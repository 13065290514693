import React from "react";

// modals
import AddReportdetails from "./AddReportDetails";
import AddUserdetails from "./AddUserdetails";
import PageAddExternal from "./PageAddExternal";
import PageAddInternal from "./PageAddInternal";
import SMARGroupDetails from "./SMARGroupDetails";
import ShowReportsPreview from "./ShowReportsPreview";
import AddTag from "./Tagging/AddTag";
import AddCategory from "./Tagging/AddCategory";
import TagStatus from "./Tagging/TagStatus";
import DeleteLog from "./Tagging/DeleteLog";
import Post from "./Post";
import HashtagPosts from "./HashtagPosts";

const SelectModal = ({ modalKey, modalData, modalProps, handleClose }) => {
  const action = modalProps ? modalProps.action : "";
  switch (modalKey) {
    case "add-page-internal":
      return (
        <PageAddInternal
          handleClose={handleClose}
          modalData={modalData}
          action={action}
          modalProps={modalProps}
        />
      );
    case "add-page-external":
      return (
        <PageAddExternal
          handleClose={handleClose}
          modalData={modalData}
          action={action}
          modalProps={modalProps}
        />
      );
    case "add-smar-group-details":
      return (
        <SMARGroupDetails
          handleClose={handleClose}
          modalData={modalData}
          action={action}
          modalProps={modalProps}
        />
      );
    case "add-user-details":
      return (
        <AddUserdetails
          handleClose={handleClose}
          modalData={modalData}
          action={action}
          modalProps={modalProps}
        />
      );
    case "add-report-details":
      return (
        <AddReportdetails
          handleClose={handleClose}
          modalData={modalData}
          action={action}
          modalProps={modalProps}
        />
      );
    case "show-reports":
      return (
        <ShowReportsPreview
          handleClose={handleClose}
          modalData={modalData}
        />
      );
    case "add-tag":
      return (
        <AddTag
          handleClose={handleClose}
          modalData={modalData}
        />
      );
    case "add-category":
      return (
        <AddCategory
          handleClose={handleClose}
          modalData={modalData}
        />
      );
      case "tags-status":
        return (
          <TagStatus
            handleClose={handleClose}
            modalData={modalProps.tagsList}
          />
        );
        case "delete-log":
        return (
          <DeleteLog
          action={action}
            handleClose={handleClose}
          />
        );
        case "show-post":
          return (
            <Post
            modalData={modalProps}
              handleClose={handleClose}
            />
          );
          case "show-hashtag-posts":
          return (
            <HashtagPosts
            modalData={modalProps}
              handleClose={handleClose}
            />
          );
    default:
      return null;
  }
};

export default SelectModal;
