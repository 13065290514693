import React, { useEffect, useState, Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';

import NotoSansRegular from "assets/fonts/arial-unicode-ms.ttf";
import NotoSansBold from "assets/fonts/arial-unicode-ms-bold.ttf";
import RobotoBoldItalic from "assets/fonts/Roboto-BoldItalic.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf"
import RobotoBold from "assets/fonts/Roboto-Bold.ttf"

//components
import PdfTableHeader from './PdfTableHeader';
import PdfTableBody from './PdfTableBody';
import ImageCardPdf from './ImageCardPdf';
import PdfTableBodyNoParty from './PdfTableBodyNoParty';
import PdfTableHeaderNoParty from './PdfTableHeaderNoParty';
import PdfTableBodyWeekly from './PdfTableBodyWeekly';
import PdfTableHeaderWeekly from './PdfTableHeaderWeekly';
import PdfTableBodyWeeklyNoParty from './PdfTableBodyWeeklyNoParty';
import PdfTableHeaderWeeklyNoParty from './PdfTableHeaderWeeklyNoParty';
import PdfTableHeaderTailWind from './PdfTableHeaderTailWind';
import PdfTableBodyTailWind from './PdfTableBodyTailWind';
import PdfTableHeaderNoPartyTailWind from './PdfTableHeaderNoPartyTailWind';
import PdfTableBodyNoPartyTailWind from './PdfTableBodyNoPartyTailWind';
import PdfTableNoPartyTailWind from './PdfTableNoPartyTailWind';
import PdfTableHeaderTailWindWeekly from './PdfTableHeaderTailWindWeekly';
import PdfTableBodyTailWindWeekly from './PdfTableBodyTailWindWeekly';
import PdfTableHeaderNoPartyTailWindWeekly from './PdfTableHeaderNoPartyTailWindWeekly';
import PdfTableNoPartyTailWindWeekly from './PdfTableNoPartyTailWindWeekly';

Font.register({
  family: 'Roboto', fonts: [
    // { src: RobotoThin, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: NotoSansRegular, fontWeight: 400 },
    { src: NotoSansBold, fontWeight: 700 },
  ]
});

Font.register({
  family: 'NewRoboto', fonts: [
    // { src: RobotoThin, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 700 },
  ]
});

Font.register({
  family: 'NewRobotoItalic', fonts: [
    // { src: RobotoThin, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: RobotoBoldItalic, fontWeight: 700 },
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    paddingTop: 35,
    paddingBottom: 20,
    paddingHorizontal: 23,
  },
  pageWeekly: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    paddingTop: 35,
    paddingBottom: 20,
    paddingHorizontal: 10,
  },
  body: {
    flexGrow: 1,
  },
  reportHeading: {
    height: 50,
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
  },
  reportHeadingSmar: {
    height: 90,
    flexDirection: 'column',
    justifyContent: "center",
    alignItems: 'center',
  },
  headingText: {
    fontSize: "14.5px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#000000",
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  headingSmarTextTailWind: {
    fontSize: "22px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#000000",
    fontFamily: 'NewRoboto',
    fontWeight: 700,
    marginBottom: 2,
  },
  headingTextTailWind: {
    fontSize: "17px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#000000",
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  headingTextDate: {
    fontSize: "13px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#000000",
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  headingTextDateTailWind: {
    fontSize: "14px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#000000",
    fontFamily: 'NewRobotoItalic',
    fontWeight: 700,
    // fontStyle: "italic",
    paddingBottom: 4,
  },
  reportHeadingNew: {
    height: 50,
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center',
    marginBottom: 10,
  },
  headingTextNew: {
    fontSize: "12px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#4F4F4F",
    fontFamily: 'Roboto',
    fontWeight: 700,
    backgroundColor: "#f2f2f2",
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  table: {
    marginBottom: 40,
  },
  table_tailwind: {
    marginTop: 35,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
    borderBottom: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    // flexBasis: 42
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 15,
  },
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    // border: "2px solid brown",
    height: 40
  },
  column_heading: {
    fontSize: "14px",
  },
  section: {
    padding: 10,
    // flexGrow: 1,
    // border: "1px solid red",
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const options_range = { year: 'numeric', month: 'long', day: 'numeric' };

const strictPageBreak = {
  "Ireland - Wicklow-Wexford": "",
  "Ireland - Wicklow": "Green Party Ireland",
  "Ireland - Wexford": "Independent",
  "Ireland - Waterford": "",
  "Ireland - Tipperary South": "",
  "Ireland - Tipperary North": "Labour",
  "Ireland - Sligo-Leitrim": "Sinn Fein",
  "Ireland - Roscommon-Galway": "Fine Gael",
  "Ireland - Other": "",
  "Ireland - Offaly": "",
  "Ireland - Meath West": "Fine Gael",
  "Ireland - Meath East": "Independent",
  "Ireland - Mayo": "Green Party Ireland",
  "Ireland - Louth": "Independent",
  "Ireland - Longford-Westmeath": "",
  "Ireland - Limerick County": "Independent",
  "Ireland - Limerick City": "Green Party Ireland",
  "Ireland - Laois": "",
  "Ireland - Dublin": "Independent",
  "Ireland - Kildare South": "Fine Gael",
  "Ireland - Kildare North": "Sinn Fein",
  "Ireland - Kerry": "Sinn Fein",
  "Ireland - Galway West": "Independent",
  "Ireland - Galway East": "Independent",
  "Ireland - Dun Laoghaire": "Green Party Ireland",
  "Ireland - Dublin West": "Green Party Ireland",
  "Ireland - Dublin South West": "Green Party Ireland",
  "Ireland - Dublin South Central": "Green Party Ireland",
  "Ireland - Dublin Rathdown": "",
  "Ireland - Dublin North West": "Sinn Fein",
  "Ireland - Dublin Mid West": "Sinn Fein",
  "Ireland - Dublin Fingal West": "",
  "Ireland - Dublin Fingal East": "Fine Gael",
  "Ireland - Dublin Central": "Green Party Ireland",
  "Ireland - Dublin Bay South": "Independent",
  "Ireland - Dublin Bay North": "Fine Gael",
  "Ireland - Donegal": "Independent",
  "Ireland - Cork South West": "Independent",
  "Ireland - Cork South Central": "Fine Gael",
  "Ireland - Cork North West": "",
  "Ireland - Cork North Central": "People Before Profit",
  "Ireland - Cork East": "Labour",
  "Ireland - Clare": "Green Party Ireland",
  "Ireland - Cavan-Monaghan": "",
  "Ireland - Carlow-Kilkenny": "Fine Gael",
  "Ireland National Leaders": "",
  "Ireland TDs": ["Aontú", "Green Party Ireland", "Labour", "Right to Change (Independent Group)"],
  "Ireland National Parties": "",
  "Ireland National Ministers": ["Fianna Fáil", "Fine Gael", "Green Party Ireland"],
};

const instaCheck = [
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  225,
  226,
  227,
  230
];

// break={key==="Aontú" ||key==="Green Party Ireland" || key === "Labour" || key === "Right to Change (Independent Group)"}
// break={strictPageBreak?.[`${fileName}`].includes(key)}

// Create Document Component
const GeneratePdf = ({ completeData, startDate, endDate, fileName, platform, reportType, reportId }) => {
  const [reportData, setReportData] = useState("");

  const getDayName = (dateStr, type) => {
    var date = new Date(dateStr);
    if (type === "range") {
      return date.toLocaleDateString(undefined, options_range);
    }
    return date.toLocaleDateString(undefined, options);
  };

  const dateDiffInDays = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  };

  function formatDate(date) {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-IN', options);

    // Split the formatted date and construct the desired format
    const [day, month, year] = formattedDate.split(' ');
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (completeData && completeData.length > 0) {
      setReportData(completeData);
    } else {
      setReportData("");
    }
  }, [completeData]);
  return (
    <>
      {reportData && reportData.length > 0 && reportType !== 9 && reportType !== 10 ?
        (<Document>
          <Page size="A4" style={styles.page} orientation="portrait" wrap>
            <View style={styles.body}>
              <View style={styles.reportHeading}>
                <Text style={styles.headingText}>{fileName} {dateDiffInDays(startDate, endDate) === 0 ? "- Daily Report" : dateDiffInDays(startDate, endDate) === 6 ? "- Weekly Report" : "- Monthly Report"}</Text>
                <Text style={styles.headingTextDate}>
                  {startDate !== endDate ?
                    `(${getDayName(startDate, "range")} - ${getDayName(endDate, "range")})` :
                    `(${getDayName(startDate, "daily")})`}
                </Text>
              </View>
              {reportData.length > 0 ?
                reportData.map((val, indx) => {
                  return (
                    <Fragment>
                      {val?.top_pages ?
                        startDate === endDate ? (
                          <View style={styles.table}>
                            <PdfTableHeader
                              partyRequired={"required"}
                              start_date={startDate}
                              end_date={endDate}
                              platform={platform}
                              data={val.top_pages}
                            />
                            <PdfTableBody
                              data={val.top_pages}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={"required"}
                              platform={platform}
                            />
                          </View>
                        ) :
                          <View style={styles.table}>
                            <PdfTableHeaderWeekly
                              partyRequired={"required"}
                              start_date={startDate}
                              end_date={endDate}
                              platform={platform}
                              reportType={dateDiffInDays(startDate, endDate)}
                              data={val.top_pages}
                            />
                            <PdfTableBodyWeekly
                              data={val.top_pages}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={"required"}
                              platform={platform}
                            />
                          </View> :
                        <></>
                      }

                      {val?.top_posts && startDate === endDate ?
                        (
                          <View break>
                            <View style={styles.reportHeadingNew}>
                              <Text style={styles.headingTextNew}>Top Posts</Text>
                            </View>
                            <View style={styles.imageContainer}>
                              {val.top_posts.map((imgVal) => {
                                if (imgVal?.resource?.startsWith("https://")) {
                                  return (
                                    <ImageCardPdf
                                      key={imgVal.post_id}
                                      image={imgVal.resource}
                                      shares={imgVal.shares}
                                      name={imgVal.page_name}
                                      type="image"
                                    />
                                  )
                                } else if (imgVal?.resource && imgVal?.resource !== "") {
                                  return (
                                    <ImageCardPdf

                                      key={imgVal.post_id}
                                      image={imgVal.resource}
                                      shares={imgVal.shares}
                                      name={imgVal.page_name}
                                      type="text"
                                    />
                                  )
                                }
                              })}
                            </View>
                          </View>
                        ) :
                        <></>
                      }

                      {val[`Bharatiya Janata Party`] || val[`BJP`] ?
                        startDate === endDate ? (
                          <View style={styles.table}>
                            <PdfTableHeaderNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Bharatiya Janata Party"}
                              platform={platform}
                              data={val[`Bharatiya Janata Party`] || val[`BJP`]}
                            />
                            <PdfTableBodyNoParty
                              data={val[`Bharatiya Janata Party`] || val[`BJP`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View>
                        ) :
                          <View style={styles.table}>
                            <PdfTableHeaderWeeklyNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Bharatiya Janata Party"}
                              platform={platform}
                              reportType={dateDiffInDays(startDate, endDate)}
                              data={val[`Bharatiya Janata Party`] || val[`BJP`]}
                            />
                            <PdfTableBodyWeeklyNoParty
                              data={val[`Bharatiya Janata Party`] || val[`BJP`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View> :
                        <></>
                      }

                      {val[`Indian National Congress`] || val[`INC`] ?
                        startDate === endDate ? (
                          <View style={styles.table}>
                            <PdfTableHeaderNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Indian National Congress"}
                              platform={platform}
                              data={val[`Indian National Congress`] || val[`INC`]}
                            />
                            <PdfTableBodyNoParty
                              data={val[`Indian National Congress`] || val[`INC`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View>
                        ) :
                          <View style={styles.table}>
                            <PdfTableHeaderWeeklyNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Indian National Congress"}
                              platform={platform}
                              reportType={dateDiffInDays(startDate, endDate)}
                              data={val[`Indian National Congress`] || val[`INC`]}
                            />
                            <PdfTableBodyWeeklyNoParty
                              data={val[`Indian National Congress`] || val[`INC`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View> :
                        <></>
                      }

                      {val[`Aam Aadmi Party`] || val[`AAP`] ?
                        startDate === endDate ? (
                          <View style={styles.table}>
                            <PdfTableHeaderNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Aam Aadmi Party"}
                              platform={platform}
                              data={val[`Aam Aadmi Party`] || val[`AAP`]}
                            />
                            <PdfTableBodyNoParty
                              data={val[`Aam Aadmi Party`] || val[`AAP`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View>
                        ) :
                          <View style={styles.table}>
                            <PdfTableHeaderWeeklyNoParty
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              heading={"Aam Aadmi Party"}
                              platform={platform}
                              reportType={dateDiffInDays(startDate, endDate)}
                              data={val[`Aam Aadmi Party`] || val[`AAP`]}
                            />
                            <PdfTableBodyWeeklyNoParty
                              data={val[`Aam Aadmi Party`] || val[`AAP`]}
                              start_date={startDate}
                              end_date={endDate}
                              partyRequired={""}
                              platform={platform}
                            />
                          </View> :
                        <></>
                      }

                      {val ?
                        Object.keys(val).map((key) => {
                          return (
                            <Fragment>
                              {key !== "top_posts" &&
                                key !== "top_pages" &&
                                key !== "Bharatiya Janata Party" &&
                                key !== "BJP" &&
                                key !== "Indian National Congress" &&
                                key !== "INC" &&
                                key !== "AAP" &&
                                key !== "Aam Aadmi Party" ?
                                startDate === endDate ?
                                  <View style={styles.table}>
                                    <PdfTableHeaderNoParty
                                      partyRequired={""}
                                      start_date={startDate}
                                      end_date={endDate}
                                      heading={key}
                                      platform={platform}
                                      data={val[`${key}`]}
                                    />
                                    <PdfTableBodyNoParty
                                      data={val[`${key}`]}
                                      start_date={startDate}
                                      end_date={endDate}
                                      partyRequired={""}
                                      platform={platform}
                                    />
                                  </View> :
                                  <View style={styles.table}>
                                    <PdfTableHeaderWeeklyNoParty
                                      partyRequired={""}
                                      start_date={startDate}
                                      end_date={endDate}
                                      heading={key}
                                      platform={platform}
                                      reportType={dateDiffInDays(startDate, endDate)}
                                      data={val[`${key}`]}
                                    />
                                    <PdfTableBodyWeeklyNoParty
                                      data={val[`${key}`]}
                                      start_date={startDate}
                                      end_date={endDate}
                                      partyRequired={""}
                                      platform={platform}
                                    />
                                  </View> :
                                <></>
                              }
                            </Fragment>
                          )
                        }) :
                        <></>
                      }
                    </Fragment>
                  )
                }) :
                <></>
              }
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
        </Document>) :
        (<Document>
          <Page size="A4" style={startDate === endDate ? styles.page : styles.pageWeekly} orientation="portrait" wrap>
            <View style={styles.body}>
              <View style={styles.reportHeadingSmar}>
                <Text style={styles.headingSmarTextTailWind}>Social Media Analytics Report (SMAR)</Text>
                {instaCheck.includes(reportId) ?
                  <Text style={styles.headingTextTailWind}>{fileName} {dateDiffInDays(startDate, endDate) === 0 ? ": Daily Report - Instagram" : dateDiffInDays(startDate, endDate) === 6 ? ": Weekly Report  - Instagram" : ": Monthly Report - Instagram"}</Text> :
                  <Text style={styles.headingTextTailWind}>{fileName} {dateDiffInDays(startDate, endDate) === 0 ? ": Daily Report - Facebook" : dateDiffInDays(startDate, endDate) === 6 ? ": Weekly Report  - Facebook" : ": Monthly Report - Facebook"}</Text>
                }
                <Text style={styles.headingTextDateTailWind}>
                  {startDate !== endDate ?
                    `(${getDayName(startDate, "range")} - ${getDayName(endDate, "range")})` :
                    `(${formatDate(startDate)})`}
                </Text>
              </View>
              {reportData.length > 0 ?
                reportData.map((val, indx) => {
                  return (
                    <Fragment key={indx}>
                      {val?.top_pages &&
                        fileName !== "Ireland National Parties" ?
                        (
                          startDate === endDate ?
                            <View style={styles.table}>
                              <PdfTableHeaderTailWind
                                partyRequired={"required"}
                                start_date={startDate}
                                end_date={endDate}
                                platform={platform}
                                data={val.top_pages}
                                reportType={reportType}
                                fileName={fileName}
                                reportId={reportId}
                              />
                              <PdfTableBodyTailWind
                                data={val.top_pages}
                                start_date={startDate}
                                end_date={endDate}
                                partyRequired={"required"}
                                platform={platform}
                                fileName={fileName}
                                reportId={reportId}
                              />
                            </View> :
                            <View style={styles.table}>
                              <PdfTableHeaderTailWindWeekly
                                partyRequired={"required"}
                                start_date={startDate}
                                end_date={endDate}
                                platform={platform}
                                data={val.top_pages}
                                reportType={reportType}
                                fileName={fileName}
                                reportId={reportId}
                              />
                              <PdfTableBodyTailWindWeekly
                                data={val.top_pages}
                                start_date={startDate}
                                end_date={endDate}
                                partyRequired={"required"}
                                platform={platform}
                                fileName={fileName}
                                reportId={reportId}
                              />
                            </View>

                        ) :
                        (startDate === endDate ?
                          <View style={styles.table}>
                            <PdfTableHeaderNoPartyTailWind
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              platform={platform}
                              data={val.top_pages}
                              reportType={reportType}
                              fileName={fileName}
                              reportId={reportId}
                            />
                          </View> :
                          <View style={styles.table}>
                            <PdfTableHeaderNoPartyTailWindWeekly
                              partyRequired={""}
                              start_date={startDate}
                              end_date={endDate}
                              platform={platform}
                              data={val.top_pages}
                              reportType={reportType}
                              fileName={fileName}
                              reportId={reportId}
                            />
                          </View>
                        )
                      }
                      {val?.top_posts && val.top_posts.length > 0 && (instaCheck.includes(reportId)) ?
                        <></> :
                        val?.top_posts && val.top_posts.length > 0 &&
                        (
                          <View break>
                            <View style={styles.reportHeadingNew}>
                              <Text style={styles.headingTextNew}>Top Posts</Text>
                            </View>
                            <View style={styles.imageContainer}>
                              {val.top_posts.map((imgVal) => {
                                if (imgVal?.resource?.startsWith("https://")) {
                                  return (
                                    <ImageCardPdf
                                      fileName={fileName}
                                      key={imgVal.post_id}
                                      image={imgVal.resource}
                                      shares={imgVal.shares}
                                      name={imgVal.page_name}
                                      type="image"
                                      reportType={"tailwind"}
                                    />
                                  )
                                } else if (imgVal?.resource && imgVal?.resource !== "") {
                                  return (
                                    <ImageCardPdf
                                      fileName={fileName}
                                      key={imgVal.post_id}
                                      image={imgVal.resource}
                                      shares={imgVal.shares}
                                      name={imgVal.page_name}
                                      type="text"
                                      reportType={"tailwind"}
                                    />
                                  )
                                }
                              })}
                            </View>
                          </View>
                        )
                      }
                      {val && (instaCheck.includes(reportId)) ?
                        Object.keys(val).map((key) => {
                          if (fileName === "Ireland TDs" || fileName === "Ireland National Ministers") {
                            if(startDate === endDate) {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View break={fileName === "Ireland TDs" ? key === "Fianna Fáil" || key === "Right to Change (Independent Group)" || key === "Labour" || key === "Sinn Féin" : key === "Fianna Fáil" || key === "Fine Gael" || key === "Green Party Ireland"} style={styles.table_tailwind}>
                                      <PdfTableHeaderNoPartyTailWind
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            } else {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View break={key === "Fianna Fáil" || key === "Right to Change (Independent Group)" || key === "Independent" || key === "Sinn Féin"} style={styles.table_tailwind}>
                                      <PdfTableHeaderNoPartyTailWindWeekly
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            }
                          } else {
                            if(startDate === endDate) {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View style={styles.table}>
                                      <PdfTableNoPartyTailWind
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            } else {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View style={styles.table}>
                                      <PdfTableNoPartyTailWindWeekly
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            }
                          }
                        }) :
                        Object.keys(val).map((key) => {
                          if (fileName === "Ireland TDs" || fileName === "Ireland National Ministers") {
                            if (startDate === endDate) {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View break={strictPageBreak?.[`${fileName}`].includes(key)} style={styles.table_tailwind}>
                                      <PdfTableHeaderNoPartyTailWind
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            } else {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View break={strictPageBreak?.[`${fileName}`].includes(key)} style={styles.table_tailwind}>
                                      <PdfTableHeaderNoPartyTailWindWeekly
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            }
                          } else {
                            if (startDate === endDate) {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View style={styles.table}>
                                      <PdfTableNoPartyTailWind
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            } else {
                              return (
                                <Fragment key={key}>
                                  {key !== "top_pages" &&
                                    key !== "top_posts" &&
                                    fileName !== "Ireland National Leaders" &&
                                    fileName !== "Ireland National Parties" ?
                                    <View style={styles.table}>
                                      <PdfTableNoPartyTailWindWeekly
                                        partyRequired={""}
                                        start_date={startDate}
                                        end_date={endDate}
                                        heading={key}
                                        platform={platform}
                                        data={val[`${key}`]}
                                        reportType={reportType}
                                        reportId={reportId}
                                      />
                                    </View> :
                                    <></>
                                  }
                                </Fragment>
                              )
                            }
                          }
                        })
                      }
                    </Fragment>
                  )
                }) :
                <></>
              }
            </View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
              fixed
            />
          </Page>
        </Document>)
      }
    </>
  );
};

export default GeneratePdf;