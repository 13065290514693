import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";

// MUI
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

//components
import AutoCompleteSelect from "components/Common/AutoCompleteSelect";
import CustomTextField from "components/Common/CommonTextField";
import AutoCompleteState from "components/Common/AutoCompleteState";
import CustomButton from "components/Common/CustomButton";
import AutoCompletePlatform from "components/Common/AutoCompletePlatform";
import AutoCompleteSmarList from "components/Common/AutoCompleteSmarList";
import AutoCompleteReportType from "components/Common/AutoCompleteReportType";

//data
import {
	countryMapping,
	pageCategory,
	pageSubCategory,
	platform,
	region,
	reportType,
	reportTypesByCategory,
	reportTypesByCategoryMapping,
} from "utils/data";

//context
import { AlertContext } from "contexts/AlertContext";
import { DataContext } from "contexts/DataContext";
import { AuthContext } from "contexts/AuthContext";

//apis
import { addSmarGroup, getSmarList } from "apis/componentsAction/smar";
import { addReports } from "apis/componentsAction/reports";

const ModalContainer = styled("div")(({ theme }) => ({
	background: theme.palette.primary.light,
	borderRadius: "14px",
	padding: "1.5rem 2rem",
	overflowY: "scroll",
	"&::-webkit-scrollbar": {
		width: "5px",
	},
	"&::-webkit-scrollbar-thumb": {
		background: `${theme.palette.secondary.main}`,
	},
	"& .option_container": {
		display: "flex",
		alignItems: "center",
		marginBottom: "1.3rem",
	},
	"& .option_name": {
		width: "25%",
		fontSize: "16px",
		color: "#4F4F4F",
		[theme.breakpoints.down("xl")]: {
			fontSize: "14px",
		},
	},
	"& .option_input": {
		width: "75%",
		display: "flex",
		gap: "0.5rem",
	},
	"& .smar_input": {
		width: "75%",
		display: "flex",
		gap: "0.5rem",
		alignItems: "flex-end",
	},
	"& .option_input_smar_group": {
		width: "70%",
		display: "flex",
		gap: "0.5rem",
	},
	"& .button_style": {
		borderRadius: "20px",
		color: "#FFFFFF",
		background: theme.palette.primary.main,
		// width: "20%",
		fontSize: "16px",
		[theme.breakpoints.down("xl")]: {
			fontSize: "14px",
		},
	},
	"& .cancel_style": {
		borderRadius: "20px",
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		fontWeight: "500",
		width: "20%",
		fontSize: "16px",
		[theme.breakpoints.down("xl")]: {
			fontSize: "14px",
		},
	},
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	marginRight: "8px",
	padding: "5px",
	"& .editIcon": {
		fontSize: "1.4rem",
		color: "#4F4F4F",
		background: "#EFFFFD",
		[theme.breakpoints.down("xl")]: {
			fontSize: "1.2rem",
		},
	},
	"& .delIcon": {
		fontSize: "1.4rem",
		color: "#FF5252",
		background: "#EFFFFD",
		[theme.breakpoints.down("xl")]: {
			fontSize: "1.2rem",
		},
	},
}));

const initialState = {
	pk: "",
	reportname: "",
	reportcategory: "",
	reportsubcategory: "",
	platform: {
		id: "",
		name: "",
	},
	smar_group: [],
	// reportType: reportType[0],
	reportType: {
		label: "",
		value: "",
	},
	regionType: "",
	state: {
		id: "",
		name: "",
	},
	country:{
		id: "",
		name: "",
	},
};

const AddReportdetails = ({
	handleClose,
	modalData,
	action,
	modalProps,
	handleEdit,
	handleUpdate,
}) => {
	const [state, setState] = useState(initialState);
	const [isModal, setIsModal] = useState(false);
	const [smarList, setSmarList] = useState([]);
	const [selectedSmarList, setSelectedSmarList] = useState([]);
	const [pageCount, setPageCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [limit] = useState(40);

	//   contexts
	const { showAlert } = useContext(AlertContext);
	const { user } = useContext(AuthContext);
	const { stateList, stateMapping, countryList } = useContext(DataContext);

	const getSmarData = async (loadType, currPage, searchGroup = "") => {
		const { data, error } = await getSmarList(
			currPage,
			state.platform.id,
			searchGroup
		);
		if (error) {
			alert("Something went wrong");
		}
		let newData;
		if (loadType === "initial") {
			setPageCount(Math.ceil(data.count / limit));
			newData = [...data.results];
		} else {
			newData = [...smarList, ...data.results];
		}
		setCurrentPage((prevPage) => prevPage + 1);
		setSmarList(newData);
	};
	const getCountryName = (id) => {
		const country = countryList.find(item => item.id === id);
		return country ? country.name : "";
	  };
	useEffect(() => {
		if (modalData && !isModal) {
			setIsModal(true);
			setState({
				pk: modalData.pk || "",
				reportname: modalData.reportname || "",
				reportcategory: modalData.reportcategory || "",
				reportsubcategory: modalData.reportsubcategory || "",
				platform: modalData.platform.length === 1 ? modalData.platform[0] : modalData.platform || "",
				smar_group: modalData.SmarGroup || [],
				reportType:reportTypesByCategory[modalData.report_type - 1] ||{label: "",
				value: "",},
				state: modalData?.state ? modalData?.state : {
					id: "",
					name: "",
				},
				country:{id:modalData.country,name:getCountryName(modalData.country)}||{id:'',name:''},

			});
		}
		if (state.reportType) {
			setPageCount(0);
			setCurrentPage(1);
			getSmarData("initial", 1, "");
		}
	}, [modalData, state.reportType]);

	const handleChange = (data, name) => {
		if (name === "region") {
			setState((prevState) => ({
				...prevState,
				[name]: data,
				state: "",
				district: "",
				ac: "",
				pc: "",
			}));
		} else {
			setState((prevState) => ({ ...prevState, [name]: data }));
		}
	};

	const handleTextChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleChangePlatform = (data, name) => {
		if (data) {
			setState((prevState) => ({
				...prevState,
				platform: data,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				platform: {
					id: "",
					name: "",
				},
			}));
		}
	};
	const handleRegionChange = () => {
		if (state.reportType.value == reportTypesByCategoryMapping.State) {
			setState((prevState) => ({
				...prevState,
				regionType: "state",
			}));
		} else if (state.reportType.value == reportTypesByCategoryMapping.National) {
			setState((prevState) => ({
				...prevState,
				regionType: "national",
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				regionType: "",
			}));
		}
	};

	const handleChangeReportType = (data, name) => {
		if (data) {
			setState((prevState) => ({
				...prevState,
				reportType: data,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				reportType: {
					label: "",
					value: "",
				},
			}));
		}
	};

	useEffect(() => {
		handleRegionChange();
	}, [state.reportType]);

	const handleSmarListChange = (data) => {
		if (data.length > 0) {
			let ids = data.map((item) => item.pk.toString());
			setSelectedSmarList(ids);
		} else {
			setSelectedSmarList([]);
		}
	};

	const handleStateChange = (data) => {
		if (data) {
			setState((prevState) => ({
				...prevState,
				state: data,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				state: {
					id: "",
					name: "",
				},
			}));
		}
	};
	const handlCountryChange=(data)=>{
		if (data) {
			setState((prevState) => ({
				...prevState,
				country: data,
			}));
		} else {
			setState((prevState) => ({
				...prevState,
				country: {
					id: "",
					name: "",
				},
			}));
		}
	}

	//debounced function for delaying the api call
	const debounceFunction = useCallback(
		debounce((...eventData) => {
			getSmarData(...eventData);
		}, 500),
		[]
	);

	const onInputChange = (event, value) => {
		if (value) {
			setPageCount(0);
			setCurrentPage(1);
			debounceFunction("initial", 1, value);
		} else {
			setPageCount(0);
			setCurrentPage(1);
			debounceFunction("initial", 1, "");
		}
	};

	const handleDelete = (data) => {
		const newData = state.smar_group.filter(
			(item) => item.group_name !== data.group_name
		);
		if (newData.length > 0) {
			setState((prevState) => ({ ...prevState, smar_group: newData }));
			return;
		}
		setState((prevState) => ({ ...prevState, smar_group: "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let smarGroupData = "";
		//for removing duplicate smargroups on edit
		if (state.smar_group) {
			let ids = state.smar_group.map((item) => item.pk.toString());
			smarGroupData = [...new Set([...selectedSmarList, ...ids])];
		} else {
			if (selectedSmarList) {
				smarGroupData = [...selectedSmarList];
			}
		}

		const { status, message } = await addReports(
			state.pk,
			state.reportname,
			state.reportcategory,
			state.reportsubcategory,
			state.platform,
			state.reportType,
			state.regionType,
			state.state,
			state.country,
			smarGroupData,
			action
		);
		if (status === 201 || status === 200) {
			showAlert(message, "success");
		} else if (status === 400) {
			showAlert("error occured", "error");
		} else {
			showAlert("Something went wrong", "error");
		}
		setState(initialState);

		if (action === "edit") {
			handleUpdate();
			handleEdit();
		} else {
			modalProps.setRefreshList("true");
			handleClose();
		}
	};
	return (
		<form onSubmit={handleSubmit}>
			<ModalContainer
				style={{ padding: action === "edit" ? "1.2rem 0rem" : "1.5rem 2rem" }}>
				<div className='option_container'>
					<span className='option_name'>Report Name</span>
					<div className='option_input'>
						<CustomTextField
							value={state.reportname}
							name='reportname'
							label='Enter New Report Name'
							variant='outlined'
							onChange={handleTextChange}
							sx={{ width: "80%" }}
							required
						/>
					</div>
				</div>
				<div className='option_container'>
					<span className='option_name'>Report Type</span>
					<div className='option_input'>
						<FormControl
							sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
							size='small'>
							<AutoCompleteReportType
								size='small'
								value={state.reportType}
								options={reportTypesByCategory}
								autoComplete='true'
								label='Select Report Type'
								onChange={handleChangeReportType}
								required
								// text="report_type"
							/>
						</FormControl>
					</div>
				</div>
				{reportTypesByCategoryMapping.General===state.reportType.value && (
					<div className='option_container'>
						<span className='option_name'>Platform</span>
						<div className='option_input'>
							<FormControl
								sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
								size='small'>
								<AutoCompletePlatform
									size='small'
									variant='outlined'
									text='Platform'
									value={state.platform}
									options={platform}
									autoComplete='true'
									label='Select Platform'
									onChange={handleChangePlatform}
								/>
							</FormControl>
						</div>
					</div>
				)}
				{(state.reportType.value === reportTypesByCategoryMapping.State || state.reportType.value === reportTypesByCategoryMapping.General) && (
					<div className='option_container'>
						<span className='option_name'>State</span>
						<div className='option_input'>
							<FormControl
								sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
								size='small'>
								<AutoCompleteState
									size='small'
									variant='outlined'
									value={state.state}
									options={user?.user_type === "General" ? user?.states_data.length ? user?.states_data : [] : stateList}
									autoComplete='true'
									label='State'
									text='state'
									onChange={handleStateChange}
								/>
							</FormControl>
						</div>
					</div>
				)}
				
					<div className='option_container'>
						<span className='option_name'>Country</span>
						<div className='option_input'>
							<FormControl
								sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
								size='small'>
								<AutoCompleteState
									size='small'
									variant='outlined'
									value={state.country}
									options={user?.user_type === "General" ? user?.states_data.length ? user?.states_data : [] : countryList}
									autoComplete='true'
									label='Country'
									text='country'
									onChange={handlCountryChange}
									required
								/>
							</FormControl>
						</div>
					</div>
		
				<div className='option_container'>
					<span className='option_name'>Report Category</span>
					<div className='option_input'>
						<FormControl
							sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
							size='small'>
							<AutoCompleteSelect
								size='small'
								variant='outlined'
								text='reportcategory'
								value={state.reportcategory}
								options={pageCategory}
								autoComplete='true'
								label='Select Category'
								onChange={handleChange}
							/>
						</FormControl>
					</div>
				</div>
				<div className='option_container'>
					<span className='option_name'>Report Sub Category</span>
					<div className='option_input'>
						<FormControl
							sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
							size='small'>
							<AutoCompleteSelect
								size='small'
								variant='outlined'
								text='reportsubcategory'
								value={state.reportsubcategory}
								options={pageSubCategory}
								autoComplete='true'
								label='Select Sub Category'
								onChange={handleChange}
							/>
						</FormControl>
					</div>
				</div>
				{/* <div className="option_container">
          <span className="option_name">Report Type</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompleteReportType
                size="small"
                variant="outlined"
                text="report_type"
                value={state.report_type}
                options={reportType}
                autoComplete="true"
                label="Select Report Type"
                onChange={handleChangeReportType}
              />
            </FormControl>
          </div>
        </div> */}
				{action === "edit" &&
					state.smar_group &&
					state.smar_group.map((item, index) => {
						return (
							<div className='option_container'>
								<span className='option_name'>
									{index === 0 ? "SMAR Groups" : ""}
								</span>
								<div className='option_input_smar_group'>
									<CustomTextField
										value={item.group_name}
										name='page_name'
										label='Enter new page name'
										variant='outlined'
										onChange={handleTextChange}
										sx={{ width: "86%" }}
										disabled
									/>
								</div>
								<StyledIconButton
									disableRipple={true}
									onClick={() => handleDelete(item)}>
									<DeleteOutlineOutlinedIcon className='delIcon' />
								</StyledIconButton>
							</div>
						);
					})}
				{
					<div className='option_container'>
						<span className='option_name'>
							{action !== "edit" ? "SMAR Groups" : "Add more SMAR Groups"}
						</span>
						<div className='smar_input'>
							<FormControl
								sx={{ width: "80%" }}
								size='small'>
								<AutoCompleteSmarList
									size='small'
									variant='outlined'
									limitTags={1}
									// value={selectedState}
									options={smarList}
									autoComplete='true'
									label='Select SMAR Group Name'
									onChange={handleSmarListChange}
									onInputChange={onInputChange}
									getData={getSmarData}
									currentPage={currentPage}
									pageCount={pageCount}
								/>
							</FormControl>
							{/* <CustomButton
                className="add_button_style"
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={modalHandler}
              >
                <Typography className="button_text">Add</Typography>
              </CustomButton> */}
						</div>
					</div>
				}
				{action === "edit" ? (
					<div className='option_container'>
						<div className='option_name'></div>
						<div className='option_input'>
							<CustomButton
								variant='contained'
								className='button_style'
								type='submit'>
								Save
							</CustomButton>
							<CustomButton
								variant='outlined'
								className='cancel_style'
								onClick={handleEdit}>
								Cancel
							</CustomButton>
						</div>
					</div>
				) : (
					<div className='option_container'>
						<div className='option_name'></div>
						<div className='option_input'>
							<CustomButton
								variant='contained'
								className='button_style'
								style={{ width: action === "edit" ? "20%" : "35%" }}
								type='submit'>
								Create Report
							</CustomButton>
						</div>
					</div>
				)}
			</ModalContainer>
		</form>
	);
};

export default AddReportdetails;
