import React, { useState } from "react";

// MUI
import styled from "@emotion/styled";
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomButton from "components/Common/CustomButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import { border } from "@mui/system";

const ModalContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: "14px",
  padding: "1.5rem 1.5rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: `${theme.palette.secondary.main}`,
  },

  "& .platform": {
    display: "flex",
    alignItems: "center",
  },
  "& .radio_name": {
    fontWeight: "400",
    fontSize: "18px",
    marginRight: "2rem",
    flex: "0.2",
  },
  "& .radio_group": {
    flex: "0.8",
  },
  "& .page_option_container": {
    display: "flex",
    alignItems: "center",
    marginTop: "1.3rem",
  },
  "& .option_name": {
    flex: "0.25",
    width: "100%",
  },
  "& .option_input": {
    flex: "0.75",
    width: "100%",
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
}));

const fakeDropDown = {
  pagename: [
    "Page Name 1",
    "Page Name 2",
    "Page Name 3",
    "Page Name 4",
    "Page Name 5",
  ],
  pagecategory: ["Page category 1", "Page category 2", "Page category 3"],
  pagesubcategory: ["Page category 1", "Page category 2", "Page category 3"],
  politicalparty: [
    "Political Party 1",
    "Political Party 2",
    "Political Party 3",
  ],
  region: ["Region 1", "Region 2", "Region 3", "Region 4"],
  state: ["Maharashtra", "Madhya Pradesh", "Rajasthan"],
  distric: ["Maharashtra", "Madhya Pradesh", "Rajasthan"],
  smargroups: [
    "SMAR Group 1",
    "SMAR Group 2",
    "SMAR Group 3",
    "SMAR Group 4",
    "SMAR Group 5",
  ],
};
const PageAddExternal = () => {
  const [isAuth, setIsAuth] = useState(false);
  return (
    <ModalContainer>
      <div className="platform_pageType_contianer">
        <div className="platform">
          <span className="radio_name">Page Type</span>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="external"
            name="radio-buttons-group"
            className="radio_group"
          >
            <FormControlLabel
              value="internal"
              control={<Radio />}
              label="Internal"
            />

            <FormControlLabel
              value="external"
              control={<Radio />}
              label="External"
            />
          </RadioGroup>
        </div>
      </div>
      <div className="page_option_container">
        <span className="option_name">Page Name</span>
        <div className="option_input">
          <TextField
            id="outlined-basic"
            label="Enter New Page Name"
            variant="outlined"
            size="small"
            sx={{ flex: "0.7" }}
          />
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Page Id</span>
        <div className="option_input">
          <TextField
            id="outlined-basic"
            label="Enter Page Id"
            variant="outlined"
            size="small"
            sx={{ flex: "0.3" }}
          />
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Page Category</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">Select Category</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select Category"
            >
              <MenuItem value="">
                <em>Select Category</em>
              </MenuItem>
              {fakeDropDown.pagecategory.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Page Sub Category</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">Select Sub Category</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select Sub Category"
            >
              <MenuItem value="">
                <em>Select Sub Category</em>
              </MenuItem>
              {fakeDropDown.pagesubcategory.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Political Party</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">
              Select Political Party
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select Political Party"
            >
              <MenuItem value="">
                <em>Select Political Party</em>
              </MenuItem>
              {fakeDropDown.politicalparty.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Region</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">Select Region</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select Region"
            >
              <MenuItem value="">
                <em>Select Region</em>
              </MenuItem>
              {fakeDropDown.region.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">Location of place</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">Select State</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select State"
            >
              <MenuItem value="">
                <em>Select State</em>
              </MenuItem>
              {fakeDropDown.state.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: "0.3" }} size="small">
            <InputLabel id="demo-select-small">Select District</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select District"
            >
              <MenuItem value="">
                <em>Select District</em>
              </MenuItem>
              {fakeDropDown.distric.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <span className="option_name">SMAR Groups</span>
        <div className="option_input">
          <FormControl sx={{ flex: "0.5" }} size="small">
            <InputLabel id="demo-select-small">
              Select SMAR Group Name
            </InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              label="Select SMAR Group Name"
            >
              <MenuItem value="">
                <em>Select SMAR Groups</em>
              </MenuItem>
              {fakeDropDown.smargroups.map((state) => (
                <MenuItem value={state}>{state}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <CustomButton style={{ flex: "0.1" }} variant="outlined">
            Add
          </CustomButton>
        </div>
      </div>
      <div className="page_option_container">
        <span className="option_name">URL of page</span>
        <div className="option_input">
          <TextField
            id="outlined-basic"
            label="Enter Page url"
            variant="outlined"
            size="small"
            sx={{ flex: "0.5" }}
          />
          <span style={{ flex: "0.1" }}>Optional</span>
        </div>
      </div>{" "}
      <div className="page_option_container">
        <div className="option_name"></div>
        <div className="option_input">
          <CustomButton variant="contained" sx={{ color: "white" }}>
            Add Page
          </CustomButton>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PageAddExternal;
