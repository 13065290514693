import React from "react";

//mui
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const Container = styled("div")(({ theme }) => ({
  width: "19.09%",
  maxWidth: "19.09%",
  borderRadius: "8px",
  border: "0.629268px solid #E5E5E5",
  // boxShadow: "0px 0px 2.51707px rgba(0, 0, 0, 0.1)",
  marginBottom: "1.4rem",
  position: "relative",
  [theme.breakpoints.down("xl")]: {
    width: "19%",
    maxWidth: "19%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "24.2%",
    maxWidth: "24.2%",
  },
  "& .img_style": {
    width: "100%",
    maxWidth: "100%",
    objectFit: "fill",
    // aspectRatio: "4/2",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "222px",
    // minHeight: "222px",
    maxheight: "222px",
    borderBottom: "1px solid #E5E5E5",
  },
  "& .text_desc_style": {
    width: "100%",
    maxWidth: "100%",
    height: "222px",
    maxheight: "222px",
    overflowY: "scroll",
    padding: "0 0.5rem",
    borderBottom: "1px solid #E5E5E5",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  },
  "& .share_container": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    height: "40px",
    top: 198,
    right: 0,
    zIndex: "2",
    background: "#FFFFFF",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    // padding: "0 0.7rem",
    // boxShadow: "0.629268px 0.629268px 2.51707px rgba(0, 0, 0, 0.24)",
    "& .text_container": {
      background: "#ffffff",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 1rem",
      borderTopLeftRadius: "20px",
      borderBottomLeftRadius: "20px",
      border: "1px solid rgba(224, 224, 224, 1)",
      "& .text_style": {
        color: "#4F4F4F",
        fontSize: "16px",
        [theme.breakpoints.down("xl")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
      },
    },
    "& .number_container": {
      background: "#374A92",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 1rem",
      border: "1px solid rgba(224, 224, 224, 1)",
      "& .number_style": {
        color: "#FFFFFF",
        fontSize: "16px",
        [theme.breakpoints.down("xl")]: {
          fontSize: "15px",
        },
        [theme.breakpoints.down("lg")]: {
          fontSize: "14px",
        },
      },
    },
  },
  "& .page_name_style": {
    padding: "1rem 0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& .name_style": {
      fontSize: "16px",
      fontWeight: "600",
      color: "#303030",
      padding: "0 0.5rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "15px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
  },
}));

const PdfImageCard = ({ image, shares, name, type }) => {

  return (
    <Container>
      <div
        className="img_container"
      >
        {type === "image" ?
          <img
            className="img_style"
            src={image}
          /> :
          <div className="text_desc_style">
            <p>{image}</p>
          </div>
        }
      </div>
      <div className="share_container">
        <div className="text_container">
          <Typography
            className="text_style"
          >
            Post Shares
          </Typography>
        </div>
        <div className="number_container">
          <Typography
            className="number_style"
          >
            {shares}
          </Typography>
        </div>
      </div>
      <div className="page_name_style">
        <Typography className="name_style">
          {name}
        </Typography>
      </div>
    </Container>
  );
};

export default PdfImageCard;