import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";

// MUI
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

// components
import AutoCompleteSelect from "components/Common/AutoCompleteSelect";
import CustomButton from "components/Common/CustomButton";
import CustomTextField from "components/Common/CommonTextField";
import { AutoCompleteMultiState } from "components/Common/AutoCompleteMultiSelect";
import AutoCompleteMultiPageSelect from "components/Common/AutoCompleteMultiPageSelect";

// apis
import { addUsers } from "apis/componentsAction/user";
import { getPagesList } from "../../apis/componentsAction/pages";

// utils
import { userType, accessLevel, emailDomain } from "../../utils/data";

// context
import { AlertContext } from "contexts/AlertContext";
import { DataContext } from "contexts/DataContext";

const ModalContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: "14px",
  padding: "1.5rem 2rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: `${theme.palette.secondary.main}`,
  },

  "& .radio_group": {
    flex: "0.8",
  },
  "& .option_container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.3rem",
  },
  "& .option_name": {
    width: "25%",
    fontSize: "16px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .option_input": {
    width: "75%",
    display: "flex",
    gap: "0.5rem",
    "& .MuiInputBase-root": {
      height: "36px",
    }
  },
  "& .option_input_smar_group": {
    width: "70%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .option_input_last": {
    width: "75%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .button_style": {
    borderRadius: "20px",
    color: "#FFFFFF",
    background: theme.palette.primary.main,
    // width: "20%",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .cancel_style": {
    borderRadius: "20px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    width: "20%",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    color: "#4F4F4F",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "1.4rem",
    color: "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
}));

const initialState = {
  id: "",
  user_name: "",
  email_username: "",
  email_domain: "",
  email_id: "",
  user_type: "",
  access_type: "",
  page: [],
  state_name: [],
};

const AddUserdetails = ({
  handleClose,
  modalData,
  action,
  modalProps,
  handleEdit,
  handleUpdate,
}) => {
  const [state, setState] = useState(initialState);
  const [pageList, setPageList] = useState([]);
  const [selectedStateList, setSelectedStateList] = useState([]);
  const [selectedPageList, setSelectedPageList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(40);
  const [isModal, setIsModal] = useState(false);
  const { stateList } = useContext(DataContext);
  const { showAlert } = useContext(AlertContext);

  const getPagesData = async (loadType, currPage, searchPage = "") => {
    const { data, error } = await getPagesList("", currPage, "", searchPage, "", "", "");
    if (error) {
      if (error.status === 401) {
        return;
      } else if (error.status === 404) {
        alert("No reports available for your state");
        return;
      } else {
        alert("Couldn't fetch the data");
        return;
      }
    }
    let newData;
    if (loadType === "initial") {
      setPageCount(Math.ceil(data.count / limit));
      newData = [...data.results];
    } else {
      newData = [...pageList, ...data.results];
    }
    setCurrentPage(prevPage => prevPage + 1);
    setPageList(newData);
  };

  useEffect(() => {
    if (modalData && !isModal) {
      setIsModal(true);
      setState({
        id: modalData.pk || "",
        user_name: modalData.name || "",
        email_domain: modalData.email.slice(modalData.email.indexOf("@")) || "",
        email_username:
          modalData.email.slice(0, modalData.email.indexOf("@")) || "",
        email_id: modalData.email || "",
        user_type: modalData.user_type || "",
        access_type: modalData.access_type || "",
        page: modalData.pages_data || [],
        state_name: modalData.states_data || [],
      });
    }
    if(state.access_type === "Page") {
      setPageCount(0);
      setCurrentPage(1);
      getPagesData("initial", 1, "");
    }
  }, [modalData, state.state, state.access_type]);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAccessLevelChange = (data) => {
    if(data) {
      setState((prevState) => ({ ...prevState, access_type: data }));
    }
  };

  const handleChange = (data) => {
    if (data.length > 0) {
      let ids = data.map(item => (item.id).toString());
      setSelectedStateList(ids);
    } else {
      setSelectedStateList([]);
    }
  };

  const handleDelete = (data) => {
    const newData = state.state_name.filter(item => item.pk !== data.pk);
    if (newData.length > 0) {
      setState((prevState) => ({ ...prevState, state_name: newData }));
      return;
    }
    setState((prevState) => ({ ...prevState, state_name: "" }));
  };

  const handleDeletePage = (data) => {
    const newData = state.page.filter(item => item.pk !== data.pk);
    if (newData.length > 0) {
      setState((prevState) => ({ ...prevState, page: newData }));
      return;
    }
    setState((prevState) => ({ ...prevState, page: "" }));
  };

  //debounced function for delaying the api call
  const debounceFunction = useCallback(debounce((...eventData) => {
    getPagesData(...eventData);
  }, 500), []);

  const onInputChange = (event, value) => {
    if(value) {
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, value);
    } else {
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, "");
    }
  }

  const handlePageListChange = (data) => {
    if (data.length > 0) {
      let ids = data.map(item => (item.page_id).toString());
      setSelectedPageList(ids);
    } else {
      setSelectedPageList([]);
    }
  };

  const handleUserTypeChange = (value, name) => {
    if (value) {
      setState((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: "",
        access_level: "",
        page: [],
        state_name: [],
      }))
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !state.email_domain ||
      !state.email_username ||
      !state.user_name ||
      !state.email_id ||
      !state.user_type
    ) {
      showAlert("Please provide all the fields", "warning");
      return;
    }

    if (
      state.user_type === "General" &&
      (state.access_level === null || state.access_level === "")
    ) {
      showAlert("Please provide access level", "warning");
      return;
    }
    if (
      state.user_type === "General" &&
      state.access_level === "State" &&
      (state.state_name.length === 0 || state.state_name === null)
    ) {
      showAlert("Please Select States", "warning");
      return;
    }
    if (
      state.user_type === "General" &&
      state.access_level === "Page" &&
      (state.page.length === 0 || state.page === null)
    ) {
      showAlert("Please Select Pages", "warning");
      return;
    }

    //for removing duplicate states on edit
    let stateData = "";
    if (state.state_name) {
      let ids = state.state_name.map(item => (item.pk).toString());
      stateData = [...new Set([...selectedStateList, ...ids])];
    } else {
      if (selectedStateList.length > 0) {
        stateData = [...selectedStateList];
      }
    }

    //for removing duplicate pages on edit
    let pageData = "";
    if (state.page) {
      let ids = state.page.map(item => (item.pk).toString());
      pageData = [...new Set([...selectedPageList, ...ids])];
    } else {
      if (selectedStateList.length > 0) {
        pageData = [...selectedPageList];
      }
    }

    const { status, message } = await addUsers(
      state.id,
      state.user_name,
      state.email_id,
      state.user_type,
      state.access_type,
      pageData,
      stateData,
      action
    );
    if (status) {
      if(action === "edit") {
        showAlert("User Info edited successfully", "success");
      } else {
        showAlert("User added successfully", "success");
      }
      
    } else if (status === 400) {
      showAlert(message, "error");
    } else {
      showAlert("Something went wrong", "error");
    }
    setState(initialState);
    if (action === "edit") {
      handleUpdate();
      handleEdit();
    }
    if (action === "create") {
      modalProps.setRefreshList(!modalProps.refreshList);
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <ModalContainer
        style={{ padding: action === "edit" ? "1.2rem 0rem" : "1.5rem 2rem" }}
      >
        <div className="option_container">
          <span className="option_name">Name</span>
          <div className="option_input">
            <CustomTextField
              value={state.user_name}
              name="user_name"
              label="Enter User Name"
              variant="outlined"
              onChange={handleTextChange}
              sx={{ width: "80%" }}
              required
            />
          </div>
        </div>
        <div className="option_container">
          <span className="option_name">Email ID</span>
          <div className="option_input">
            <FormControl sx={{ width: "39.3%" }}>
              <CustomTextField
                value={state.email_username}
                name="email_username"
                label="Enter Email Id"
                variant="outlined"
                onChange={(e) =>
                  setState({
                    ...state,
                    email_username: e.target.value,
                    email_id: e.target.value.concat(state.email_domain),
                  })
                }
                sx={{ width: "100%" }}
                required
              />
            </FormControl>
            <FormControl sx={{ width: "39.3%" }}>
              <AutoCompleteSelect
                size="small"
                variant="outlined"
                options={emailDomain}
                value={state.email_domain}
                autocomplete="true"
                label="Select Domain"
                name="email_domain"
                onChange={(newVal) =>
                  setState({
                    ...state,
                    email_domain: newVal,
                    email_id: state.email_username.concat(newVal),
                  })
                }
                sx={{ width: "100%" }}
              />
            </FormControl>
          </div>
        </div>
        <div className="option_container">
          <span className="option_name">User Type</span>
          <div className="option_input">
            <FormControl sx={{ width: "39.3%" }}>
              <AutoCompleteSelect
                size="small"
                variant="outlined"
                value={state.user_type}
                options={userType}
                autoComplete="true"
                text="user_type"
                name="user_type"
                label="Select User Type"
                onChange={handleUserTypeChange}
              />
            </FormControl>
          </div>
        </div>
        {state.user_type === "General" && (
          <>
            <div className="option_container">
              <span className="option_name">Access Level</span>
              <div className="option_input">
                <FormControl sx={{ width: "39.3%" }}>
                  <AutoCompleteSelect
                    size="small"
                    variant="outlined"
                    value={state.access_type}
                    options={accessLevel}
                    autoComplete="true"
                    label="Select Access Level"
                    text="access_type"
                    onChange={handleAccessLevelChange}
                  />
                </FormControl>
              </div>
            </div>
            {action === "edit" && state.access_type === "State" && state.state_name &&
              state.state_name.map((item, index) => {
                return (
                  <div className="option_container">
                    <span className="option_name">{index === 0 ? "States" : ""}</span>
                    <div className="option_input_smar_group">
                      <CustomTextField
                        value={item.name}
                        name="state_name"
                        label="State"
                        variant="outlined"
                        onChange={handleTextChange}
                        sx={{ width: "86%" }}
                        disabled
                      />
                    </div>
                    <StyledIconButton
                      disableRipple={true}
                      onClick={() => handleDelete(item)}
                    >
                      <DeleteOutlineOutlinedIcon className="delIcon" />
                    </StyledIconButton>
                  </div>
                )
              })
            }
            {state.access_type === "State" && (
              <div className="option_container">
                <span className="option_name">{action === "edit" ? "Add more States" : "States"}</span>
                <div className="option_input_last">
                  <FormControl sx={{ width: "80%" }}>
                    <AutoCompleteMultiState
                      size="small"
                      label="Select State"
                      // value={state.name}
                      options={stateList}
                      text="state_name"
                      onChange={handleChange}
                    />
                  </FormControl>
                </div>
              </div>
            )}

            {action === "edit" && state.access_type === "Page" && state.page &&
              state.page.map((item, index) => {
                return (
                  <div className="option_container">
                    <span className="option_name">{index === 0 ? "Page" : ""}</span>
                    <div className="option_input_smar_group">
                      <CustomTextField
                        value={item.page_name}
                        name="page_name"
                        label="Page"
                        variant="outlined"
                        onChange={handleTextChange}
                        sx={{ width: "86%" }}
                        disabled
                      />
                    </div>
                    <StyledIconButton
                      disableRipple={true}
                      onClick={() => handleDeletePage(item)}
                    >
                      <DeleteOutlineOutlinedIcon className="delIcon" />
                    </StyledIconButton>
                  </div>
                )
              })
            }
            {state.access_type === "Page" && (
              <div className="option_container">
                <div className="option_name">Page</div>
                <div className="option_input_last">
                  <FormControl sx={{ width: "80%" }}>
                    <AutoCompleteMultiPageSelect
                      size="small"
                      variant="outlined"
                      text="Pages"
                      limitTags={1}
                      onInputChange={onInputChange}
                      // value={state.platform}
                      options={pageList}
                      autoComplete="true"
                      label="Select Page(s)"
                      onChange={handlePageListChange}
                      getData={getPagesData}
                      currentPage={currentPage}
                      pageCount={pageCount}
                    />
                  </FormControl>
                </div>
              </div>
            )}
          </>
        )}

        {action === "edit" ? (
          <div className="option_container">
            <div className="option_name"></div>
            <div className="option_input">
              <CustomButton
                variant="contained"
                className="button_style"
                type="submit"
              >
                Save
              </CustomButton>
              <CustomButton
                variant="outlined"
                className="cancel_style"
                onClick={handleEdit}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        ) : (
          <div className="option_container">
            <div className="option_name"></div>
            <div className="option_input">
              <CustomButton
                variant="contained"
                sx={{
                  color: "white",
                  borderRadius: "1rem",
                  textTransform: "none",
                }}
                type="submit"
              >
                Create an User
              </CustomButton>
            </div>
          </div>
        )}
      </ModalContainer>
    </form>
  );
};

export default AddUserdetails;
