import React, { useContext, useState, useEffect } from "react";

//mui
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { ModalContext } from "contexts/ModalContext";
import SelectModal from "./selectModal";

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  margin: 0,
  // padding: "1em 3em",
  padding: "1rem 1.5rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #EAEAEA",
  [theme.breakpoints.down("xl")]: {
    padding: "0.9rem 1.5rem",
  },
  "& .title": {
    fontWeight: 600,
    color: "#133933",
    fontSize: "20px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "17px",
    },
  },
  "& .closeButton": {
    height: "23px",
    width: "23px",
    [theme.breakpoints.down("xl")]: {
      height: "22px",
      width: "22px",
    },
    "& .closeIcon": {
      fontSize: "1.4rem",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xl")]: {
        fontSize: "1.3rem",
      },
    },
  },
}));

const NewDialogTitle = (props) => {
  const { children, classes, onClose, modalProps, ...other } = props;
  return (
    <StyledDialogTitle {...other}>
      <Typography className="title">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className="closeButton"
          onClick={onClose}
        >
          <CancelIcon className="closeIcon" />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

const ModalMain = () => {
  const { modalKey, modalProps, hideModal, modalData } =
    useContext(ModalContext);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    hideModal();
  };

  useEffect(() => {
    modalKey ? setOpen(true) : setOpen(false);
  }, [modalKey]);
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={
        modalProps ? (modalProps?.width ? modalProps?.width : "md") : "md"
      }
      fullWidth={modalProps ? true : false}
      fullScreen={modalProps ? (modalProps?.fullscreen ? true : false) : false}
      height={"auto"}
    >
      <NewDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        modalProps={modalProps}
      >
        {modalProps && modalProps.title}
      </NewDialogTitle>
      <SelectModal
        modalKey={modalKey}
        modalData={modalData}
        modalProps={modalProps}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default ModalMain;
