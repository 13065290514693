export function matchPath(path, key) {
  return path.split("/").includes(key);
};

// function to convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str) => {

  // converting first letter to uppercase
  if(str) {
    const capitalized = str.replace(/^./, str[0].toUpperCase());
  
    return capitalized;
  }

  return "";
};

export const getPreviousWeek = () => {
  const today = new Date();
  const oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  const startOfPrevWeek = new Date(oneWeekAgo.getTime() - (oneWeekAgo.getDay() - 1) * 24 * 60 * 60 * 1000);
  const endOfPrevWeek = new Date(oneWeekAgo.getTime() + (7 - oneWeekAgo.getDay()) * 24 * 60 * 60 * 1000);
  return { start: startOfPrevWeek, end: endOfPrevWeek };
};

export const getPreviousMonth = () => {
  const today = new Date();
  const firstDayOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastDayOfPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  return { start: firstDayOfPrevMonth, end: lastDayOfPrevMonth };
};