//icons
import {ReactComponent as Users} from "assets/users.svg";
import {ReactComponent as Pages} from "assets/layers.svg";
import {ReactComponent as Smar} from "assets/boxes.svg";
import {ReactComponent as Reports} from "assets/archive.svg";
import {ReactComponent as ContentTagging} from "assets/content-tagging.svg";
import {ReactComponent as MediaMonitoring} from "assets/monitor.svg";
import {ReactComponent as UsersCol} from "assets/users_col_blue.svg";
import {ReactComponent as PagesCol} from "assets/layers_col_blue.svg";
import {ReactComponent as SmarCol} from "assets/boxes_col_blue.svg";
import {ReactComponent as ReportsCol} from "assets/archive_col_blue.svg";
import {ReactComponent as ContentTaggingCol} from "assets/content-tagging-col-blue.svg";
import {ReactComponent as MediaMonitoringCol} from "assets/monitor_col.svg";
import {ReactComponent as SmarDashboard} from "assets/Analytics.svg";
import {ReactComponent as SmarDashboardCol} from "assets/analytics-col.svg";


const NavLinks = [
  {
    primary: "Users",
    key: "users",
    icon: Users,
    iconcol: UsersCol,
    // secondary: [{ text: "People", key: "people" }],
  },
  {
    primary: "Pages",
    key: "pages",
    icon: Pages,
    iconcol: PagesCol,
    secondary: [
      {
        text: "Facebook",
        key: "facebook",
      },
      {
        text: "Twitter",
        key: "twitter",
      },
      {
        text: "YouTube",
        key: "youtube",
      },
      {
        text: "Instagram",
        key: "instagram",
      },
      {
        text: "On Demand Scraping",
        key: "on-demand-scraping"
      }
    ],
  },
  {
    primary: "SMAR Group",
    key: "smar",
    icon: Smar,
    iconcol: SmarCol,
  },
  {
    primary: "Reports",
    key: "reports",
    icon: Reports,
    iconcol: ReportsCol,
    secondary: [
      {
        text: "Generated Reports",
        key: "generated_reports",
      },
      {
        text: "Generate Report",
        key: "generate_report",
      },
    ],
  },
  {
    primary: "Content Tagging",
    key: "content-tagging",
    icon: ContentTagging,
    iconcol: ContentTaggingCol,
    secondary: [
      {
        text: "Overview",
        key: "overview",
      },
      {
        text: "Tagging",
        key: "tagging",
      },
      {
        text: "Dashboard",
        key: "dashboard",
      },
      {
        text: "MP Reports",
        key: "mp-reports",
      }
    ],
  },
  {
    primary: "Media Monitoring",
    key: "media-monitoring",
    icon: MediaMonitoring,
    iconcol: MediaMonitoringCol,
    secondary: [
      {
        text: "Electronic Media",
        key: "electronic-media",
      },
      {
        text: "Twitter",
        key: "twitter",
      },
      {
        text: "Print and Digital Media",
        key: "print-and-digital-media"
      }
      ,
      {
        text:"Campaign Ads",
        key:"campaign-ads"
      },
      {
        text: "Dashboard",
        key: "dashboard",
      },
      {
        text: "Content Logs",
        key: "content-logs",
      }
    ],
  },
  {
    primary: "SMAR Dashboard",
    key: "smar-dashboard",
    icon: SmarDashboardCol,
    iconcol: SmarDashboard,
  },
];

export default NavLinks;
