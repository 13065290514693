import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    // flexGrow: 1,
    flexDirection: "row",
    borderLeft: "1px solid #000000",
    // borderRight: "1px solid #000000",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#FFFFFF",
    // flexBasis: 42
  },
  container_rank: {
    width: "7%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_page_name: {
    width: "33%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  // container_party: {
  //   width: "18%",
  //   textAlign: "center",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "row",
  //   height: 60,
  //   paddingHorizontal: 6,
  // },
  container_total_score: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_total_posts: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_total_shares: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_top_ten: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_max_shares: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  container_median_shares: {
    width: "10%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 35,
    paddingHorizontal: 2,
    borderRight: "1px solid #000000",
    borderBottom: "1px solid #000000",
  },
  column_heading: {
    fontSize: "11px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    color: "#303030",
    fontFamily: "Roboto",
    fontWeight: 400,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 10,
  },
});

const PdfTableBodyNoPartyTailWind = ({ data, start_date, end_date, platform, reportGeneratedType, fileName ,heading,reportType}) => {
  const [rows, setRows] = useState("");
  const [header,setHeader]=useState(false);
  const [formattingData, setFormattingData] = useState([]);

  const randomColour = (field, value) => {
    if (value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const data = [...formattingData].sort((firstVal, secondVal) => {
        return secondVal[`${field}`] - firstVal[`${field}`]
      });
      let index = data.findIndex(x => x[`${field}`] === value);
      if (index >= 10) {
        return "#FFFFFF";
      }
      // const colors = ["#5FF1F1", "#8EFBFB", "#B0F9F9", "#DAFFFF", "#ECFFFF", "#5cd60c"];
      const colors = ["#57BB8A", "#69C297", "#7CCAA4", "#8FD1B1", "#A1D9BE", "#B4E0CB", "#C7E8D8", "#D9EFE5", "#ECF7F2", "#FFFFFF"];
      var random_color = colors[index];
      return random_color;
    }
    return "#FFFFFF";
  };

  useEffect(() => {
    if (data.length > 0) {
      setRows(data);
      setFormattingData([...data]);
    } else {
      setRows([]);
      setFormattingData([]);
    }
  }, [data]);

  return (
    <>
      {rows &&
        rows.map((val, index) => {
        if(index>2){        
            return (
              <View style={styles.row} key={index} wrap={false}>
                <View
                  style={[
                    styles.container_rank,
                  ]}
                >
                  <Text style={styles.column_heading}>{val?.position}</Text>
                </View>
                {platform.toLowerCase() !== "twitter" ? (
                  <View
                    style={[
                      styles.container_page_name,
                    ]}
                  >
                    <Text style={styles.column_heading}>
                      <Link
                        src={`https://facebook.com/${val.page__page_id}`}
                        style={{
                          textDecoration: "none",
                          borderBottom: "none",
                          color: "#0000FF",
                        }}
                      >
                        {val.page__page_name.split('').join('\u200B')}
                      </Link>
                    </Text>
                  </View>
                ) : (
                  <View
                    style={[
                      styles.container_page_name,
                    ]}
                  >
                    <Text style={styles.column_heading}>
                      {val.page__page_id}
                    </Text>
                  </View>
                )}
                <View
                  style={[
                    styles.container_total_score,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("score", val?.score)}` : ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("score", val?.score)}` : ""
                            : "",
                      },
                    ]}
                  >
                    {val?.score}
                  </Text>
                </View>
                <View
                  style={[
                    styles.container_total_posts,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("total_posts", val?.total_posts)}` : ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("total_posts", val?.total_posts)}` : ""
                            : "",
                      },
                    ]}
                  >
                    {platform.toLowerCase() !== "twitter"
                      ? val.total_posts
                      : val.total_tweets}
                  </Text>
                </View>
                <View
                  style={[
                    styles.container_total_shares,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                            "total_shares",
                            val?.total_shares
                          )}`: ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                              "total_shares",
                              val?.total_shares
                            )}` : ""
                            : "",
                      },
                    ]}
                  >
                    {platform.toLowerCase() !== "twitter"
                      ? val.total_shares.toLocaleString()
                      : val.total_retweets.toLocaleString()}
                  </Text>
                </View>
                <View
                  style={[
                    styles.container_top_ten,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                            "num_posts_in_top",
                            val?.num_posts_in_top
                          )}` : ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                              "num_posts_in_top",
                              val?.num_posts_in_top
                            )}` : ""
                            : "",
                      },
                    ]}
                  >
                    {platform.toLowerCase() !== "twitter"
                      ? val.num_posts_in_top
                      : val.num_tweets_in_top}
                  </Text>
                </View>
                <View
                  style={[
                    styles.container_max_shares,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("max_shares", val?.max_shares)}` : ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour("max_shares", val?.max_shares)}` : ""
                            : "",
                      },
                    ]}
                  >
                    {platform.toLowerCase() !== "twitter"
                      ? val.max_shares
                      : val.max_retweets}
                  </Text>
                </View>
                <View
                  style={[
                    styles.container_median_shares,
                    {
                      backgroundColor:
                        platform.toLowerCase() !== "twitter"
                          ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                            "median_shares",
                            val?.median_shares
                          )}` : ""
                          : "",
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.column_heading,
                      {
                        backgroundColor:
                          platform.toLowerCase() !== "twitter"
                            ? fileName !== "Ireland National Parties" && fileName !== "Ireland National Leaders" ? `${randomColour(
                              "median_shares",
                              val?.median_shares
                            )}` : ""
                            : "",
                      },
                    ]}
                  >
                    {platform.toLowerCase() !== "twitter"
                      ? val.median_shares
                      : val.median_retweets}
                  </Text>
                </View>
              </View>
            );
          }
          
        })
      }
    </>
   
    
  );
};

export default PdfTableBodyNoPartyTailWind;