import React, { useContext, useState } from "react";

// MUI
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";

//components
import CustomButton from "components/Common/CustomButton";

// apis
import { addNewCategory } from "apis/componentsAction/tagging";

// contexts
import { AlertContext } from "contexts/AlertContext";
import { AuthContext } from "contexts/AuthContext";
import { ModalContext } from "contexts/ModalContext";

const Container = styled("div")(({ theme }) => ({
  height: "350px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "& .search_bar": {
    padding: "1rem 1.5rem",
  },
  "& .button_container": {
    borderTop: "1px solid #E7F2FF",
    padding: "0.5rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    "& .add_style": {
      background: "#009DFF",
      color: "#FFFFFF",
      borderRadius: "20px",
      padding: "0.1rem 2rem",
      fontSize: "14px",
    },
    "& .cancel_style": {
      color: "#787575",
    },
  },
}));

const AddCategory = ({ handleClose }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { showAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);
  const { modalProps } = useContext(ModalContext);

  const handleAddCategory = async () => {
    setLoading(true);
    if (!value || value?.trim() === "") {
      setLoading(false);
      showAlert("Please type a new category name", "error");
      return;
    }
    const response = await addNewCategory(value, user?.pk);
    modalProps?.getCategoryList && (await modalProps?.getCategoryList());
    setLoading(false);
    handleClose();
    if (response.error !== null) {
      showAlert("Something went wrong!", "error");
    } else {
      showAlert("Successfully add a new category", "success");
    }
  };

  return (
    <Container>
      <div className="search_bar">
        <TextField
          variant="outlined"
          placeholder="Add Category"
          size="small"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          inputProps={{
            className: "text_field_style",
          }}
          sx={{
            width: "100%",
          }}
        />
      </div>
      <div className="button_container">
        <CustomButton className="cancel_style" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton
          className="add_style"
          onClick={handleAddCategory}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              sx={{
                width: "18px !important",
                height: "18px !important",
                color: "#FFF",
              }}
            />
          ) : (
            "Add"
          )}
        </CustomButton>
      </div>
    </Container>
  );
};

export default AddCategory;
