import React, { useState, useContext } from "react";

// MUI
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

//components
import CustomButton from "components/Common/CustomButton";

// apis
import {
  inActivePrimeTimeLog,
  inActivePrintLog,
  inActiveYoutubeLog,
} from "apis/componentsAction/log-history-mmt";

// contexts
import { AlertContext } from "contexts/AlertContext";
import { ModalContext } from "contexts/ModalContext";
import { deleteElectronicCampaignAd, deletePrintCampaignAd } from "apis/componentsAction/campaign-ads";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& .text": {
    width: "100%",
    borderBottom: "1px solid #E7F2FF",
    paddingBottom: "2rem",
    display: "flex",
    fontWeight: 600,
    justifyContent: "center",
    paddingTop: "1rem",
  },
  "& .button_container": {
    padding: "0.7rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    "& .add_style": {
      background: "red",
      color: "#FFFFFF",
      borderRadius: "20px",
      padding: "0.1rem 1rem",
      fontSize: "12px",
    },
    "& .cancel_style": {
      padding: "0.1rem 1rem",
      fontSize: "12px",
      color: "#787575",
    },
  },
}));

const DeleteLog = ({ handleClose, action }) => {
  const { modalProps } = useContext(ModalContext);
  const { showAlert } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const handleDeleteLog = async () => {
    setLoading(true);
    try {
      if (action === "delete-prime-time") {
        const { data, error } = await inActivePrimeTimeLog(modalProps.id);
      } else if (action === "delete-print") {
        const { data, error } = await inActivePrintLog(modalProps.id);
      } else if (action === "delete-youtube") {
        const { data, error } = await inActiveYoutubeLog(modalProps.id);
      }
      else if (action === "delete-print-campaign") {
        const { data, error } = await deletePrintCampaignAd(modalProps.id);
      }
      else if (action ===  'delete-electronic-campaign') {
        const { data, error } = await deleteElectronicCampaignAd(modalProps.id);
      }
      modalProps.toggleUpdateTriggerFunction();
      setLoading(false);
      showAlert("successfully deleted", "success");
      handleClose();
    } catch (error) {
      // Handle the error here, you can show an alert or perform other actions
      console.error("log is not deleted:", error);
      setLoading(false);
    }
  };
  return (
    <Container>
      <Typography className="text">Do You Want To Delete?</Typography>
      <div className="button_container">
        <CustomButton className="cancel_style" onClose={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton className="add_style" onClick={handleDeleteLog}>
          Delete
        </CustomButton>
      </div>
    </Container>
  );
};

export default DeleteLog;
