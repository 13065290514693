// MUI
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoaderContainer = styled("div")(({ theme, diff }) => ({
  height: `calc(100vh - ${theme.spacing(diff)})`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const Loader = ({diff}) => {
  return (
    <LoaderContainer diff={diff}>
      <Box sx={{ display: "flex" }} className="loader">
        <CircularProgress />
      </Box>
    </LoaderContainer>
  );
};

export default Loader;