import React, { useEffect, useRef } from "react";
//import
import * as d3 from "d3";

const D3DivergentBarChart = ({
  data,
  indexBy,
  fields,
  labels,
  width = 800,
  height = 400,
}) => {
  const chartRef = useRef(null);
  const [leftField, rightField] = fields;

  useEffect(() => {
    if (chartRef.current) {
      d3.select(chartRef.current).selectAll("*").remove();

      const margin = { top: 60, right: 30, bottom: 20, left: 100 };
      const barHeight = 21;
      const barPadding = 15;
      const chartWidth = width - margin.left - margin.right;
      const chartHeight = data.length * (barHeight + barPadding);
      const height = chartHeight + margin.top + margin.bottom;

      const svg = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", "100%")
        .attr("height", `400px`)
        .attr("viewBox", `0 0 ${width} ${height}`)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      const legend = svg
        .append("g")
        .attr("transform", `translate(${chartWidth / 2 - 60}, -50)`);

      legend
        .append("rect")
        .attr("x", -15)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", "rgba(136, 132, 216, 0.8)");

      legend
        .append("text")
        .attr("x", 5)
        .attr("y", 12)
        .attr("fill", "#4F4F4F")
        .attr("font-size", "8px")
        .text(labels[0]);

      legend
        .append("rect")
        .attr("x", 75)
        .attr("width", 15)
        .attr("height", 15)
        .attr("fill", "rgba(130, 202, 157, 0.8)");

      legend
        .append("text")
        .attr("x",95)
        .attr("y", 12)
        .attr("font-size", "8px")
        .attr("fill", "#4F4F4F")
        .text(labels[1]);

      const maxRight = Math.max(...data.map((d) => d[rightField]));
      const maxLeft = Math.max(...data.map((d) => Math.abs(d[leftField])));
      const scaleFactor = maxRight / maxLeft;

      const leftData = data.map(
        (item) => -Math.abs(item[leftField]) * scaleFactor
      );
      const rightData = data.map((item) => item[rightField]);

      const maxValue = Math.max(
        Math.max(...leftData.map(Math.abs)),
        Math.max(...rightData)
      );

      const x = d3
        .scaleLinear()
        .domain([-maxValue, maxValue])
        .range([0, chartWidth]);

     
      const y = d3.scaleBand()
        .domain(data.map(d => d[indexBy]))
        .range([0, data.length * (barHeight + barPadding)])
        .padding(0.1);

      const customTicks = (scale, side, field, factor) => {
        const filteredData = data.map((d) =>
          side === "negative" ? -d[field] * factor : d[field]
        );

        let range, step;
        if (side === "negative") {
          const minValue = Math.min(...filteredData);
          range = [minValue, 0];
          step = Math.abs(minValue) / 5;
        } else {
          const maxValue = Math.max(...filteredData);
          range = [0, maxValue];
          step = maxValue / 5;
        }
        if (step === 0) return [];
        return d3.range(range[0], range[1] + step, step);
      };

      const negativeTicks = customTicks(x, "negative", leftField, scaleFactor);
      const positiveTicks = customTicks(x, "positive", rightField);

      const xAxis = d3
        .axisTop(x)
        .tickFormat((d) =>
          d < 0
            ? `${Math.abs(Math.round(d / scaleFactor))}`
            : Math.abs(d) >= 1000
            ? ` ${(Math.abs(d) / 1000).toFixed(0)}k`
            : Math.round(Math.abs(d))
        )
        .tickValues([...negativeTicks, 0, ...positiveTicks]);

        svg.append("g")
        .attr("class", "x-axis")
        .call(xAxis)
        .attr("color", "#4F4F4F") 
        .selectAll("text") 
        .attr("fill", "#4F4F4F")
        .style("font-size", "8px ");

        const yAxis = d3.axisLeft(y)
        .tickFormat((d) => {
          const label = d.length > 15 ? `${d.slice(0, 15)}...` : d; 
          return label;
        });
      
      svg.append("g")
        .attr("class", "y-axis")
        .call(yAxis)
        .attr("color", "#4F4F4F")
        .call(g => {
          g.selectAll(".tick")
            .attr("transform", function() {
              const currentTransform = d3.select(this).attr("transform");
              const translate = currentTransform.match(/translate\(([\d.-]+),\s*([\d.-]+)\)/);
              if (translate) {
                const x = parseFloat(translate[1]);
                const y = parseFloat(translate[2]) - 5; // Adjust this value to move ticks up
                return `translate(${x},${y})`;
              }
              return currentTransform;
            });
      
            g.selectAll(".tick text") 
            .attr("fill", "#4F4F4F")
            .style("font-size", "8px ")
            .attr("dy", "-0.03em");
        });


      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("position", "absolute")
        .style("visibility", "hidden")
        .style("background", "#000")
        .style("padding", "8px")
        .style("border-radius", "4px")
        .style("font-size", "10px")
        .style("pointer-events", "none")
        .style("color", "white")
        .style("opacity", "0.85");

        const barsLeft = svg.selectAll(".bar-left")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar-left")
        .attr("y", d => y(d[indexBy]))        .attr("height", `${barHeight}px`)
        .attr("x", d => x(Math.min(0, -d[leftField] * scaleFactor)))
        .attr("width", d => Math.abs(x(-d[leftField] * scaleFactor) - x(0)))
        .attr("fill", "rgba(136, 132, 216, 0.8)")
        .on("mouseover", function (event, d) {
          tooltip
          .html(`<b>${d[indexBy]}</b><br>${labels[0]}: ${d[leftField]}`)            
          .style("visibility", "visible");
          d3.select(this).attr("fill", "rgba(136, 132, 216, 1.0)");
        })
        .on("mousemove", function (event) {
          tooltip
            .style("top", event.pageY - 10 + "px")
            .style("left", event.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.style("visibility", "hidden");
          d3.select(this).attr("fill", "rgba(136, 132, 216, 0.8)");
        });

        const barsRight = svg.selectAll(".bar-right")
        .data(data)
        .enter().append("rect")
        .attr("class", "bar-right")
        .attr("y", d => y(d[indexBy]))
        .attr("height", `${barHeight}px`)
        .attr("x", x(0))
        .attr("width", d => x(d[rightField]) - x(0))
        .attr("fill", "rgba(130, 202, 157, 0.8)")
        .on("mouseover", function (event, d) {
          tooltip
            .html(`<b>${d[indexBy]}</b><br>${labels[1]}: ${d[rightField]}`)
            .style("visibility", "visible");
          d3.select(this).attr("fill", "rgba(130, 202, 157, 1.0)");
        })
        .on("mousemove", function (event) {
          tooltip
            .style("top", event.pageY - 10 + "px")
            .style("left", event.pageX + 10 + "px");
        })
        .on("mouseout", function () {
          tooltip.style("visibility", "hidden");
          d3.select(this).attr("fill", "rgba(130, 202, 157, 0.8)");
        });

      svg
        .selectAll(".text-left")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "text-left")
        .attr("x", (d) => {
          const xPos = x(Math.min(0, -d[leftField] * scaleFactor));
          const textWidth = Math.abs(d[leftField]).toString().length;
          return xPos < textWidth ? xPos - textWidth + 40 : xPos - 20;
        })
        .attr("y", d => y(d[indexBy]) + barHeight / 2)
        .attr("dy", ".35em")
        .attr("fill", "#4F4F4F")
        .attr("font-size", "8px")
        .attr("text-anchor", (d) => {
          const xPos = x(Math.min(0, -d[leftField] * scaleFactor));
          const textWidth = Math.abs(d[leftField]).toString().length * 6;
          return xPos < textWidth ? "end" : "start";
        })
        .text((d) => (d[leftField] !== 0 ? Math.abs(d[leftField]) : ""))
        .style("visibility", (d) => {
          const xPos = x(Math.min(0, -d[leftField] * scaleFactor));
          const textWidth = Math.abs(d[leftField]).toString().length * 6;
          return xPos < -textWidth ? "hidden" : "visible";
        });

      svg
        .selectAll(".text-right")
        .data(data)
        .enter()
        .append("text")
        .attr("class", "text-right")
        .attr("x", (d) => x(d[rightField]) + 18)
        .attr("y", d => y(d[indexBy]) + barHeight / 2)
        .attr("dy", ".35em")
        .attr("fill", "#4F4F4F")
        .attr("font-size", "8px")
        .attr("text-anchor", "end")
        .text((d) =>
          d[rightField] !== 0
            ? Math.abs(d[rightField]) >= 1000
              ? `${(Math.abs(d[rightField]) / 1000).toFixed(0)}k`
              : Math.round(Math.abs(d[rightField]))
            : ""
        );
        svg.append("line")
        .attr("x1", x(0))
        .attr("x2", x(0))
        .attr("y1", 0)
        .attr("y2", chartHeight)
        .attr("stroke", "#4F4F4F")
        .attr("stroke-width", 1)
        .attr("stroke-linecap", "square")
        .raise();
          }
  }, [data, indexBy, fields, labels, width, height]);

  return <div ref={chartRef} />;
};

export default D3DivergentBarChart;
