import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  reportHeading: {
    height: 40,
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: 'center',
  },
  headingText: {
    fontSize: "12px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#4F4F4F",
    fontFamily: 'Roboto',
    fontWeight: 700,
    backgroundColor: "#f2f2f2",
    paddingHorizontal: 4,
    paddingVertical: 2,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
    border: "1px solid #e0e0e0",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#084B83",
    // flexBasis: 42
  },
  row_new: {
    // flexGrow: 1,
    flexDirection: 'row',
    borderBottom: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#FFFFFF",
    // flexBasis: 42
  },
  container_rank: {
    width: '6%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_page_name: {
    width: '21%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 6,
  },
  // container_party: {
  //   width: '18%',
  //   textAlign: 'center',
  //   alignItems: 'center',
  //   justifyContent: "flex-start",
  //   flexDirection: 'row',
  //   height: 60,
  //   paddingHorizontal: 6,
  // },
  container_total_score: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_total_posts: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_total_shares: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_top_ten: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_max_shares: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_median_shares: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },
  container_days_won: {
    width: '7%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 60,
    paddingHorizontal: 4,
  },

  container_rank_new: {
    width: '6%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_page_name_new: {
    width: '21%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 6,
  },
  container_party_new: {
    width: '18%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 6,
  },
  container_total_score_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_total_posts_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_total_shares_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_top_ten_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_max_shares_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_median_shares_new: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_days_won_new: {
    width: '7%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },

  column_heading: {
    fontSize: "9px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#FFFFFF",
    fontFamily: 'Roboto',
    fontWeight: 700,
  },
  column_heading_new: {
    fontSize: "7px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#303030",
    fontFamily: 'Roboto',
    fontWeight: 400,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 10,
  },
});

const PdfTableHeaderWeeklyNoParty = ({ heading, platform, reportType, data, start_date, end_date }) => {
  const [rows, setRows] = useState("");
  const [formattingData, setFormattingData] = useState([]);

  const randomColour = (field, value) => {
    if(value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const data = [...formattingData].sort((firstVal, secondVal) => {
        return secondVal[`${field}`] - firstVal[`${field}`]
      });
      let index_new = data.findIndex(x => x[`${field}`] === value);
      if (index_new >= 7) {
        return "#FFFFFF";
      }
      // const colors = ["#5FF1F1", "#8EFBFB", "#B0F9F9", "#DAFFFF", "#ECFFFF", "#5cd60c"];
      const colors = ["#70db70", "#85e085", "#99e699", "#adebad", "#c2f0c2", "#d6f5d6", "#ebfaeb"];
      var random_color = colors[index_new];
      return random_color;
    }
    return "#FFFFFF";
  };

  useEffect(() => {
    if (data.length > 0) {
      setRows(data);
      setFormattingData([...data]);
    } else {
      setRows("");
      setFormattingData([]);
    }
  }, [data]);

  return (
    <View wrap={false}>
      <View style={styles.reportHeading}>
        <Text style={styles.headingText}>{heading} {platform.toLowerCase() === "twitter" ? "Account" : ""}</Text>
      </View>
      <View style={styles.row} wrap={false}>
        <View style={styles.container_rank}>
          <Text style={styles.column_heading}>Rank</Text>
        </View>
        <View style={styles.container_page_name}>
          <Text style={styles.column_heading}>{platform.toLowerCase() === "facebook" ? "Page Name" :platform.toLowerCase() === "instagram"?"Account Name":platform.toLowerCase() === "youtube" ? "Channel Name" : "Account Name"}</Text>
        </View>
        {/* <View style={styles.container_party}>
          <Text style={styles.column_heading}>Party</Text>
        </View> */}
        <View style={styles.container_total_score}>
          <Text style={styles.column_heading}>Total Score</Text>
        </View>
        <View style={styles.container_total_posts}>
          <Text style={styles.column_heading}>{(platform.toLowerCase() === "facebook"||platform.toLowerCase() === "instagram") ? "Total Posts" :platform.toLowerCase() === "youtube" ?"Total Videos" : "Total Tweets"}</Text>
        </View>
        <View style={styles.container_total_shares}>
          <Text style={styles.column_heading}>{platform.toLowerCase() === "facebook" ? "Total Shares" :platform.toLowerCase() === "instagram"?"Total Likes":platform.toLowerCase() === "youtube" ?"Total Views" : "Total Retweets"}</Text>
        </View>
        <View style={styles.container_top_ten}>
          <Text style={styles.column_heading}>{(platform.toLowerCase() === "facebook"||platform.toLowerCase() === "instagram") ? reportType <= 6 ? "No. of Posts in Top 10" : "No. of Posts in Top 30" :platform.toLowerCase() === "youtube" ? reportType <= 6 ? "No. of Videos in Top 10" : "No. of Videos in Top 30": reportType <= 6 ? "No. of Tweets in Top 10" : "No. of Tweets in Top 30"}</Text>
        </View>
        <View style={styles.container_max_shares}>
          <Text style={styles.column_heading}>{platform.toLowerCase() === "facebook" ? "Max Shares of a Post" :platform.toLowerCase() === "instagram"?"Max Likes of a Post":platform.toLowerCase() === "youtube" ? "Max Views of a Post" : "Max Retweets of a Tweet"}</Text>
        </View>
        <View style={styles.container_median_shares}>
          <Text style={styles.column_heading}>{platform.toLowerCase() === "facebook" ? "Median Shares of a Post"  :platform.toLowerCase() === "instagram"?"Median Likes of a Post":platform.toLowerCase() === "youtube" ? "Median Views of a Post" : "Median Retweets of a Tweet"}</Text>
        </View>
        <View style={styles.container_days_won}>
          <Text style={styles.column_heading}>Days Won</Text>
        </View>
      </View>
      {rows &&
        rows.map((val, index) => {
          if (index <= 2) {
            return (
              <View style={styles.row_new} key={index} wrap={false}>
                <View style={styles.container_rank_new}>
                  <Text style={styles.column_heading_new}>{val?.position}</Text>
                </View>
                {platform.toLowerCase() !== "twitter" ?
                  <View style={styles.container_page_name_new}>
                    <Text style={styles.column_heading_new}>
                      <Link
                        src={platform.toLowerCase() === "youtube" ?`https://youtube.com/channel/${val.page__page_id}`:platform.toLowerCase() === "instagram" ?`https://instagram.com/${val.page__page_id}`:`https://facebook.com/${val.page__page_id}`}
                        style={{ textDecoration: 'none', borderBottom: 'none', color: '#0000FF', }}
                      >
                        {val.page__page_name}
                      </Link>
                    </Text>
                  </View> :
                  <View style={styles.container_page_name_new}>
                    <Text style={styles.column_heading_new}>
                      {val.page__page_id}
                    </Text>
                  </View>
                }
                {/* <View style={styles.container_party}>
                  <Text style={styles.column_heading}>{val.page__political_party__name}</Text>
                </View> */}
                <View style={styles.container_total_score_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("score", val?.score)}` : "" }]}
                  >
                    {val?.score}
                  </Text>
                </View>
                <View style={styles.container_total_posts_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("total_posts", val?.total_posts)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.total_posts : val.total_tweets}
                  </Text>
                </View>
                <View style={styles.container_total_shares_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("total_shares", val?.total_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.total_shares : val.total_retweets}
                  </Text>
                </View>
                <View style={styles.container_top_ten_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("num_posts_in_top", val?.num_posts_in_top)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.num_posts_in_top : val.num_tweets_in_top}
                  </Text>
                </View>
                <View style={styles.container_max_shares_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("max_shares", val?.max_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.max_shares : val.max_retweets}
                  </Text>
                </View>
                <View style={styles.container_median_shares_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("median_shares", val?.median_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.median_shares : val.median_retweets}
                  </Text>
                </View>
                <View style={styles.container_days_won_new}>
                  <Text
                    style={[styles.column_heading_new, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("no_of_days_won", val?.no_of_days_won)}` : "" }]}
                  >
                    {val?.no_of_days_won}
                  </Text>
                </View>
              </View>
            )
          }
        })}
    </View>
  );
};

export default PdfTableHeaderWeeklyNoParty;