import React, { useState, useEffect, useContext } from "react";

//mui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";

//contexts
import { AlertContext } from "contexts/AlertContext";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "250px",
  "& .MuiInputLabel-root": {
    fontSize: "12px",
    backgroundColor: "white",
    padding: "0 4px",
  },
  "& .MuiSelect-select": {
    fontSize: "12px",
    padding: "8px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const StyledMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: 224,
      maxWidth: 240,
      paddingTop: 0,
      "& .MuiMenuItem-root": {
        fontSize: "12px",
        paddingLeft: "0px",
      },
      "& .MuiTypography-root": {
        fontSize: "12px",
      },
      "& .MuiCheckbox-root": {
        padding: "4px",
      },
      "& .MuiMenu-list": {
        paddingTop: "0px",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.3em",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#cccccc",
        },
      },
    },
  },
};

const AutoCompleteGraphSelect = ({
  options,
  onChange,
  label,
  tabValue,
  selectedGraph,
}) => {
  const [selectedPages, setSelectedPages] = useState([]);
  const { showAlert } = useContext(AlertContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  useEffect(() => {
    onChange(selectedPages);
  }, [selectedPages, onChange]);
  useEffect(() => {
    selectedGraph === "total posts vs total shares" ||
    selectedGraph === "median shares vs max shares"
      ? setSelectedPages(options.slice(0, 5))
      : selectedGraph === "total posts vs median shares" &&
        setSelectedPages(options.slice(0, 5));
  }, [selectedGraph]);
  const handleChange = (event) => {
    const value = event.target.value;

    if (value.length === 0) {
      showAlert(
        `At least 1 ${
          tabValue === "facebook" ? "Page" : "Channel"
        } needs to be selected`,
        "error"
      );
      return;
    }
    if (value.length <= 5) {
      setSelectedPages(value);
    } else {
      showAlert(`Select Up to 5 ${tabValue === "facebook" ? "Page" : "Channel"}"`, "error");
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  return (
    <Box display="flex" justifyContent="end" alignItems="center">
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={
            selectedGraph === "total posts vs total shares" ||
            selectedGraph === "median shares vs max shares"
              ? "Select Up to 5 Pages"
              : "Select Up to 5 pages"
          }
          open={tooltipOpen}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <StyledFormControl variant="outlined">
            <InputLabel id="page-select-label">{label}</InputLabel>
            <Select
              labelId="page-select-label"
              multiple
              value={selectedPages}
              onChange={handleChange}
              onClick={handleTooltipClose}
              onMouseEnter={handleTooltipOpen}
              onMouseLeave={handleTooltipClose}
              renderValue={(selected) => `${selected.length} selected`}
              MenuProps={StyledMenuProps}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option} sx={{ fontSize: "12px" }}>
                  <Checkbox
                    checked={selectedPages.includes(option)}
                    disabled={
                      (selectedGraph === "total posts vs total shares" ||
                      selectedGraph === "median shares vs max shares"
                        ? selectedPages.length >= 5
                        : selectedPages.length >= 5) &&
                      !selectedPages.includes(option)
                    }
                  />
                  <ListItemText primary={option} sx={{ fontSize: "12px" }} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Tooltip>
      </ClickAwayListener>
    </Box>
  );
};

export default AutoCompleteGraphSelect;
