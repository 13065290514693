import React, { useState, useContext, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { accountService } from "utils/facebook-account-service";

// MUI
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Box } from "@mui/material";

//components
import AutoCompleteSelect from "components/Common/AutoCompleteSelect";
import AutoCompletePageSelect from "components/Common/AutoCompletePageSelect";
import CustomButton from "components/Common/CustomButton";
import AutoCompleteState from "components/Common/AutoCompleteState";
import AutoCompletePartySelect from "components/Common/AutoCompletePartySelect";
import AutoCompleteAcSelect from "components/Common/AutoCompleteAcSelect";
import AutoCompletePcSelect from "components/Common/AutoCompletePcSelect";
import AutoCompleteDistrict from "components/Common/AutoCompleteDistrict";
import CustomTextField from "components/Common/CommonTextField";
import AutoCompleteSmarList from "components/Common/AutoCompleteSmarList";
import AutoCompletePlatform from "components/Common/AutoCompletePlatform";

//data
import { countryMapping, instagramAccountType, pageCategory, pageSubCategory, region } from "utils/data";

//apis
import { getDistrictList, getACList, getPCList } from "apis/common/data";
import { getSmarList } from "apis/componentsAction/smar";
import {
  addInstagramHandle,
  addPages,
  addYouTubeHandle,
  handleBulkPagesUpload,
} from "apis/componentsAction/pages";

//context
import { DataContext } from "contexts/DataContext";
import { AlertContext } from "contexts/AlertContext";
import { ModalContext } from "contexts/ModalContext";
//Library Imports
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";

//Utils
import { capitalizeFirstLetter } from "utils/helpers";

//Assets
import SampleBulkPageAdditionSheet from "../../assets/files/bulk_page_addition_Sample.xlsx";

const ModalContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: "14px",
  padding: "1.5rem 2rem",
  overflowY: "scroll",
  "& .bulk-upload-main": {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    height: "300px",
    "& .upload-container": {
      cursor: "pointer",
      marginBottom: "1rem",
      marginTop: "1rem",
      border: `1px solid ${theme.palette.primary.outerborder}`,
      borderRadius: "8px",
      width: "50%",
      padding: "4rem",
      backgroundColor: "#F7FBFF",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginLeft: "2rem",
      position: "relative",
      "& .file-added-section": {
        "& .added-file-text": {
          textAlign: "center",
          color: "#4F4F4F",
          fontWeight: 400,
          fontSize: "16px",
        },
        "& .file-name-text": {
          textAlign: "center",
          color: "#0077E7",
          fontWeight: 700,
          fontSize: "14px",
        },
      },
      "& .uploaded-file-buttons-container": {
        position: "absolute",
        display: "flex",
        bottom: 12,
        right: 20,
        "& .generate-report-btn": {
          padding: "0.25rem 1rem",
          borderRadius: "30px",
          textTransform: "capitalize",
          display: "inline",
        },
      },
      "& .text-section": {
        marginTop: "1rem",
        "& .top-text": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "& .upload-text": {
            color: "#0077E7",
            fontWeight: 600,
            fontSize: "16px",
            [theme.breakpoints.down("xl")]: {
              fontSize: "14px",
            },
          },
          "& .drag-drop-text": {
            marginLeft: "0.25rem",
            color: "#4F4F4F",
            fontWeight: 400,
            fontSize: "16px",
            [theme.breakpoints.down("xl")]: {
              fontSize: "14px",
            },
          },
        },
        "& .extension-text": {
          color: "#787575",
          fontWeight: 400,
          fontSize: "14px",
          textAlign: "center",
          [theme.breakpoints.down("xl")]: {
            fontSize: "12px",
          },
        },
      },
    },
  },

  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: `${theme.palette.secondary.main}`,
  },
  "& .platform_pageType_contianer": {
    marginBottom: "0.6rem",
  },
  "& .platform": {
    display: "flex",
    alignItems: "center",
  },
  "& .radio_name": {
    fontWeight: "400",
    fontSize: "16px",
    width: "25%",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .radio_group": {
    width: "75%",
    "& .MuiFormControlLabel-root": {
      marginRight: "1.5rem",
    },
    "& .MuiTypography-root": {
      fontSize: "16px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
    "& .MuiRadio-root": {
      padding: "0.5rem 0.4rem",
      "& svg": {
        fontSize: "1.4rem",
        [theme.breakpoints.down("xl")]: {
          fontSize: "1.2rem",
        },
      },
    },
  },
  "& .auth_container": {
    margin: "0 0 0.4rem 0",
  },
  "& .page_option_container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.3rem",
  },
  "& .option_name": {
    width: "25%",
    fontSize: "16px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .option_input": {
    width: "75%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .smar_input": {
    width: "75%",
    display: "flex",
    gap: "0.5rem",
    alignItems: "flex-end",
  },
  "& .option_input_smar_group": {
    width: "70%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .add_button_style": {
    borderRadius: "20px",
    padding: "0.1rem 1rem 0.1rem 0.4rem",
    verticalAlign: "middle",
    "& .button_text": {
      fontSize: "16px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
    "& .MuiButton-startIcon>*:nth-of-type(1)": {
      fontSize: "1.1rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "1rem",
      },
    },
  },
  "& .page_add_button": {
    color: "#FFFFFF",
    fontSize: "16px",
    borderRadius: "20px",
    background: "#ADE4DC",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
    "&:hover": {
      background: "#3EBFAB",
    },
  },
  "& .button_style": {
    borderRadius: "20px",
    color: "#FFFFFF",
    background: theme.palette.primary.main,
    width: "20%",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
      width: "15%",
    },
  },
  "& .cancel_style": {
    borderRadius: "20px",
    color: theme.palette.primary.main,
    width: "20%",
    fontSize: "16px",
    fontWeight: "500",
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    color: "#4F4F4F",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "1.4rem",
    color: "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
}));

const initialState = {
  id: "",
  page_id: "",
  page_name: "",
  long_token: "",
  page_category: "",
  page_sub_category: "",
  party: "",
  region_type: {
    id: "",
    name: "",
  },
  // object_id: "",
  state: "",
  district: "",
  ac: "",
  pc: "",
  smar_group_set: "",
  country:{
    id:"",
    name:""
  },
  type:'Business'
};

const PageAddInternal = ({
  handleClose,
  modalData,
  action,
  modalProps,
  handleEdit,
  handleUpdate,
}) => {
  const [isAuth, setIsAuth] = useState(false);
  const [state, setState] = useState(initialState);
  const [country,setCountryList] =useState([])
  const [smarGroupList, setSmarGroupList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [acList, setAcList] = useState([]);
  const [pcList, setPcList] = useState([]);
  const [selectedPage, setSelectedPage] = useState({ name: "" });
  const [pagesInfo, setPagesInfo] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [selectedSmarList, setSelectedSmarList] = useState([]);
  const [radioPlatform, setRadioPlatform] = useState(modalProps.platform);
  const [radioPageType, setRadioPageType] = useState("Internal");
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit] = useState(40);
  const [pageAdditionType, setPageAdditionType] = useState("single");
  const { stateList, partyList, mappingTable, countryList } = useContext(DataContext);
  const { showAlert } = useContext(AlertContext);
  const { showModal } = useContext(ModalContext);
  const handleLoginClick = async () => {
    const data = await accountService.login(radioPlatform);
    if (data && data.length > 0) {
      setPagesInfo(data);
      return;
    }
  };
  const getSmarListData = async (
    loadType,
    currPage,
    searchGroup = "",
    platformVal
  ) => {

    let platform = "";
    if (platformVal === "Facebook") {
      platform = 1;
    } else if (platformVal === "YouTube") {
      platform = 3;
    } else if (platformVal === "Instagram"){
      platform = 4;
    }
    const { data, error } = await getSmarList(currPage, platform, searchGroup);
    if (error) {
      alert("Couldn't fetch the data");
      return;
    }
    let newData;
    if (loadType === "initial") {
      setPageCount(Math.ceil(data.count / limit));
      newData = [...data.results];
    } else {
      newData = [...smarGroupList, ...data.results];
    }
    setCurrentPage((prevPage) => prevPage + 1);
    setSmarGroupList(newData);
  };
  const getCountryName = (id) => {
    const country = countryList.find(item => item.id === id);
    return country ? country.name : "";
  };
  useEffect(() => {
    if (modalData && !isModal) {
      setIsModal(true);
      let region = [];
      if (modalData.region_type) {
        region = mappingTable.filter((val) => {
          if (val.name.toLowerCase() === modalData.region_type.toLowerCase()) {
            return val;
          }
        });
      }

      setState({
        // id: modalData.uid || "",
        id:
          radioPlatform === "Facebook"
            ? modalData.uid || ""
            : modalData.pk || "",
        page_id:
        radioPlatform === "Instagram"?modalData.insta_handles||'':
          radioPlatform === "Facebook"
            ? modalData.page_id || ""
            : modalData.youtube_id || "",
        page_name:
        radioPlatform === "Instagram"?modalData.insta_name||'':
          radioPlatform === "Facebook"
            ? modalData.page_name || ""
            : modalData.name || "",
        page_category:
        radioPlatform === "Instagram"?modalData.insta_category||'':
          radioPlatform === "Facebook"
            ? modalData.page_category || ""
            : modalData.category || "",
        page_sub_category:
        radioPlatform === "Instagram"?modalData.insta_subcategory||'':
          radioPlatform === "Facebook"
            ? modalData.page_subcategory || ""
            : modalData.subcategory || "",
        party: modalData.political_party || "",
        platform: modalData.platform || "",
        country:{id:modalData.country,name:getCountryName(modalData.country)}||{id:'',name:''},
        region_type:
          region.length > 0
            ? region[0]
            : {
                id: "",
                name: "",
              },
        state:
          modalData.region_type.toLowerCase() === "state"
            ? {
                id: modalData.content_object.pk,
                name: modalData.content_object.name,
              }
            : modalData.content_object?.state,
        district:
          modalData.region_type.toLowerCase() === "district"
            ? {
                id: modalData.content_object.pk,
                name: modalData.content_object.name,
              }
            : "",
        ac:
          modalData.region_type.toLowerCase() === "ac"
            ? {
                id: modalData.content_object.pk,
                name: modalData.content_object.name,
              }
            : "",
        pc:
          modalData.region_type.toLowerCase() === "pc"
            ? {
                id: modalData.content_object.pk,
                name: modalData.content_object.name,
              }
            : "",
        smar_group_set: modalData.smargroup_set_details || "",
        type:modalData?.insta_type || ""
      });
      if (modalData.region_type.toLowerCase() === "pc") {
        getPC(modalData.content_object.state);
      } else if (modalData.region_type.toLowerCase() === "ac") {
        getAC(modalData.content_object.state);
      } else if (modalData.region_type.toLowerCase() === "district") {
        getAC(modalData.content_object.state);
      }
    } else {
      if (state.region_type.name.toLowerCase() === "district"&& state.state!=="") {
        getDistrict(state.state);
      } else if (state.region_type.name.toLowerCase() === "ac" && state.state!=="") {
        getAC(state.state);
      } else if (state.region_type.name.toLowerCase() === "pc" && state.state!=="") {
        getPC(state.state);
      }
    }
    if (smarGroupList.length < 1) {
      setPageCount(0);
      setCurrentPage(1);
      getSmarListData("initial", 1, "", radioPlatform);
    }
  }, [modalData, state.state, radioPlatform]);

  const getDistrict = async (state) => {
    const { result, error } = await getDistrictList(state);
    if (error) {
      alert("Couldn't fetch the data");
      return;
    }
    setDistrictList(result);
  };

  const getAC = async (state) => {
    const { result, error } = await getACList(state);
    if (error) {
      alert("Couldn't fetch the data");
      return;
    }
    setAcList(result);
  };
  const getPC = async (state) => {
    const { result, error } = await getPCList(state);
    if (error) {
      alert("Couldn't fetch the data");
      return;
    }
    setPcList(result);
  };

  const handleChange = (data, name) => {
    if (name === "state") {
      setState((prevState) => ({
        ...prevState,
        [name]: data,
        district: "",
        ac: "",
        pc: "",
      }));
    }
    else if (name === "region") {
      setState((prevState) => ({
        ...prevState,
        [name]: data,
        state: "",
        district: "",
        ac: "",
        pc: "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: data }));
    }
  };

  const handlePlatformRadioChange = (event) => {
    let val = event.target.value;
    setRadioPlatform(val);
    setPageCount(0);
    setCurrentPage(1);
    getSmarListData("initial", 1, "", val);
    setState(initialState);
  };

  const handlePageTypeRadioChange = (event) => {
    let val = event.target.value;
    setRadioPageType(val);
    // setState(initialState);
  };
  const handlePageAdditionType = (event) => {
    let val = event.target.value;
    setPageAdditionType(val);
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePageSelect = (data) => {
    if (data) {
      setSelectedPage(data);
      setState((prevState) => ({
        ...prevState,
        page_id: data.page_id,
        page_name: data.name,
        long_token: data.long_token,
      }));
    } else {
      setSelectedPage({
        name: "",
      });
      setState((prevState) => ({
        ...prevState,
        page_id: "",
        page_name: "",
        long_token: "",
      }));
    }
  };

  const handleSmarListChange = (data) => {
    if (data.length > 0) {
      let ids = data.map((item) => item.pk.toString());
      setSelectedSmarList(ids);
    } else {
      setSelectedSmarList([]);
    }
  };

  const handleChangeRegion = (value, name) => {
    if (value) {
      setState((prevState) => ({
        ...prevState,
        region_type: value,
        state:'',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        region_type: {
          id: "",
          name: "",
        },
      }));
    }
  };

  const handleDelete = (data) => {
    const newData = state.smar_group_set.filter(
      (item) => item.group_name !== data.group_name
    );
    if (newData.length > 0) {
      setState((prevState) => ({ ...prevState, smar_group_set: newData }));
      return;
    }
    setState((prevState) => ({ ...prevState, smar_group_set: "" }));
  };

  //debounced function for delaying the api call
  const debounceFunction = useCallback(
    debounce((...eventData) => {
      getSmarListData(...eventData);
    }, 500),
    []
  );

  const onInputChange = (event, value) => {
    if (value) {
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, value);
    } else {
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, "");
    }
  };

  //for add smar group modal
  const modalHandler = () => {
    const modalProps = {
      type: "add page",
      action: "create",
      title: "Add SMAR Group Details",
    };
    showModal("add-smar-group-details", modalProps, "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let smarGroupData = "";
    //for removing duplicate smargroups on edit
    if (state.smar_group_set) {
      let ids = state.smar_group_set.map((item) => item.id.toString());
      smarGroupData = [...new Set([...selectedSmarList, ...ids])];
    } else {
      if (selectedSmarList) {
        smarGroupData = [...selectedSmarList];
      }
    }

    if (radioPlatform === "Facebook" && pageAdditionType === "single") {
      const { status, message } = await addPages(
        state.id,
        state.page_id,
        state.long_token,
        state.page_name,
        radioPageType,
        state.page_category,
        state.page_sub_category,
        state.party,
        radioPlatform,
        state.region_type,
        state.state,
        state.country,
        state.district,
        state.ac,
        state.pc,
        smarGroupData,
        action
      );
      if (status) {
        showAlert("Data Updated", "success");
      } else if (status === 400) {
        showAlert(message, "error");
        alert("error");
      } else {
        showAlert("Something went wrong", "error");
      }
      setState(initialState);
      if (action === "edit") {
        handleUpdate();
        handleEdit();
      }
      if (action === "create") {
        modalProps.setRefreshList("true");
        handleClose();
      }
    } else if (radioPlatform === "Facebook" && pageAdditionType === "bulk") {
      const { data, error } = await handleBulkPagesUpload(csvFile[0]);
      if (error) {
        showAlert(error.data, "error");
      } else {
        showAlert(`${data}`);
      }
    } else if (radioPlatform === "YouTube") {
      const { status, message } = await addYouTubeHandle(
        state.id,
        state.page_id,
        state.page_name,
        state.page_category,
        state.page_sub_category,
        state.party,
        state.region_type,
        state.state,
        state.district,
        state.ac,
        state.pc,
        state.country,
        smarGroupData,
        action
      );
      if (status) {
        showAlert("Data Updated", "success");
      } else if (status === 400) {
        showAlert(message, "error");
        alert("error");
      } else {
        showAlert("Something went wrong", "error");
      }
      setState(initialState);
      if (action === "edit") {
        handleUpdate();
        handleEdit();
      }
      if (action === "create") {
        modalProps.setRefreshList("true");
        handleClose();
      }
    }else if (radioPlatform === "Instagram") {
      const { status, message } = await addInstagramHandle(
        state.id,
        state.page_id,
        state.page_name,
        state.page_category,
        state.page_sub_category,
        state.party,
        state.region_type,
        state.state,
        state.district,
        state.ac,
        state.pc,
        state.country,
        smarGroupData,
        action
      );
      if (status) {
        showAlert("Data Updated", "success");
      } else if (status === 400) {
        showAlert(message, "error");
        alert("error");
      } else {
        showAlert("Something went wrong", "error");
      }
      setState(initialState);
      if (action === "edit") {
        handleUpdate();
        handleEdit();
      }
      if (action === "create") {
        modalProps.setRefreshList("true");
        handleClose();
      }
    }
  };
  const [loading, setIsLoading] = useState(false);
  const onFileDrop = useCallback((files) => {
    try {
      setIsLoading(true);

      Papa.parse(files[0], {
        header: true,
        skipEmptyLines: true,
        complete: (results, file) => {
          const pageIDList = results.data.map((pageID) => pageID["Page ID"]);

          if (pageIDList.length === 0)
            return showAlert(
              "File does not contain any Page IDs. Please provide the Page IDs and re-upload the CSV file",
              "error"
            );
          if (pageIDList.length > 250)
            return showAlert(
              "File cannot contain more than 250 Page IDs",
              "error"
            );

          const filteredPageIDList = [...new Set(pageIDList)];

          if (filteredPageIDList.length === 0)
            return showAlert(
              "Error while parsing CSV file. Please download the sample file provided on the page and try again",
              "error"
            );

          // const nextDate = new Date(dateRange[1]);
          // nextDate.setDate(nextDate.getDate() + 1);

          // const postApiData = async () => {
          //   const { data, error } = await postOnDemandScraping({
          //     page_ids: filteredPageIDList,
          //     start_date: formatDateForAPIRequest(dateRange[0]),
          //     end_date: formatDateForAPIRequest(nextDate),
          //     platform: 1
          //   })

          //   if (error) return showAlert(error.message, "error")

          //   setReportData(prevState => [...prevState, data])
          //   setCSVFile(null)
          // }

          // postApiData()
        },
        error: (error, file) => {
          console.error(error);
          showAlert(error.message, "error");
        },
      });
    } catch (err) {
      console.error(err);
      showAlert(err.message, "error");
    } finally {
      setIsLoading(false);
    }
  }, []);
  const [csvFile, setCSVFile] = useState(null);

  const { getRootProps, acceptedFiles } = useDropzone({
    maxSize: 2e6,
    maxFiles: 1,
    multiple: false,
    // disabled: !timePeriodBetweenDateRange || timePeriodBetweenDateRange > 7,
    onDropAccepted: (files, event) => setCSVFile(files),
    onDropRejected: (fileRejections, event) => {
      return showAlert(
        `${capitalizeFirstLetter(
          fileRejections[0].errors[0].code.split("-").join(" ")
        )}: ${fileRejections[0].errors[0].message}`,
        "error"
      );
    },
    accept: {
      "text/xlsx": [".xlsx", ".xls"],
    },
  });
  const userAddedFileName = acceptedFiles?.[0]?.name;
  return (
    <form onSubmit={handleSubmit}>
      <ModalContainer
        style={{ padding: action === "edit" ? "1.2rem 0rem" : "1.5rem 2rem" }}
      >
        {action === "create" ? (
          <div className="platform_pageType_contianer">
            <div className="platform">
              <span className="radio_name">Platform</span>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="facebook"
                name="radio-buttons-group"
                className="radio_group"
                onChange={handlePlatformRadioChange}
                value={radioPlatform}
              >
                <FormControlLabel
                  value="Facebook"
                  control={<Radio />}
                  label="Facebook"
                />
                {/* <FormControlLabel
                  value="Instagram"
                  control={<Radio />}
                  label="Instagram"
                /> */}

                <FormControlLabel
                  value="YouTube"
                  control={<Radio />}
                  label="YouTube"
                />
                <FormControlLabel
                  value="Instagram"
                  control={<Radio />}
                  label="Instagram"
                />
              </RadioGroup>
            </div>
            {radioPlatform === "Facebook" && (
              <div className="platform">
                <span className="radio_name">Page Type</span>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="internal"
                  name="radio-buttons-group"
                  className="radio_group"
                  onChange={handlePageTypeRadioChange}
                  value={radioPageType}
                >
                  <FormControlLabel
                    value="Internal"
                    control={<Radio />}
                    label="Internal"
                  />

                  <FormControlLabel
                    value="External"
                    control={<Radio />}
                    label="External"
                  />
                </RadioGroup>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {radioPlatform === "Facebook" &&
          action !== "edit" &&
          radioPageType === "External" && (
            <div className="platform">
              <span className="radio_name">Page Additon Type</span>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="internal"
                name="radio-buttons-group"
                className="radio_group"
                onChange={handlePageAdditionType}
                value={pageAdditionType}
              >
                <FormControlLabel
                  value="single"
                  control={<Radio />}
                  label="Single"
                />

                <FormControlLabel
                  value="bulk"
                  control={<Radio />}
                  label="Bulk"
                />
              </RadioGroup>
            </div>
          )}
        {radioPlatform === "Facebook" &&
          action !== "edit" &&
          radioPageType !== "External" && (
            <div className="auth_container">
              {!isAuth ? (
                <span className="auth_text" style={{ fontSize: "14px" }}>
                  Login with Facebook
                </span>
              ) : (
                <span
                  className="auth_text"
                  style={{ fontSize: "14px", color: "#3ebfab" }}
                >
                  Logged In
                </span>
              )}
              <br />
              <CustomButton
                sx={{
                  color: "black",
                  borderRadius: "15px",
                  textTransform: "none",
                  border: "1px solid #3ebfab",
                }}
                onClick={() => handleLoginClick()}
              >
                <FacebookIcon sx={{ color: "#355391", marginRight: "5px" }} />{" "}
                Facebook
              </CustomButton>
            </div>
          )}
        {radioPlatform === "YouTube" || radioPlatform === "Instagram" ||
        action === "edit" ||
        (radioPageType === "External" && pageAdditionType === "single") ? (
          <div className="page_option_container">
            <span className="option_name">
              {radioPlatform === "Instagram"?'Account Name':radioPlatform === "YouTube" ? "Channel Name" : "Page Name"}
            </span>
            <div className="option_input">
              <CustomTextField
                value={state.page_name}
                name="page_name"
                label={
                  radioPlatform === "Instagram"?"Enter new account name":
                  radioPlatform === "YouTube"
                    ? "Enter new channel name"
                    : "Enter new page name"
                }
                variant="outlined"
                onChange={handleTextChange}
                sx={{ width: "80%" }}
                required
              />
            </div>
          </div>
        ) : (
          pagesInfo.length >= 1 && (
            <div className="page_option_container">
              <span className="option_name">Page Name</span>
              <div className="option_input">
                <FormControl sx={{ flex: "0.8" }} size="small">
                  <AutoCompletePageSelect
                    size="small"
                    variant="outlined"
                    value={selectedPage}
                    options={pagesInfo}
                    autoComplete="true"
                    label="Select Category"
                    onChange={handlePageSelect}
                    required
                  />
                </FormControl>
              </div>
            </div>
          )
        )}
        {(radioPageType === "External" &&
          radioPlatform === "Facebook" &&
          pageAdditionType === "single") ||
        radioPlatform === "YouTube"||radioPlatform === "Instagram" ? (
          <div className="page_option_container">
            <span className="option_name">
              {radioPlatform === "Instagram"?"Account Username":radioPlatform === "YouTube" ? "Channel ID" : "Page ID"}
            </span>
            <div className="option_input">
              <CustomTextField
                value={state.page_id}
                name="page_id"
                label={
                  radioPlatform === "Instagram"?"Enter Account Username":
                  radioPlatform === "YouTube"
                    ? "Enter Channel ID"
                    : "Enter Page ID"
                }
                variant="outlined"
                onChange={handleTextChange}
                sx={{ width: "80%" }}
                required={(radioPlatform === "YouTube"||radioPlatform === "Instagram") ? true : false}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
{(radioPlatform === "Instagram" || (action === "edit" && radioPlatform === "Instagram"))&&(
          <div className="page_option_container">
          <span className="option_name">Select Account Type</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
             <AutoCompleteSelect
								size='small'
								variant='outlined'
								text='reportcategory'
								value={state.type}
								options={instagramAccountType}
								autoComplete='true'
								label='Select Type'
								onChange={handleChange}
							/>
            </FormControl>
          </div>
        </div>
        )}
         {action === "edit"&& radioPlatform!=="YouTube" && (
            <div className="page_option_container">
                <span className="option_name">Select Country </span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompleteState
                      size="small"
                      variant="outlined"
                      value={state.country}
                      options={countryList}
                      autoComplete="true"
                      label="Select Country"
                      onChange={handleChange}
                      text="country"
                      required
                    />
                  </FormControl>
                </div>
              </div>
        )}{
         (radioPlatform === "Instagram" && action==="create")||(radioPageType === "External" &&
          radioPlatform === "Facebook" &&
          pageAdditionType === "single" && action==="create") ?
          (
            <div className="page_option_container">
                <span className="option_name">Select Country</span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompleteState
                      size="small"
                      variant="outlined"
                      value={state.country}
                      options={countryList}
                      autoComplete="true"
                      label="Select Country"
                      onChange={handleChange}
                      text="country"
                      required
                    />
                  </FormControl>
                </div>
              </div>
        ):(
          <></>
        ) }
        {pagesInfo.length >= 1 ||
        action === "edit" ||
        radioPlatform === "YouTube" ||radioPlatform === "Instagram"||
        (radioPageType === "External" && pageAdditionType === "single") ? (
          <>
            <div className="page_option_container">
              <span className="option_name">
                {radioPlatform === "Instagram"?'Account Category':radioPlatform === "YouTube"
                  ? "Channel Category"
                  : "Page Category"}
              </span>
              <div className="option_input">
                <FormControl
                  sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                  size="small"
                >
                  <AutoCompleteSelect
                    size="small"
                    variant="outlined"
                    text="page_category"
                    value={state.page_category}
                    options={pageCategory}
                    autoComplete="true"
                    label="Select Category"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </div>
            </div>
            <div className="page_option_container">
              <span className="option_name">
                {radioPlatform === "Instagram"?'Account Sub Category':radioPlatform === "YouTube"
                  ? "Channel Sub Category"
                  : "Page Sub Category"}
              </span>
              <div className="option_input">
                <FormControl
                  sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                  size="small"
                >
                  <AutoCompleteSelect
                    size="small"
                    variant="outlined"
                    text="page_sub_category"
                    value={state.page_sub_category}
                    options={pageSubCategory}
                    autoComplete="true"
                    label="Select sub-category"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </div>
            </div>
            <div className="page_option_container">
              <span className="option_name">Political Party</span>
              <div className="option_input">
                <FormControl
                  sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                  size="small"
                >
                  <AutoCompletePartySelect
                    size="small"
                    variant="outlined"
                    value={state.party}
                    options={partyList}
                    autoComplete="true"
                    label="Select Political Party"
                    text="party"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </div>
            </div>
            <div className="page_option_container">
              <span className="option_name">Region</span>
              <div className="option_input">
                <FormControl
                  sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                  size="small"
                >
                  <AutoCompletePlatform
                    size="small"
                    variant="outlined"
                    value={state.region_type}
                    options={mappingTable}
                    autoComplete="true"
                    label="Select Region"
                    text="region_type"
                    onChange={handleChangeRegion}
                    required
                  />
                </FormControl>
              </div>
            </div>{" "}
            {state?.region_type.name !== "" &&
            state?.region_type?.name !== "Nation" &&
            state?.region_type?.name !== "National" ? (
              <div className="page_option_container">
                <span className="option_name">Select State</span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompleteState
                      size="small"
                      variant="outlined"
                      value={state.state}
                      options={stateList}
                      autoComplete="true"
                      label="Select State"
                      onChange={handleChange}
                      text="state"
                      required={
                        state.region_type.name === "State" ? true : false
                      }
                    />
                  </FormControl>
                </div>
              </div>
            ) : (
              <></>
            )}
            {state.region !== "" &&
            state.region_type?.name.toLowerCase() === "district" ? (
              <div className="page_option_container">
                <span className="option_name">Select District</span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompleteDistrict
                      size="small"
                      variant="outlined"
                      value={state.district}
                      options={districtList}
                      autoComplete="true"
                      label="Select District"
                      text="district"
                      onChange={handleChange}
                      required={
                        state.region_type.name === "District" ? true : false
                      }
                    />
                  </FormControl>
                </div>
              </div>
            ) : (
              <></>
            )}
            {state.region !== "" &&
            state.region_type?.name.toLowerCase() === "ac" ? (
              <div className="page_option_container">
                <span className="option_name">Select AC</span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompletePcSelect
                      size="small"
                      variant="outlined"
                      value={state.ac}
                      options={acList}
                      autoComplete="true"
                      label="Select AC"
                      text="ac"
                      onChange={handleChange}
                      required={state.region_type.name === "AC" ? true : false}
                    />
                  </FormControl>
                </div>
              </div>
            ) : (
              <></>
            )}
            {state.region !== "" &&
            state.region_type.name.toLowerCase() === "pc" ? (
              <div className="page_option_container">
                <span className="option_name">Select PC</span>
                <div className="option_input">
                  <FormControl
                    sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                    size="small"
                  >
                    <AutoCompletePcSelect
                      size="small"
                      variant="outlined"
                      value={state.pc}
                      options={pcList}
                      autoComplete="true"
                      label="Select PC"
                      text="pc"
                      onChange={handleChange}
                      required={state.region_type.name === "PC" ? true : false}
                    />
                  </FormControl>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        {action === "edit" &&
          state.smar_group_set &&
          state.smar_group_set.map((item, index) => {
            return (
              <div className="page_option_container" key={index}>
                <span className="option_name">
                  {index === 0 ? "SMAR Groups" : ""}
                </span>
                <div className="option_input_smar_group">
                  <CustomTextField
                    value={item.group_name}
                    name="page_name"
                    label="Enter new page name"
                    variant="outlined"
                    onChange={handleTextChange}
                    sx={{ width: "86%" }}
                    disabled
                  />
                </div>
                <StyledIconButton
                  disableRipple={true}
                  onClick={() => handleDelete(item)}
                >
                  <DeleteOutlineOutlinedIcon className="delIcon" />
                </StyledIconButton>
              </div>
            );
          })}

        {pagesInfo.length >= 1 ||
        radioPlatform === "YouTube" ||radioPlatform === "Instagram"||
        action === "edit" ||
        (radioPageType === "External" && pageAdditionType === "single") ? (
          <div className="page_option_container">
            <span className="option_name">
              {action !== "edit" ? "SMAR Groups" : "Add more SMAR Groups"}
            </span>
            <div className="smar_input">
              <FormControl sx={{ width: "80%" }} size="small">
                <AutoCompleteSmarList
                  size="small"
                  variant="outlined"
                  limitTags={1}
                  // value={selectedState}
                  options={smarGroupList}
                  autoComplete="true"
                  label="Select SMAR Group Name"
                  onChange={handleSmarListChange}
                  onInputChange={onInputChange}
                  getData={getSmarListData}
                  currentPage={currentPage}
                  pageCount={pageCount}
                />
              </FormControl>
              <CustomButton
                className="add_button_style"
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={modalHandler}
              >
                <Typography className="button_text">Add</Typography>
              </CustomButton>
            </div>
          </div>
        ) : (
          ""
        )}
        {radioPageType === "External" &&
          radioPlatform === "Facebook" &&
          pageAdditionType === "bulk" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent:'center',
                gap: "1rem",
                height: "300px",
                marginLeft:'6.5rem'
              }}
              className="bulk-upload-main"
            >
              <Box {...getRootProps()} className={"upload-container"}>
                {csvFile ? (
                  <>
                    <Box className={"file-added-section"}>
                      {userAddedFileName && (
                        <>
                          <Typography className={"added-file-text"}>
                            File Uploaded
                          </Typography>

                          <Typography className={"file-name-text"}>
                            {userAddedFileName}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box className={"uploaded-file-buttons-container"}>
                      <CustomButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setCSVFile(null);
                        }}
                      >
                        Reset
                      </CustomButton>
                    </Box>
                  </>
                ) : (
                  <>
                    <DriveFolderUploadIcon
                      color={"primary"}
                      sx={{ fontSize: "72px" }}
                    />

                    <Box className={"text-section"}>
                      <Box className={"top-text"}>
                        <Typography className={"upload-text"}>
                          Click to Upload
                        </Typography>

                        <Typography className={"drag-drop-text"}>
                          or drag and drop
                        </Typography>
                      </Box>

                      <Typography className={"extension-text"}>
                        Excel extension file only (max size 2mb)
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
              <a
                download="bulk_page-addition_Sample.xlsx"
                target="_blank"
                rel="noreferrer"
                href={SampleBulkPageAdditionSheet}
              >
                <CustomButton variant="outlined" className={"sample-csv-btn"} size={"small"}>
                  Download Sample Sheet
                </CustomButton>
              </a>
            </Box>
          )}
        {action === "edit" ? (
          <div className="page_option_container">
            <div className="option_name"></div>
            <div className="option_input">
              <CustomButton
                variant="contained"
                className="button_style"
                type="submit"
              >
                Save
              </CustomButton>
              <CustomButton
                variant="outlined"
                className="cancel_style"
                onClick={handleEdit}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        ) : (
          (pagesInfo.length >= 1 ||
            radioPlatform === "YouTube" ||radioPlatform === "Instagram"||
            radioPageType === "External") && (
            <div className="page_option_container">
              <div className="option_name"></div>
              <div className="option_input">
                <CustomButton
                  variant="contained"
                  className="button_style"
                  // style={{ width: "15%" }}
                  type="submit"
                >
                  {radioPlatform === "YouTube"?'Add Channel':radioPlatform === "Instagram"?"Add Account":"Add Page"}
                </CustomButton>
              </div>
            </div>
          )
        )}
      </ModalContainer>
    </form>

  );
};

export default PageAddInternal;
