export const sectionAcess = (userGroup, userLevel, link,email) => {
  if (userGroup.toLowerCase() === "general" && (link.primary === "Pages") && (userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
  || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && (link.primary === "SMAR Group") && (userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
  || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && (link.primary === "Reports") && (userLevel === "L0" || userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
  || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L13" || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18") && (link.primary === "Media Monitoring")) {
    return false;
  } else if (userGroup.toLowerCase() === "general" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L13" || userLevel === "L14") && (link.primary !== "Media Monitoring")) {
    return true;
  }
  if (userGroup.toLowerCase() === "general" && (userLevel === "L5" || userLevel === "L6" || userLevel === "L7" || userLevel === "L8") && (link.primary === "Media Monitoring" || link.primary === "Content Tagging")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && (link.primary !== "Content Tagging")) {
    return true;
  }
  if (userGroup.toLowerCase() === "general" && (link.primary === "Content Tagging") && userLevel === "L9") {
    return true;
  }
  if ((email==="priyansh@admin.com"||email==="animesh.kumar@tailnode.com"||email==="neeraj.sehrawat@tailnode.com"||email==="sachin.yadav@tailnode.com"||email==="prashant.jhalani@inverv.com"||email==="pradeep.singh@inverv.com"||email==="akshay.soni@inverv.com"||email==="gaurav.binjola@tailnode.com")&& (link.primary === "Smar Dashboard")) {
    return true;
  }
  return false;
};

export const secondaryAcess = (userGroup, userLevel, link, priLink) => {
  if(userGroup.toLowerCase() !== "general") {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Facebook") {
    if (userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
    || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18") {
      return false;
    }
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Generate Report" && priLink.key === "reports") {
    if (userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
    || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18") {
      return false;
    }
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Generated Reports" && priLink.key === "reports") {
    if (userLevel === "L9" || userLevel === "L10" || userLevel === "L11" || userLevel === "L12" || userLevel === "L13" 
    || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18") {
      return false;
    }
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Dashboard" && priLink.key === "reports") {
    if (userLevel === "L0") {
      return false;
    }
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Dashboard" && priLink.key === "content-tagging") {
    if (userLevel === "L0" || userLevel === "L7" || userLevel === "L8" || userLevel === "L10" || userLevel === "L17" || userLevel === "L18") {
      return false;
    }
    return true;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Dashboard" && priLink.key === "media-monitoring") {
    if (userLevel === "L3" || userLevel === "L7" || userLevel === "L13" || userLevel === "L17") {
      return false;
    }
    return true;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Generate Report" && userLevel === "L0") {
    return true;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Generated Reports" && userLevel === "L0") {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Dashboard" && priLink.key === "media-monitoring" && (userLevel === "L4" || userLevel === "L5" || userLevel === "L6" || userLevel === "L8")) {
    return true;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Electronic Media" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L5" || userLevel === "L6" || userLevel === "L7" || userLevel === "L8" || userLevel === "L13" || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Content Logs" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L5" || userLevel === "L6" || userLevel === "L7" || userLevel === "L8" || userLevel === "L13" || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Print and Digital Media" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L5" || userLevel === "L6" || userLevel === "L7" || userLevel === "L8" || userLevel === "L13" || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Campaign Ads" && (userLevel === "L3" || userLevel === "L4" || userLevel === "L5" || userLevel === "L6" || userLevel === "L7" || userLevel === "L8" || userLevel === "L13" || userLevel === "L14" || userLevel === "L15" || userLevel === "L16" || userLevel === "L17" || userLevel === "L18")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Overview" && (userLevel !== "L1" && userLevel !== "L5" && userLevel !== "L9" && userLevel !== "L11" && userLevel !== "L15")) {
    return false;
  }
  if (userGroup.toLowerCase() === "general" && link.text === "Tagging" && (userLevel !== "L2" && userLevel !== "L6" && userLevel !== "L9" && userLevel !== "L12" && userLevel !== "L16")) {
    return false;
  }

  return true;
};

export const pathAccess = (userGroup, userLevel, link, secLink,email) => {
  if (link === "content-tagging") {
    if (userGroup.toLowerCase() === "general") {
      if (userLevel === "L0" || userLevel === "L2") {
        return "/app/content-tagging/overview"
      } else if (userLevel === "L1" || userLevel === "L11" || userLevel === "L15") {
        return "/app/content-tagging/tagging"
      }
    }
  }
  if (link === "media-monitoring") {
    if (userGroup.toLowerCase() === "general") {
      if (userLevel === "L3" || userLevel === "L4") {
        return "/app/media-monitoring/electronic-media"
      }
    }
  }
  if (link === "smar-dashboard"&&(email==="priyansh@admin.com"||email==="animesh.kumar@tailnode.com"||email==="neeraj.sehrawat@tailnode.com"||email==="sachin.yadav@tailnode.com"||email==="prashant.jhalani@inverv.com"||email==="pradeep.singh@inverv.com"||email==="akshay.soni@inverv.com"||email==="gaurav.binjola@tailnode.com")) {
    if (userGroup.toLowerCase()==="super admin") {
        return "/app/smar-dashboard"
    }
  }

  return `/app/${link}/${secLink}`;
}