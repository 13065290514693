import { ResponsivePie } from '@nivo/pie';

// MUI
import styled from "@emotion/styled";
import Typography from '@mui/material/Typography';

const OverallContainer = styled("div")(({ theme ,type}) => ({
  width: type === "smarDashboard" ? '100%' : 'auto',
  height:  "85%",
  [theme.breakpoints.down("xl")]: {
    height: "79%",
  },
  [theme.breakpoints.down("xl")]: {
    height: "75%",
  },
  "& .legend": {
    padding: "0 1rem",
    height: "15%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "0.5rem",
    columnGap: "1rem",
    [theme.breakpoints.down("xl")]: {
      rowGap: "0",
      columnGap: "0.8rem",
      height: "22%",
    },
    [theme.breakpoints.down("lg")]: {
      rowGap: "0.5rem",
      columnGap: "0.7rem",
      height: "22%",
    },
    "& .each_legend_style": {
      display: "flex",
      justifyContent: "center",
      gap: "0.3rem",
      alignItems: "center",
      "& .text_style": {
        fontSize: "15px",
        fontWeight: "500",
        [theme.breakpoints.down("xl")]: {
          fontSize: "13px",
        },
      }
    }
  }
}))

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyResponsivePie = ({ data, colors ,type}) => {

  const chartTheme = {
    // Customize font size and other styles for the pie chart
    labels: {
      // Adjust the font size for the values inside the pie slices
      text: {
        fontSize: 12,
        fontWeight: "600",
      },
    },
  };

  const formatValueAsPercentage = value => type!=="smarDashboard"?`${value}%`:`${value}`;
  const pieMargins = type === "smarDashboard"
  ? { top: 20, right: 20, bottom: 20, left: 20 }
  : { top: 40, right: 80, bottom: 80, left: 80 };
  return (
    <OverallContainer type={type}>
      <ResponsivePie
        data={data}
        margin={pieMargins}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        theme={chartTheme}
        valueFormat={formatValueAsPercentage}
        borderWidth={1.7}
        colors={colors}
        colorBy="index"
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0.2
            ]
          ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsDiagonalLength={7}
        arcLinkLabelsStraightLength={8}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              2
            ]
          ]
        }}
      // legends={[
      //   {
      //     anchor: 'bottom',
      //     direction: 'row',
      //     justify: false,
      //     translateX: 0,
      //     translateY: 56,
      //     itemsSpacing: 0,
      //     itemWidth: 100,
      //     itemHeight: 18,
      //     itemTextColor: '#999',
      //     itemDirection: 'left-to-right',
      //     itemOpacity: 1,
      //     symbolSize: 18,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000'
      //         }
      //       }
      //     ]
      //   }
      // ]}
      />
      <div className="legend">
        {data && data.length && data.map((val, index) => {
          return (
            <div
              key={val.id}
              className="each_legend_style"
            >
              <div
                style={{
                  backgroundColor: colors[index],
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                }}
              >
              </div>
              <Typography className='text_style'>{val.label}</Typography>
            </div>
          )
        })}
      </div>
    </OverallContainer>
  );
};

export default MyResponsivePie;