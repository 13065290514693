import React, { useState, useEffect, useContext, useCallback } from "react";
import { debounce } from "lodash";

// MUI
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

//components
import AutoCompleteSelect from "components/Common/AutoCompleteSelect";
import CustomTextField from "components/Common/CommonTextField";
import CustomButton from "components/Common/CustomButton";
import AutoCompletePlatform from "components/Common/AutoCompletePlatform";
import AutoCompleteMultiPageSelect from "components/Common/AutoCompleteMultiPageSelect";
import AutoCompleteMultiChannelSelect from "components/Common/AutoCompleteMultiChannelSelect";
import AutoCompleteState from "components/Common/AutoCompleteState";

//data
import {
  countryMapping,
  pageCategory,
  pageSubCategory,
  platform,
} from "utils/data";

//context
import { AlertContext } from "contexts/AlertContext";
import { DataContext } from "contexts/DataContext";
import { AuthContext } from "contexts/AuthContext";

//apis
import { addSmarGroup } from "apis/componentsAction/smar";
import {
  getInstaAccountsList,
  getPagesListAll,
  getTwitterHandleList,
  getYouTubeChannelList,
} from "apis/componentsAction/pages";
import AutoCompleteMultiAccountSelect from "components/Common/AutoCompleteAccountSelect";

const ModalContainer = styled("div")(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: "14px",
  padding: "1.5rem 2rem",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: `${theme.palette.secondary.main}`,
  },
  "& .option_container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "1.3rem",
  },
  "& .option_name": {
    width: "25%",
    fontSize: "16px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .option_input": {
    width: "75%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .option_input_smar_group": {
    width: "70%",
    display: "flex",
    gap: "0.5rem",
  },
  "& .button_style": {
    borderRadius: "20px",
    color: "#FFFFFF",
    background: theme.palette.primary.main,
    // width: "20%",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .cancel_style": {
    borderRadius: "20px",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontWeight: "500",
    width: "20%",
    fontSize: "16px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    color: "#4F4F4F",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "1.4rem",
    color: "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
}));

const initialState = {
  id: "",
  group_name: "",
  smargroup_category: "",
  smargroup_sub_category: "",
  platform: {
    id: "",
    name: "",
  },
  pages: [],
  state: {
    id: "",
    name: "",
  },
  country: {
    id: "",
    name: "",
  },
  region_type: {
    id: "",
    name: "",
  },
};

const SMARGroupDetails = ({
  handleClose,
  modalData,
  action,
  modalProps,
  handleEdit,
  handleUpdate,
}) => {
  const [state, setState] = useState(initialState);
  const [pageList, setPageList] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchedPage, setSearchedPage] = useState("");
  const [limit] = useState(40);
  const { showAlert } = useContext(AlertContext);
  const { stateList, countryList, mappingTable } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const getCountryName = (id) => {
    const country = countryList.find((item) => item.id === id);
    return country ? country.name : "";
  };
  const filteredMappingTable = mappingTable.filter(item => item.name === "State" || item.name === "Nation");

  const getPagesData = async (
    loadType,
    currPage,
    searchPage = "",
    platformVal
  ) => {
    if (platformVal.toLocaleLowerCase() === "facebook") {
      const { data, error } = await getPagesListAll(
        1,
        currPage,
        "",
        searchPage,
        "",
        "",
        "",
        "name"
      );
      if (error) {
        if (error.status === 401) {
          console.log("error");
        } else if (error.status === 404) {
          alert("No reports available for your state");
          return;
        } else {
          alert("Couldn't fetch the data");
          return;
        }
      }
      let newData;
      if (loadType === "initial") {
        setPageCount(Math.ceil(data.count / limit));
        newData = [...data.results];
      } else {
        newData = [...pageList, ...data.results];
      }
      setCurrentPage((prevPage) => prevPage + 1);
      setPageList(newData);
    } else if (platformVal.toLocaleLowerCase() === "instagram") {
      const { data, error } = await getInstaAccountsList(
        3,
        currPage,
        "Business",
        searchPage,
        "",
        "",
        ""
      );
      if (error) {
        if (error.status === 401) {
          console.log("error");
        } else if (error.status === 404) {
          alert("No reports available for your state");
          return;
        } else {
          alert("Couldn't fetch the data");
          return;
        }
      }
      let newData;
      if (loadType === "initial") {
        setPageCount(Math.ceil(data.count / limit));
        newData = [...data.results];
      } else {
        newData = [...pageList, ...data.results];
      }
      setCurrentPage((prevPage) => prevPage + 1);
      setPageList(newData);
    } else {
      const { data, error } = await getYouTubeChannelList(
        3,
        currPage,
        "",
        searchPage,
        "",
        "",
        ""
      );
      if (error) {
        if (error.status === 401) {
          console.log("error");
        } else if (error.status === 404) {
          alert("No reports available for your state");
          return;
        } else {
          alert("Couldn't fetch the data");
          return;
        }
      }
      let newData;
      if (loadType === "initial") {
        setPageCount(Math.ceil(data.count / limit));
        newData = [...data.results];
      } else {
        newData = [...pageList, ...data.results];
      }
      setCurrentPage((prevPage) => prevPage + 1);
      setPageList(newData);
    }
  };

  useEffect(() => {
    if (modalData && !isModal) {
      setIsModal(true);
      let region = [];
      if (modalData.region_type) {
        region = mappingTable.filter((val) => {
          if (val.name.toLowerCase() === modalData.region_type.toLowerCase()) {
            return val;
          }
        });
      }
      setState({
        id: modalData.pk || "",
        group_name: modalData.group_name || "",
        smargroup_category: modalData.smargroup_category || "",
        smargroup_sub_category: modalData.smargroup_sub_category || "",
        platform: modalData.platform || {
          id: "",
          name: "",
        },
        pages:
          modalData.platform?.name.toLowerCase() === "twitter"
            ? modalData.handles || []
            : modalData.platform?.name.toLowerCase() === "youtube"
            ? modalData.ytchannels || []
            : modalData.platform?.name.toLowerCase() === "instagram"
            ? modalData.insta_handles || []
            : modalData.pages || [],
            state:
            modalData.region_type.toLowerCase() === "state"
              ? {
                  id: modalData.content_object.pk,
                  name: modalData.content_object.name,
                }
              : modalData.content_object?.state,        country: modalData.country
          ? { id: modalData.country, name: getCountryName(modalData.country) }
          : { id: "", name: "" },
          region_type:
          region.length > 0
            ? region[0]
            : {
                id: "",
                name: "",
              },
      });
    }
    if (state.platform.id) {
      setPageCount(0);
      setCurrentPage(1);
      getPagesData("initial", 1, searchedPage, state.platform.name);
    }
  }, [modalData, state.platform]);

  const handlePageListChange = (data, text) => {
    if (data.length > 0) {
      let ids = [];
      if (text === "pages") {
        ids = data.map((item) => item.page_id.toString());
      } else if (text === "channel") {
        ids = data.map((item) => item.pk.toString());
      }
      setSelectedPages(ids);
    } else {
      setSelectedPages([]);
    }
  };

  const handleChange = (data, name) => {
    if (name === "region") {
      setState((prevState) => ({
        ...prevState,
        [name]: data,
        state: "",
        district: "",
        ac: "",
        pc: "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: data }));
    }
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangePlatform = (data, name) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        platform: data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        platform: {
          id: "",
          name: "",
        },
      }));
    }
  };

  const handleStateChange = (data) => {
    if (data) {
      setState((prevState) => ({
        ...prevState,
        state: data,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        state: {
          id: "",
          name: "",
        },
      }));
    }
  };

  const handleDelete = (data) => {
    if (
      state.platform?.name.toLocaleLowerCase() === "youtube" ||
      state.platform?.name.toLocaleLowerCase() === "instagram"
    ) {
      const newData = state.pages.filter((item) => item.pk !== data.pk);
      if (newData.length > 0) {
        setState((prevState) => ({ ...prevState, pages: newData }));
        return;
      }
      setState((prevState) => ({ ...prevState, pages: "" }));
    } else if (state.platform?.name.toLocaleLowerCase() === "facebook") {
      const newData = state.pages.filter(
        (item) => item.page_id !== data.page_id
      );
      if (newData.length > 0) {
        setState((prevState) => ({ ...prevState, pages: newData }));
        return;
      }
      setState((prevState) => ({ ...prevState, pages: "" }));
    }
  };

  //debounced function for delaying the api call
  const debounceFunction = useCallback(
    debounce((...eventData) => {
      getPagesData(...eventData);
    }, 500),
    []
  );

  const onInputChange = (event, value) => {
    if (value) {
      setSearchedPage(value);
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, value, state.platform.name);
    } else {
      setSearchedPage("");
      setPageCount(0);
      setCurrentPage(1);
      debounceFunction("initial", 1, "", state.platform.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let pagesData = "";
    //for removing duplicate smargroups on edit
    if (state.platform?.name.toLocaleLowerCase() === "facebook") {
      if (state.pages.length > 0) {
        let ids = state.pages.map((item) => item.page_id.toString());
        pagesData = [...new Set([...selectedPages, ...ids])];
      } else {
        if (selectedPages) {
          pagesData = [...selectedPages];
        }
      }
    } else if (
      state.platform?.name.toLocaleLowerCase() === "youtube" ||
      state.platform?.name.toLocaleLowerCase() === "instagram"
    ) {
      if (state.pages.length > 0) {
        let ids = state.pages.map((item) => item.pk.toString());
        pagesData = [...new Set([...selectedPages, ...ids])];
      } else {
        if (selectedPages) {
          pagesData = [...selectedPages];
        }
      }
    }
    const { status, message } = await addSmarGroup(
      state.id,
      state.group_name,
      state.smargroup_category,
      state.smargroup_sub_category,
      state.platform,
      state.state,
      state.country,
      pagesData,
      action,
      state.region_type
    );
    if (status === 201 || status === 200) {
      showAlert(message, "success");
      alert("success");
    } else if (status >= 400) {
      showAlert("Something went wrong", "error");
      alert("error");
    } else {
      showAlert("Success", "success");
    }
    setState(initialState);
    if (action === "edit") {
      handleUpdate();
      handleEdit();
    }
    if (action === "create") {
      modalProps.setRefreshList("true");
      handleClose();
    }
  };
  const handleChangeRegion = (value, name) => {
    if (value) {
      setState((prevState) => ({
        ...prevState,
        region_type: value,
        state: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        region_type: {
          id: "",
          name: "",
        },
      }));
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <ModalContainer
        style={{ padding: action === "edit" ? "1.2rem 0rem" : "1.5rem 2rem" }}
      >
        <div className="option_container">
          <span className="option_name">SMAR Group Name</span>
          <div className="option_input">
            <CustomTextField
              value={state.group_name}
              name="group_name"
              label="Enter New SMAR Group Name"
              variant="outlined"
              onChange={handleTextChange}
              sx={{ width: "80%" }}
              required
            />
          </div>
        </div>{" "}
        <div className="option_container">
          <span className="option_name">Platform</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompletePlatform
                size="small"
                variant="outlined"
                text="Platform"
                value={state.platform}
                options={platform}
                autoComplete="true"
                label="Select Platform"
                onChange={handleChangePlatform}
                required
              />
            </FormControl>
          </div>
        </div>
       
        <div className="option_container">
          <span className="option_name">Select Country</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompleteState
                size="small"
                variant="outlined"
                value={state.country}
                options={countryList}
                autoComplete="true"
                label="Select Country"
                onChange={handleChange}
                text="country"
                required
              />
            </FormControl>
          </div>
        </div>
        <div className="option_container">
          <span className="option_name">Region</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompletePlatform
                size="small"
                variant="outlined"
                value={state.region_type}
                options={filteredMappingTable}
                autoComplete="true"
                label="Select Region"
                text="region_type"
                onChange={handleChangeRegion}
                required
              />
            </FormControl>
          </div>
        </div>
        {state?.region_type.name !== "" &&
            state?.region_type?.name !== "Nation" &&
            state?.region_type?.name !== "National"&& (
          <div className="option_container">
            <span className="option_name">State</span>
            <div className="option_input">
              <FormControl
                sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
                size="small"
              >
                <AutoCompleteState
                  size="small"
                  variant="outlined"
                  text="state"
                  value={state.state}
                  options={
                    user?.user_type === "General"
                      ? user?.states_data.length
                        ? user?.states_data
                        : []
                      : stateList
                  }
                  autoComplete="true"
                  label="Select State"
                  onChange={handleStateChange}
                  required
                />
              </FormControl>
            </div>
          </div>
        )}
        <div className="option_container">
          <span className="option_name">SMAR Group Category</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompleteSelect
                size="small"
                variant="outlined"
                text="smargroup_category"
                value={state.smargroup_category}
                options={pageCategory}
                autoComplete="true"
                label="Select Category"
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
        <div className="option_container">
          <span className="option_name">SMAR Group Sub Category</span>
          <div className="option_input">
            <FormControl
              sx={{ flex: action === "edit" ? "0.8" : "0.4" }}
              size="small"
            >
              <AutoCompleteSelect
                size="small"
                variant="outlined"
                text="smargroup_sub_category"
                value={state.smargroup_sub_category}
                options={pageSubCategory}
                autoComplete="true"
                label="Select Sub Category"
                onChange={handleChange}
              />
            </FormControl>
          </div>
        </div>
        {action === "edit" &&
          state.pages &&
          state.pages.map((item, index) => {
            return (
              <div className="option_container">
                <span className="option_name">
                  {index === 0
                    ? state.platform?.name.toLocaleLowerCase() === "youtube"
                      ? "Channels"
                      : state.platform?.name.toLocaleLowerCase() === "instagram"
                      ? "Accounts"
                      : state.platform?.name.toLocaleLowerCase() === "twitter"
                      ? "Handles"
                      : "Pages"
                    : ""}
                </span>
                <div className="option_input_smar_group">
                  <CustomTextField
                    value={
                      state.platform?.name.toLocaleLowerCase() === "twitter"
                        ? item.handle_id
                        : state.platform?.name.toLocaleLowerCase() ===
                          "instagram"
                        ? item.insta_name
                        : state.platform?.name.toLocaleLowerCase() === "youtube"
                        ? item.name
                        : item.page_name
                    }
                    name="page_name"
                    label={`Enter new ${
                      state.platform?.name.toLocaleLowerCase() === "youtube"
                        ? "Channel"
                        : state.platform?.name.toLocaleLowerCase() ===
                          "instagram"
                        ? "Account"
                        : state.platform?.name.toLocaleLowerCase() === "twiiter"
                        ? "Handle"
                        : "Page"
                    } name`}
                    variant="outlined"
                    onChange={handleTextChange}
                    sx={{ width: "86%" }}
                    disabled
                  />
                </div>
                <StyledIconButton
                  disableRipple={true}
                  onClick={() => handleDelete(item)}
                >
                  <DeleteOutlineOutlinedIcon className="delIcon" />
                </StyledIconButton>
              </div>
            );
          })}
        {state.platform.id ? (
          <div className="option_container">
            {state.platform?.name.toLocaleLowerCase() === "youtube" ? (
              <span className="option_name">
                {action !== "edit" ? "Channels" : "Add more Channels"}
              </span>
            ) : state.platform?.name.toLocaleLowerCase() === "instagram" ? (
              <span className="option_name">
                {action !== "edit" ? "Accounts" : "Add more Accounts"}
              </span>
            ) : state.platform?.name.toLocaleLowerCase() === "twitter" ? (
              <span className="option_name">
                {action !== "edit" ? "Handles" : "Add more Handles"}
              </span>
            ) : (
              <span className="option_name">
                {action !== "edit" ? "Pages" : "Add more Pages"}
              </span>
            )}
            <div className="option_input">
              <FormControl sx={{ width: "80%" }} size="small">
                {state.platform.name.toLowerCase() === "facebook" ? (
                  <AutoCompleteMultiPageSelect
                    size="small"
                    variant="outlined"
                    text="pages"
                    limitTags={1}
                    onInputChange={onInputChange}
                    value={searchedPage}
                    options={pageList}
                    autoComplete="true"
                    label="Select Page(s)"
                    onChange={handlePageListChange}
                    getData={getPagesData}
                    currentPage={currentPage}
                    pageCount={pageCount}
                  />
                ) : state.platform.name.toLowerCase() === "instagram" ? (
                  <AutoCompleteMultiAccountSelect
                    size="small"
                    variant="outlined"
                    text="channel"
                    limitTags={1}
                    onInputChange={onInputChange}
                    // value={state.platform}
                    options={pageList}
                    autoComplete="true"
                    label="Select Account(s)"
                    onChange={handlePageListChange}
                    getData={getPagesData}
                    currentPage={currentPage}
                    pageCount={pageCount}
                  />
                ) : (
                  <AutoCompleteMultiChannelSelect
                    size="small"
                    variant="outlined"
                    text="channel"
                    limitTags={1}
                    onInputChange={onInputChange}
                    // value={state.platform}
                    options={pageList}
                    autoComplete="true"
                    label="Select Channel(s)"
                    onChange={handlePageListChange}
                    getData={getPagesData}
                    currentPage={currentPage}
                    pageCount={pageCount}
                  />
                )}
              </FormControl>
            </div>
          </div>
        ) : (
          <></>
        )}
        {action === "edit" ? (
          <div className="option_container">
            <div className="option_name"></div>
            <div className="option_input">
              <CustomButton
                variant="contained"
                className="button_style"
                type="submit"
              >
                Save
              </CustomButton>
              <CustomButton
                variant="outlined"
                className="cancel_style"
                onClick={handleEdit}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        ) : (
          <div className="option_container">
            <div className="option_name"></div>
            <div className="option_input">
              <CustomButton
                variant="contained"
                className="button_style"
                style={{ width: action === "edit" ? "20%" : "35%" }}
                type="submit"
              >
                Create a SMAR Group
              </CustomButton>
            </div>
          </div>
        )}
      </ModalContainer>
    </form>
  );
};

export default SMARGroupDetails;
