import React, { useState, useContext } from "react";

// MUI
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import { CircularProgress } from "@mui/material";

//components
import CustomButton from "components/Common/CustomButton";

// apis
import {
  getTagsByCategory,
  updateTagStatus,
} from "apis/componentsAction/tagging";

// contexts
import { AlertContext } from "contexts/AlertContext";
import { AuthContext } from "contexts/AuthContext";
import { ModalContext } from "contexts/ModalContext";

const Container = styled("div")(({ theme }) => ({
  height: "360px",
  "& .tags_container": {
    height: "300px",
    display: "flex",
    "& .tag_categories_container": {
      width: "49%",
      height: "300px",
      borderRight: "1px solid #E7F2FF",
      "& .header": {
        padding: "0.5rem 0.75rem 0.2rem",
        background: "#D6EFFF",
        height: "35px",
        "& .heading": {
          color: "#4F4F4F",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
      "& .tag_cat_container": {
        height: `calc(100% - 35px)`,
        padding: "0.6rem 0.75rem",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#E5E5E5",
          borderRadius: "8px",
        },
        "& .category": {
          display: "flex",
          gap: 1,
          alignItems: "baseline",
          marginBottom: "0.4rem",
          "&:hover": {
            cursor: "pointer",
          },
          "& .active": {
            color: "#1D1D1D",
            fontWeight: "700",
            fontSize: "16px",
          },
          "& .inactive": {
            color: "#303030",
            fontWeight: "400",
            fontSize: "16px",
          },
        },
      },
    },
    "& .tag_list_container": {
      width: "58%",
      height: "300px",
      "& .header": {
        padding: "0.5rem 0.75rem 0.2rem",
        background: "#D6EFFF",
        height: "35px",
        "& .heading": {
          color: "#4F4F4F",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
      "& .search_bar": {
        padding: "0.75rem",
        height: "56px",
        "& .MuiInputLabel-root": {
          fontSize: "15px",
          color: "#4F4F4F",
          [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "5px",
        },
        "& .text_field_style": {
          fontSize: "16px",
          height: "18px",
          color: "#222222",
          [theme.breakpoints.down("xl")]: {
            height: "15px",
            fontSize: "14px",
          },
        },
      },
      "& .tags": {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        maxHeight: `calc(100% - 91px)`,
        padding: "0.6rem 0.75rem",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#E5E5E5",
          borderRadius: "8px",
        },
        "& .chips": {
          fontSize: "16px",
          fontWeight: "500",
          color: "#1B1C1E",
          background: "#F2F9FF",
          border: "1px solid #CDECFF",
          [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
          },
        },
        "& svg": {
          color: "#4F4F4F",
          height: "18px",
          width: "18px",
        },
      },
    },
  },
  "& .button_container": {
    borderTop: "1px solid #E7F2FF",
    padding: "0.7rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    "& .add_style": {
      background: "#009DFF",
      color: "#FFFFFF",
      borderRadius: "20px",
      padding: "0.1rem 2rem",
      fontSize: "14px",
    },
    "& .cancel_style": {
      color: "#787575",
    },
  },
}));

const TagStatus = ({handleClose }) => {
  const { modalProps } = useContext(ModalContext);
  const { showAlert } = useContext(AlertContext);

  const [selectedActive, setSelectedActive] = useState([]);
  const [selectedInactive, setSelectedInactive] = useState([]);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const handleUpdateTag = async () => {
    setLoading(true);
    let response = "";
    try {
      if (getButtonText() === "Set as Inactive") {
        // Update selectedActive tags to be inactive
        response = await updateTagStatus(selectedActive, false);
      } else if (getButtonText() === "Set as Active") {
        // Update selectedInactive tags to be active
        response = await updateTagStatus(selectedInactive, true);
      }

      setLoading(false);
      if (response.data) {
        modalProps?.handleCategoryClick &&
          modalProps?.handleCategoryClick(modalProps.selectedCategory, true);
        handleClose();
      } else {
        showAlert("Tags Not Updated", "error"); // This should only be called if there is no error
      }
    } catch (error) {
      // Handle the error here, you can show an alert or perform other actions
      console.error("Error updating tag status:", error);
      setLoading(false);
      // Don't call handleClose() here if an error occurs
    }
  };
  const handleCheckboxChange = (tagId, status) => {
    // Check if a tag from the opposite status is already selected
    const oppositeSelected =
      status === "active" ? selectedInactive : selectedActive;

    if (oppositeSelected.length > 0) {
      showAlert(
        "Cannot select tags from both Active and Inactive categories.",
        "error"
      );
      return;
    }

    // Depending on the status, update the appropriate selected array
    if (status === "active") {
      const newSelectedActive = [...selectedActive];
      const index = newSelectedActive.indexOf(tagId);
      if (index === -1) {
        newSelectedActive.push(tagId);
      } else {
        newSelectedActive.splice(index, 1);
      }
      setSelectedActive(newSelectedActive);
    } else {
      const newSelectedInactive = [...selectedInactive];
      const index = newSelectedInactive.indexOf(tagId);
      if (index === -1) {
        newSelectedInactive.push(tagId);
      } else {
        newSelectedInactive.splice(index, 1);
      }
      setSelectedInactive(newSelectedInactive);
    }
  };
  const getButtonText = () => {
    if (selectedActive.length > 0) {
      return "Set as Inactive";
    } else if (selectedInactive.length > 0) {
      return "Set as Active";
    } else {
      return "";
    }
  };
  return (
    <Container>
      <div className="tags_container">
        <div className="tag_categories_container">
          <div className="header">
            <Typography className="heading">Active Tags</Typography>
          </div>
          <div className="tag_cat_container">
            {modalProps.tagsList?.map((val) => {
              if (
                val.tags_status === true &&
                val.name !== "Mann Ki Baat Content" &&
                val.name !== "Content shared by PM Narendra Modi on his engagements and Government’s initiatives" &&
                val.name !== "Others"
              ) {
                return (
                  <div className="category" key={val.id}>
                    <input
                      type="checkbox"
                      checked={selectedActive.includes(val.id)}
                      onChange={() => handleCheckboxChange(val.id, "active")}
                    />
                    <Typography
                      className={
                        selectedActive.includes(val.id) ? "active" : "inactive"
                      }
                    >
                      {val.name}
                    </Typography>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="tag_categories_container">
          <div className="header">
            <Typography className="heading">Inactive Tags</Typography>
          </div>
          <div className="tag_cat_container">
            {modalProps.tagsList?.map((val) => {
              if (val.tags_status === false) {
                return (
                  <div className="category" key={val.id}>
                    <input
                      type="checkbox"
                      checked={selectedInactive.includes(val.id)}
                      onChange={() => handleCheckboxChange(val.id, "inactive")}
                    />
                    <Typography
                      className={
                        selectedInactive.includes(val.id)
                          ? "active"
                          : "inactive"
                      }
                    >
                      {val.name}
                    </Typography>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      {(selectedActive.length > 0 || selectedInactive.length > 0) && (
        <div className="button_container">
          <CustomButton className="add_style" onClick={handleUpdateTag}>
            {loading ? (
              <CircularProgress
                sx={{
                  width: "18px !important",
                  height: "18px !important",
                  color: "#FFF",
                }}
              />
            ) : (
              getButtonText()
            )}
          </CustomButton>
        </div>
      )}
    </Container>
  );
};

export default TagStatus;
