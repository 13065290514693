import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "contexts/AuthContext";
import { AlertProvider } from "contexts/AlertContext";
import { ModalProvider } from "contexts/ModalContext";
import { DataProvider } from "contexts/DataContext";
import GlobalCssOverride from "ThemeProvider";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <GlobalCssOverride>
      <AuthProvider>
        <DataProvider>
          <AlertProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </AlertProvider>
        </DataProvider>
      </AuthProvider>
    </GlobalCssOverride>
  // </React.StrictMode>
);
