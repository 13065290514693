export const accountService = {
  login,
  logout,
};

async function login(platform) {
  const { authResponse } = await new Promise(window.FB.login);
  if (!authResponse) return;

  const data = await getPages(authResponse.accessToken, authResponse.userID, platform);
  return data;
}

const getPages = async (newAccessToken, userId, platform) => {
  let data = [];
  let next = `https://graph.facebook.com/v13.0/me/accounts?&access_token=${newAccessToken}&fields=access_token,name,id,instagram_business_account&limit=3`;
  while (next) {
    await fetch(next)
      .then((response) => response.json())
      .then((result) => {
        data = [...data, ...result.data];
        if (result.data.length > 0 && result.paging) {
          next = result.paging.next;
        } else {
          next = false;
        }
      });
  }
  const info = await getLongTermUserToken(newAccessToken, userId, data, platform);
  return info;
};

const getLongTermUserToken = async (token, userId, pageData, platform) => {
  const response = await fetch(`https://graph.facebook.com/v12.0/oauth/access_token?grant_type=fb_exchange_token&client_id=4442192615830351&client_secret=cd5ed904eacc5b3d30615665afc0f368&fb_exchange_token=${token}`);
  const result = await response.json();
  const data = await getLongTermPageToken(result.access_token, userId, pageData, platform);
  return data;
};

const getLongTermPageToken = async (token, userId, pageData, platform) => {
  let next = `https://graph.facebook.com/v12.0/${userId}/accounts?access_token=${token}`;
  let key = 0;
  let payload = [];
  while (next) {
    let response = await fetch(next);
    let result = await response.json();
    result.data.forEach((item, index) => {
      if (platform === "Facebook") {
        payload.push({
          name: item.name,
          long_token: item.access_token,
          short_token: pageData[key].access_token,
          page_id: item.id,
        });
      } else if (platform === "Instagram") {
        if (pageData[key].instagram_business_account) {
          payload.push({
            name: item.name,
            long_token: item.access_token,
            short_token: pageData[key].access_token,
            page_id: pageData[key].id,
          });
        }
      }
      key++;
    });
    if (result.data.length > 0 && result.paging.next) {
      next = result.paging.next;
    } else {
      next = false;
    }
  }
  return payload;
}

function logout() {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  //  window.FB.api('/me/permissions', 'delete', null, () => window.FB.logout());
  //  stopAuthenticateTimer();
  //  accountSubject.next(null);
  //  history.push('/login');
  window.FB.logout(function (response) {
    console.log("Logout");
  })
}