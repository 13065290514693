import React, { useState, useContext } from "react";

// MUI
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress } from "@mui/material";

//components
import CustomButton from "components/Common/CustomButton";

// apis
import { addMpTagMapping, addNewTag } from "apis/componentsAction/tagging";

// contexts
import { AlertContext } from "contexts/AlertContext";
import { AuthContext } from "contexts/AuthContext";
import { ModalContext } from "contexts/ModalContext";

const Container = styled("div")(({ theme }) => ({
  height: "360px",
  "& .tags_container": {
    height: "300px",
    display: "flex",
    "& .tag_categories_container": {
      width: "42%",
      height: "300px",
      borderRight: "1px solid #E7F2FF",
      "& .header": {
        padding: "0.5rem 0.75rem 0.2rem",
        background: "#D6EFFF",
        height: "35px",
        "& .heading": {
          color: "#4F4F4F",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
      "& .tag_cat_container": {
        height: `calc(100% - 35px)`,
        padding: "0.6rem 0.75rem",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#E5E5E5",
          borderRadius: "8px",
        },
        "& .category": {
          marginBottom: "0.4rem",
          "&:hover": {
            cursor: "pointer",
          },
          "& .active": {
            color: "#1D1D1D",
            fontWeight: "700",
            fontSize: "16px",
          },
          "& .inactive": {
            color: "#303030",
            fontWeight: "400",
            fontSize: "16px",
          },
        },
      },
    },
    "& .tag_list_container": {
      width: "58%",
      height: "300px",
      "& .header": {
        padding: "0.5rem 0.75rem 0.2rem",
        background: "#D6EFFF",
        height: "35px",
        "& .heading": {
          color: "#4F4F4F",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
      "& .search_bar": {
        padding: "0.75rem",
        height: "56px",
        "& .MuiInputLabel-root": {
          fontSize: "15px",
          color: "#4F4F4F",
          [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingLeft: "5px",
        },
        "& .text_field_style": {
          fontSize: "16px",
          height: "18px",
          color: "#222222",
          [theme.breakpoints.down("xl")]: {
            height: "15px",
            fontSize: "14px",
          },
        },
      },
      "& .tags": {
        display: "flex",
        flexWrap: "wrap",
        gap: "0.5rem",
        maxHeight: `calc(100% - 91px)`,
        padding: "0.6rem 0.75rem",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#E5E5E5",
          borderRadius: "8px",
        },
        "& .chips": {
          fontSize: "16px",
          fontWeight: "500",
          color: "#1B1C1E",
          background: "#F2F9FF",
          border: "1px solid #CDECFF",
          [theme.breakpoints.down("xl")]: {
            fontSize: "14px",
          },
        },
        "& svg": {
          color: "#4F4F4F",
          height: "18px",
          width: "18px",
        },
      },
    },
  },
  "& .button_container": {
    borderTop: "1px solid #E7F2FF",
    padding: "0.7rem 1.5rem",
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    "& .add_style": {
      background: "#009DFF",
      color: "#FFFFFF",
      borderRadius: "20px",
      padding: "0.1rem 2rem",
      fontSize: "14px",
    },
    "& .cancel_style": {
      color: "#787575",
    },
  },
}));

const AddTag = ({ handleClose }) => {
  const { modalProps } = useContext(ModalContext);
  const { showAlert } = useContext(AlertContext);
  const { user } = useContext(AuthContext);

  const [selected, setSelected] = useState(modalProps?.categories[0]?.id);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const mpTagMapping = async (tagId, stateId) => {
    const response = await addMpTagMapping(tagId, stateId);
  };
  const handleAddTag = async () => {
    setLoading(true);
    if (!value || value?.trim() === "") {
      setLoading(false);
      showAlert("Please type a new category name", "error");
      return;
    }
    if (value?.length > 50 && modalProps.pageCategory !== "MP") {
      setLoading(false);
      showAlert("Ensure this field has no more than 50 characters.", "error");
      return;
    }
    let response;
    if (
      modalProps.pageCategory === "MP" &&
      (value.toLowerCase() === "mann ki baat content" ||
        value.toLocaleLowerCase() === "others" ||
        value.toLocaleLowerCase() ===
          "content shared by pm narendra modi on his engagements and government’s initiatives")
    ) {
      showAlert("you cant add this tag, already present", "error");
    } else {
      response = await addNewTag(
        value,
        user?.pk,
        selected,
        modalProps.stateId,
        modalProps.pageCategory
      );
    }
    if (modalProps?.selectedCategory == selected) {
      modalProps?.handleCategoryClick &&
        modalProps?.handleCategoryClick(selected, true);
    }
    setLoading(false);

    handleClose();
    if (response.error !== null) {
      if (response.error?.status === 400) {
        showAlert("This tag name is already present!", "error");
      } else {
        showAlert("Something went wrong!", "error");
      }
    } else {
      showAlert("Successfully add a new Tag", "success");
      {
        modalProps.pageCategory === "MP" &&
          mpTagMapping(response.data.id, modalProps.stateId);
      }
    }
  };

  return (
    <Container>
      <div className="tags_container">
        <div className="tag_categories_container">
          <div className="header">
            <Typography className="heading">Tag Category</Typography>
          </div>
          <div className="tag_cat_container">
            {modalProps?.categories !== null &&
              modalProps?.categories?.map((val) => {
                return (
                  <div
                    onClick={() => setSelected(val?.id)}
                    className="category"
                  >
                    <Typography
                      key={val?.id}
                      className={selected === val?.id ? "active" : "inactive"}
                    >
                      {val?.name}
                    </Typography>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="tag_list_container">
          <div className="header">
            <Typography className="heading">Tag List</Typography>
          </div>
          <div className="search_bar">
            <TextField
              variant="outlined"
              placeholder="Enter Tag"
              size="small"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              inputProps={{
                className: "text_field_style",
              }}
              sx={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div className="button_container">
        <CustomButton className="cancel_style" onClick={handleClose}>
          Cancel
        </CustomButton>
        <CustomButton className="add_style" onClick={handleAddTag}>
          {" "}
          {loading ? (
            <CircularProgress
              sx={{
                width: "18px !important",
                height: "18px !important",
                color: "#FFF",
              }}
            />
          ) : (
            "Add"
          )}
        </CustomButton>
      </div>
    </Container>
  );
};

export default AddTag;
