import React, { useEffect, useState } from "react";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    // flexGrow: 1,
    flexDirection: 'row',
    borderBottom: "1px solid #e0e0e0",
    borderLeft: "1px solid #e0e0e0",
    borderRight: "1px solid #e0e0e0",
    justifyContent: "space-around",
    alignContent: "stretch",
    flexWrap: "nowrap",
    alignItems: "stretch",
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: "#FFFFFF",
    // flexBasis: 42
  },
  container_rank: {
    width: '7%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_page_name: {
    width: '23%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 6,
  },
  container_party: {
    width: '18%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 6,
  },
  container_total_score: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_total_posts: {
    width: '11%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_total_shares: {
    width: '12%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_top_ten: {
    width: '12%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_max_shares: {
    width: '12%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  container_median_shares: {
    width: '12%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 4,
  },
  column_heading: {
    fontSize: "9px",
    textAlign: "center",
    verticalAlign: "center",
    alignItems: 'center',
    justifyContent: "center",
    color: "#303030",
    fontFamily: 'Roboto',
    fontWeight: 400,
    paddingHorizontal: 10,
    paddingVertical: 3,
    borderRadius: 10,
  },
});

const PdfTableBodyNoParty = ({ data, start_date, end_date, platform }) => {
  const [rows, setRows] = useState("");
  const [formattingData, setFormattingData] = useState([]);

  const randomColour = (field, value) => {
    if(value === 0) {
      return "#FFFFFF";
    }
    if (formattingData.length > 0) {
      const data = [...formattingData].sort((firstVal, secondVal) => {
        return secondVal[`${field}`] - firstVal[`${field}`]
      });
      let index_new = data.findIndex(x => x[`${field}`] === value);
      if (index_new >= 7) {
        return "#FFFFFF";
      }
      // const colors = ["#5FF1F1", "#8EFBFB", "#B0F9F9", "#DAFFFF", "#ECFFFF", "#5cd60c"];
      const colors = ["#70db70", "#85e085", "#99e699", "#adebad", "#c2f0c2", "#d6f5d6", "#ebfaeb"];
      var random_color = colors[index_new];
      return random_color;
    }
    return "#FFFFFF";
  };

  useEffect(() => {
    if (data.length > 0) {
      setRows(data);
      setFormattingData([...data]);
    } else {
      setRows([]);
      setFormattingData([]);
    }
  }, [data]);

  return (
    <>
      {rows &&
        rows.map((val, index) => {
          if (index > 2) {
            return (
              <View style={styles.row} key={index} wrap={false}>
                <View style={styles.container_rank}>
                  <Text style={styles.column_heading}>{val.position}</Text>
                </View>
                {platform.toLowerCase() !== "twitter" ?
                  <View style={styles.container_page_name}>
                    <Text style={styles.column_heading}>
                      <Link
                        src={platform.toLowerCase() === "youtube" ?`https://youtube.com/channel/${val.page__page_id}`:platform.toLowerCase() === "instagram" ?`https://instagram.com/${val.page__page_id}`:`https://facebook.com/${val.page__page_id}`}
                        style={{ textDecoration: 'none', borderBottom: 'none', color: '#0000FF', }}
                      >
                        {val.page__page_name}
                      </Link>
                    </Text>
                  </View> :
                  <View style={styles.container_page_name}>
                    <Text style={styles.column_heading}>
                      {val.page__page_id}
                    </Text>
                  </View>
                }
                {/* <View style={styles.container_party}>
                  <Text style={styles.column_heading}>{val.page__political_party__name}</Text>
                </View> */}
                <View style={styles.container_total_score}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("score", val.score)}` : "" }]}
                  >
                    {val.score}
                  </Text>
                </View>
                <View style={styles.container_total_posts}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("total_posts", val.total_posts)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.total_posts : val.total_tweets}
                  </Text>
                </View>
                <View style={styles.container_total_shares}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("total_shares", val.total_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.total_shares : val.total_retweets}
                  </Text>
                </View>
                <View style={styles.container_top_ten}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("num_posts_in_top", val.num_posts_in_top)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.num_posts_in_top : val.num_tweets_in_top}
                  </Text>
                </View>
                <View style={styles.container_max_shares}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("max_shares", val.max_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.max_shares : val.max_retweets}
                  </Text>
                </View>
                <View style={styles.container_median_shares}>
                  <Text
                    style={[styles.column_heading, { backgroundColor: start_date !== end_date && platform.toLowerCase() !== "twitter" ? `${randomColour("median_shares", val.median_shares)}` : "" }]}
                  >
                    {platform.toLowerCase() !== "twitter" ? val.median_shares : val.median_retweets}
                  </Text>
                </View>
              </View>
            )
          }
        })}
    </>
  );
};

export default PdfTableBodyNoParty;