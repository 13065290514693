import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ModalContext } from "contexts/ModalContext";
import { useMediaQuery, useTheme } from "@mui/material";
import ReactPlayer from "react-player/youtube";

const Container = styled(Box)(({ theme }) => ({
  //   padding: '20px',
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  marginBottom: "1rem",
  color: "#003A70",
  fontWeight: 600,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  borderRadius: "8px",
  overflow: "hidden",
}));

const CardImageWrapper = styled(Box)(({ theme, tabValue }) => ({
  position: "relative",
  paddingTop: tabValue === "facebook" && "56.25%", // 16:9 aspect ratio
  height: "115px",
  width: "100%",
}));

const CardImage = styled(CardMedia)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

const PostLinkBlock = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  padding: "4px 8px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

const PostLinkText = styled(Typography)(({ theme }) => ({
  color: "white",
  fontSize: "12px",
  marginRight: "4px",
}));

const PageName = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "14px",
  marginBottom: "8px",
}));

const PostContent = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#666",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "-webkit-line-clamp": 3,
  "-webkit-box-orient": "vertical",
  maxHeight: "4.2em",
  marginBottom: theme.spacing(1),
  whiteSpace: "normal", // Allows the text to wrap within the container
}));

const StatsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderTop: "1px solid #eee",
  padding: "8px ",
  width: "100%",
}));

const StatItem = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#666",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& strong": {
    fontSize: "14px",
    color: "#333",
    //marginRight: '4px',
  },
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "20px",
  marginBottom:'10px'
}));

const PaginationButton = styled("button")(({ theme, active }) => ({
  backgroundColor: active ? "#1B90FE" : "#fff",
  color: active ? "#fff" : "#1B90FE",
  border: "1px solid #1B90FE",
  padding: "8px 10px",
  margin: "0 5px",
  cursor: "pointer",
  fontSize: "18px",
  borderRadius: "4px",
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  "&:hover:not(:disabled)": {
    backgroundColor: "#1B90FE",
    color: "#fff",
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  textAlign: "center",
  "& img": {
    maxWidth: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  "& p": {
    marginTop: "20px",
  },
  "& button": {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
}));
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0.75rem",
  width:'100%'
}));

const TopPosts = ({ posts, tabValue }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const { showModal } = useContext(ModalContext);
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const getPostsPerPage = () => {
    if (isXs) return 4; // 2 rows of 2 posts
    if (isSm) return 6; // 2 rows of 3 posts
    if (isMd) return 8; // 2 rows of 4 posts
    if (isLg) return 10; // 2 rows of 5 posts
    return 10; // default
  };

  const postsPerPage = getPostsPerPage();
  const totalPages = Math.ceil(posts.length / postsPerPage);

  const getHeading = () => {
    // Determine the type of heading based on tabValue
    const type = tabValue === "facebook" ? "Posts" : "Videos";
  
    // Determine the specific heading based on the length of posts
    if (posts.length === 30) return `Top 30 ${type}`;
    if (posts.length === 10) return `Top 10 ${type}`;
    return `Top ${type}`;
  };

  const openModal = (post) => {
    setSelectedPost(post);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedPost(null);
  };
  const handleShowPost = (post, userId) => {
    const modalProps = {
      type: "show post",
      title: post.page_name,
      image:
        tabValue === "youtube"
          ? post.post_id
          : post.full_picture,
      alt:
        tabValue === "youtube"
          ? `https://img.youtube.com/vi/${post.post_id.replace(/"/g, "")}/0.jpg`
          : post.full_picture,
      message: post.message,
      width: "md",
      id: userId,
      tabValue:tabValue
    };
    showModal("show-post", modalProps, "");
  };
  const renderPosts = (tabValue) => {
    const start = currentPage * postsPerPage;
    const end = start + postsPerPage;
    return posts.slice(start, end).map((post, index) => {
      return (
        <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
          <StyledCard>
            <CardActionArea
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              onClick={() => {
                handleShowPost(post);
                openModal(post);
              }}
            >
              <CardImageWrapper tabValue={tabValue}>
                {tabValue === "youtube" ? (
                  <ReactPlayer
                    url={`https://www.youtube.com/watch?v=${post.post_id.replace(
                      /"/g,
                      ""
                    )}`}
                    width="100%"
                    height="100%"
                    light={`https://img.youtube.com/vi/${post.post_id.replace(
                      /"/g,
                      ""
                    )}/0.jpg`}
                    playing={false}
                  />
                ) : (
                  <CardImage image={post.full_picture} title={post.message} />
                )}
                <PostLinkBlock>
                  <Link
                    href={
                      tabValue === "youtube"
                        ? `https://www.youtube.com/watch?v=${post.post_id.replace(
                            /"/g,
                            ""
                          )}`
                        : post.post_link
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                  >
                    <PostLinkText>
                      {tabValue === "youtube"
                        ? "Watch on YouTube"
                        : "Post link"}
                    </PostLinkText>
                    <OpenInNewIcon fontSize="small" sx={{ color: "white" }} />
                  </Link>
                </PostLinkBlock>
              </CardImageWrapper>
              <StyledCardContent>
                <PageName variant="h6">{post.page_name}</PageName>
                <PostContent>{post.message}</PostContent>
              </StyledCardContent>
              <StatsContainer>
                <StatItem>
                  {tabValue==="facebook"?"Shares":"Views"}<strong>{post?.shares?.toLocaleString()}</strong>
                </StatItem>
                <StatItem>
                  Likes<strong>{post?.likes?.toLocaleString()}</strong>
                </StatItem>
                <StatItem>
                  Comments<strong>{post?.comments?.toLocaleString()}</strong>
                </StatItem>
              </StatsContainer>
            </CardActionArea>
          </StyledCard>
        </Grid>
      );
    });
  };

  return (
    <Container>
      <Heading>{getHeading()}</Heading>
      <Grid container spacing={3}>
        {renderPosts(tabValue)}
      </Grid>
      {posts.length > postsPerPage && (
        <PaginationContainer>
          <PaginationButton
            onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
            active={currentPage === 0}
          >
            &#8592;
          </PaginationButton>
          <PaginationButton
            onClick={() =>
              setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))
            }
            disabled={currentPage === totalPages - 1}
            active={currentPage === totalPages - 1}
          >
            &#8594;
          </PaginationButton>
        </PaginationContainer>
      )}
    </Container>
  );
};

export default TopPosts;
