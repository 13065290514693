import { Box, Typography } from "@mui/material";
import TopPosts from "components/Sections/TotalPosts";

const HashtagPosts = ({ modalData }) => {
  return (
    <Box sx={{ padding: "10px 10px 10px 24px" }}>
      {modalData.posts && modalData.posts.Top_post_data.length > 0 ? (
        <TopPosts posts={modalData.posts.Top_post_data} tabValue={modalData.tabValue} />
      ) : (
        <Typography sx={{ color: "red", fontWeight: "bold" }}>
          No data found
        </Typography>
      )}
</Box>
  );
};

export default HashtagPosts;
