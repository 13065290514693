import React from "react";
import { styled } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';

const StyledPopper = styled(Popper)(({theme}) => ({
  "& .MuiAutocomplete-listbox": {
    "& li": {
      fontSize: "15px",
      [theme.breakpoints.down('xl')]: {
        fontSize: "14px",
      },
    }
  }
}));

const Container = styled("div")(({theme}) => ({
  "& .MuiAutocomplete-root": {
    background: "#FFFFFF",
  },
  "& .MuiAutocomplete-input": {
    height: "19px",
    fontSize: "14px",
    [theme.breakpoints.down('xl')]: {
      height: "17px",
      fontSize: "14px",
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "15px",
    color: "#4F4F4F",
    [theme.breakpoints.down('xl')]: {
      fontSize: "14px",
    },
  }
}));

const CustomPopper = (props) => {
  return <StyledPopper {...props} placement="bottom" />
};

const defaultOptions = [{
  political_party_name: '',
}];

const AutoCompletePartySelect = ({
  value,
  options = options.length === 0 ? defaultOptions : options,
  variant = "outlined",
  disabled = false,
  onChange,
  label,
  text,
  tooltip,
  ...restProps
}) => {
  let newVal = {
    name: "",
  }
  if (value && typeof value === "string") {
    newVal = {
      name: value,
    }   
  } else if (value) {
    newVal = value;
  }

  const autocompleteComponent = (
    <Container>
      <FormControl variant="outlined" style={{ width: "100%", padding: 0 }}>
        <Autocomplete
          fullWidth
          value={newVal}
          options={options}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option ? option.name : ""}
          onChange={(event, newValue) => onChange(newValue, text)}
          filterSelectedOptions
          renderInput={(params, index) => {
            const { InputProps, inputProps, ...renderProps } = params;
            inputProps.disabled = disabled;
            InputProps.endAdornment = disabled ? <></> : InputProps.endAdornment;
            return (
              <TextField
                key={index}
                {...renderProps}
                InputProps={{ ...InputProps, disableUnderline: variant === "standard" ? true : false }}
                inputProps={inputProps}
                label={label}
                variant={variant}
                {...restProps}
              />
            )
          }}
          PopperComponent={CustomPopper}
        />
      </FormControl>
    </Container>
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      {autocompleteComponent}
    </Tooltip>
  ) : autocompleteComponent;
};

export default AutoCompletePartySelect;