import React, { useCallback, useRef } from "react";

//mui
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox": {
    "& li": {
      fontSize: "15px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
  },
}));

const Container = styled("div")(({ theme }) => ({
  "& .MuiMenuItem-root": {
    // background: "green",
  },
  "& .MuiAutocomplete-root": {
    background: "#FFFFFF",
  },
  "& .MuiAutocomplete-input": {
    height: "19px",
    fontSize: "14px",
    [theme.breakpoints.down("xl")]: {
      height: "17px",
      fontSize: "14px",
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "15px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
  "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
    // paddingTop: "3px",
    // paddingBottom: "3px",
    "& .MuiChip-label": {
      fontSize: "14px",
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('xl')]: {
        fontSize: "12px",
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
      [theme.breakpoints.down('xl')]: {
        fontSize: "16px",
      },
    },
    "& .MuiAutocomplete-tagSizeMedium": {
      fontSize: "14px",
      // background: "cyan",
      [theme.breakpoints.down('xl')]: {
        fontSize: "12px",
      },
    }
  },
  "& .MuiAutocomplete-root .MuiAutocomplete-tag": {
    padding: 0,
  },
}));

const CustomPopper = (props) => {
  return <StyledPopper {...props} placement="bottom" />;
};

const AutoCompleteMultiAccountSelect = ({
  value,
  options,
  variant = "outlined",
  disabled = false,
  onChange,
  limitTags,
  label,
  text,
  onInputChange,
  getData,
  currentPage,
  pageCount,
  ...restProps
}) => {
  const intObserver = useRef();
  const lastItemRef = useCallback(post => {

    if (intObserver.current) intObserver.current.disconnect()

    intObserver.current = new IntersectionObserver(posts => {
      if (posts[0].isIntersecting && (currentPage <= pageCount)) {
        getData("", currentPage);
      }
    })

    if (post) intObserver.current.observe(post)
  }, [currentPage, pageCount]);

  let len = null;
  if(options && options.length > 0) {
    len = options.length;
  }
  return (
    <Container>
      <FormControl variant="outlined" style={{ width: "100%", padding: 0 }}>
        <Autocomplete
          multiple
          fullWidth
          disableCloseOnSelect
          // value={value}
          options={options}
          limitTags={limitTags}
          onInputChange={onInputChange}
          // isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => (option ? `${option.insta_name}` : "")}
          onChange={(event, newValue) => onChange(newValue, text)}
          filterSelectedOptions
          renderOption={(props, option) => {
            let ref = null;
            if(options && options.length > 0) {
              ref = option === options[len - 1] ? lastItemRef : null; 
            } 
            return (
              <li {...props} ref={ref}>{option.insta_name}</li>
            )
          }}
          renderInput={(params, index) => {
            const { InputProps, inputProps, ...renderProps } = params;
            inputProps.disabled = disabled;
            InputProps.endAdornment = disabled ? (
              <></>
            ) : (
              InputProps.endAdornment
            );
            return (
              <TextField
                key={index}
                {...renderProps}
                InputProps={{
                  ...InputProps,
                  disableUnderline: variant === "standard" ? true : false,
                }}
                inputProps={inputProps}
                label={label}
                // className={classes.input}
                variant={variant}
                {...restProps}
              />
            );
          }}
          PopperComponent={CustomPopper}
        />
      </FormControl>
    </Container>
  );
};

export default AutoCompleteMultiAccountSelect;