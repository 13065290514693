import React from "react";
import NotoSansRegular from "assets/fonts/arial-unicode-ms.ttf";
import NotoSansBold from "assets/fonts/arial-unicode-ms-bold.ttf";
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf"
import RobotoBold from "assets/fonts/Roboto-Bold.ttf"
import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    { src: NotoSansRegular, fontWeight: 400 },
    { src: NotoSansBold, fontWeight: 700 },
  ],
});

Font.register({
  family: 'NewRoboto', fonts: [
    // { src: RobotoThin, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 700 },
  ]
});

const styles = StyleSheet.create({
  cardContainer: {
    width: "24.5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    marginHorizontal: 1,
    height: 180,
    marginBottom: 30,
    // border: "1px solid #e0e0e0",
    borderRadius: "10px",
    border: "1px solid #E5E5E5",
  },
  image: {
    width: "100%",
    height: 150,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    objectPosition: "center center",
  },
  text_desc: {
    width: "100%",
    maxWidth: "100%",
    height: 150,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    overflow: "hidden",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "10px",
    paddingHorizontal: 2,
    // objectPosition: 'center center'
  },
  textContainer: {
    width: "100%",
  },
  textContainerNew: {
    backgroundColor: "#374A92",
    width: "100%",
    paddingVertical: 5,
  },
  textContainerNewIreland: {
    backgroundColor: "#7030a0ff",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    height: 15,
  },
  textContainerNewShareIreland: {
    backgroundColor: "#7030a0ff",
    width: "100%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    height: 15,
  },
  text: {
    color: "#fff",
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "10px",
  },
  textContainerNewShare: {
    backgroundColor: "#374A92",
    width: "100%",
    paddingVertical: 5,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
});

const ImageCardPdf = ({ image, shares, name, type, reportType }) => {
  return (
    <View style={styles.cardContainer} wrap={false}>
      {type === "image" ? (
        <Image src={image} style={styles.image} />
      ) : (
        <Text style={styles.text_desc}>{image}</Text>
      )}
        <View style={styles.textContainer}>
          <View style={reportType==="tailwind"?styles.textContainerNewIreland:styles.textContainerNew}>
            <Text style={styles.text}>{name}</Text>
          </View>
          <View style={reportType==="tailwind"?styles.textContainerNewShareIreland:styles.textContainerNewShare}>
            <Text style={styles.text}>Shares - {shares}</Text>
          </View>
        </View>
    </View>
  );
};

export default ImageCardPdf;
