import { useState, createContext, useEffect } from "react";
import { getStateList, getPartyList, getMappinList, getCountryList } from "apis/common/data";

export const DataContext = createContext();

export const DataProvider = (props) => {

  const [stateList, setStateList] = useState([]);
  const [partyList, setPartyList] = useState([]);
  const [mappingTable, setMappingTable] = useState([]);
  const [stateMapping, setStateMapping] = useState([]);
  const [youTubeData, setYouTubeData] = useState([]);
  const [twitterDataMedia, setTwitterDataMedia] = useState([]);
  const [twitterDataBjp, setTwitterDataBjp] = useState([]);
  const [twitterDataNonBjp, setTwitterDataNonBjp] = useState([]);
  const [countryList,setCountryList]=useState([])
  // const [youTubeState, setYouTubeState] = useState({
  //   top: false,
  // });

  const getStateData = async () => {
    const { result, err } = await getStateList();
    if (err) {
      alert("Couldn't fetch the data");
      return;
    }
    setStateList(result);
  };
  const getCountryData = async () => {
    const { result, err } = await getCountryList();
    if (err) {
      alert("Couldn't fetch the data");
      return;
    }
    setCountryList(result);
  };

  const getPartyData = async () => {
    const { result, err } = await getPartyList();
    if (err) {
      alert("Couldn't fetch the data");
      return;
    }
    setPartyList(result);
  };

  const getMappingTable = async () => {
    const { result, err } = await getMappinList();
    if (err) {
      alert("Couldn't fetch the data");
      return;
    }
    setMappingTable(result);
    const stateMappingValue = result.filter((val) => val.name.toLowerCase() === "state");
    setStateMapping(stateMappingValue)
  };

  useEffect(() => {
    getStateData();
    getPartyData();
    getMappingTable();
    getCountryData();
  }, []);

  const state = {
    stateList,
    partyList,
    countryList,
    mappingTable,
    stateMapping,
    youTubeData,
    twitterDataMedia,
    twitterDataBjp,
    twitterDataNonBjp,
    // youTubeState,
  };

  return (
    <DataContext.Provider
      value={{
        stateList: state.stateList,
        partyList: state.partyList,
        countryList:state.countryList,
        mappingTable: state.mappingTable,
        stateMapping: state.stateMapping,
        youTubeData: state.youTubeData,
        twitterDataMedia: state.twitterDataMedia,
        twitterDataBjp: state.twitterDataBjp,
        twitterDataNonBjp: state.twitterDataNonBjp,
        // youTubeState: state.youTubeState,
        setYouTubeData,
        setTwitterDataMedia,
        setTwitterDataBjp,
        setTwitterDataNonBjp,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};