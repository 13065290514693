import React from "react";

// MUI
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

const Container = styled("div")(({ theme,type }) => ({
  width: "100%",
  height:type==="tagging"?'32px':'',
  "& .MuiInputLabel-root": {
    height:'32px',
    fontSize: "15px",
    color: "#4F4F4F",
    [theme.breakpoints.down('xl')] : {
      fontSize: "14px",
    }
  },
  "& .MuiOutlinedInput-root": {
    paddingLeft:(type==="tagging"||type==="smarDashboard")?"": "5px",
    height:type==="tagging"?'32px':'',
  },
  "& .text-field-style": {
    fontSize: "12px",
    height: "18px",
    color: "#222222",
    [theme.breakpoints.down('xl')]: {
      height: "15px",
      fontSize: "14px",
    },
  }
}))

const CustomTextField = ({
  value,
  label,
  inputProps,
  type,
  ...restProps
}) => {
  let newInputProps = {
    className: "text-field-style"
  };
  return (
    <Container type={type}>
      <TextField
        label={label}
        size="small"
        value={value}
        {...restProps}
        inputProps={inputProps ? inputProps : {newInputProps}}
      />
    </Container>
  );
};

export default CustomTextField;