import React from "react";

//mui
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";

const StyledPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox": {
    "& li": {
      fontSize: "15px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
  },
}));

const Container = styled("div")(({ theme }) => ({
  "& .MuiMenuItem-root": {
    // background: "green",
  },
  "& .MuiAutocomplete-root": {
    background: "#FFFFFF",
  },
  "& .MuiAutocomplete-input": {
    height: "19px",
    fontSize: "14px",
    [theme.breakpoints.down("xl")]: {
      height: "17px",
      fontSize: "14px",
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "15px",
    color: "#4F4F4F",
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px",
    },
  },
}));

const CustomPopper = (props) => {
  return <StyledPopper {...props} placement="bottom" />;
};

const AutoCompleteSelect = ({
  value,
  options,
  variant = "outlined",
  disabled = false,
  onChange,
  label,
  text,
  ...restProps
}) => {
  return (
    <Container>
      <FormControl variant="outlined" style={{ width: "100%", padding: 0 }}>
        <Autocomplete
          fullWidth
          value={value}
          options={options}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => (option ? option : "")}
          onChange={(event, newValue) => onChange(newValue, text)}
          filterSelectedOptions
          renderInput={(params, index) => {
            const { InputProps, inputProps, ...renderProps } = params;
            inputProps.disabled = disabled;
            InputProps.endAdornment = disabled ? (
              <></>
            ) : (
              InputProps.endAdornment
            );
            return (
              <TextField
                key={index}
                {...renderProps}
                InputProps={{
                  ...InputProps,
                  disableUnderline: variant === "standard" ? true : false,
                }}
                inputProps={inputProps}
                label={label}
                // className={classes.input}
                variant={variant}
                {...restProps}
              />
            );
          }}
          PopperComponent={CustomPopper}
        />
      </FormControl>
    </Container>
  );
};

export default AutoCompleteSelect;
