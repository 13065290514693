import React from "react";
import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player/youtube";

const Post = ({ modalData, closeModal }) => {
  const { image, message, tabValue } = modalData;
  return (
    <Box sx={{ padding: "10px", display: "flex", flexDirection: "column",justifyContent:'center',alignItems:'center' }}>
      {tabValue === "facebook" ? (
        <img
          style={{ height: "30rem", width: "auto", objectFit: "contain" }}
          src={image}
          alt="post picture"
        />
      ) : (
        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${image}`}
          width="100%"
          height="30rem"
          controls
        />
      )}
      <Typography sx={{ marginTop: "5px" }}>{message}</Typography>
    </Box>
  );
};

export default Post;
