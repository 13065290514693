import AXIOS from "services/axios";

export const getUserList = async () => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/user/`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const updateYouTubeLog = async (
  headline,
  description,
  state,
  tendancy,
  heading,
  political_party,
  channel,
  log_time,
  image_field,
  anchor,
  type_id,
  coverage_duration,
  user_id
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  formData.append("headline", headline);
  formData.append("description", description);
  formData.append("tendancy", tendancy);
  formData.append("state", stateFinal);
  formData.append("heading", heading.name);
  // formData.append("political_party", political_party.id);
  formData.append("newchannel", channel.id ? channel.id : channel.pk);
  formData.append("anchor", anchor);
  formData.append("coverage_duration", coverage_duration);
  formData.append("type_id", type_id);

  if (image_field) formData.append("image", image_field);
  if (log_time) formData.append("log_time", log_time);

  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/logs/${user_id}/`,
      formData
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const inActiveYoutubeLog = async (user_id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/logs/${user_id}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const updatePrintLog = async (
  headline,
  description,
  state,
  tendancy,
  heading,
  political_party,
  channel,
  image_field,
  page,
  article_position,
  column_position,
  type_id,
  user_id
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  formData.append("headline", headline);
  formData.append("description", description);
  formData.append("tendancy", tendancy);
  formData.append("state", stateFinal);
  
  // formData.append("political_party", political_party.id);
  formData.append("channel", channel);
  formData.append("article_position", article_position);
  formData.append("column_position", column_position);
  formData.append("type_id", type_id);
  if(heading) formData.append("heading", heading.id);
  if (image_field) formData.append("image", image_field);
  if (page) formData.append("page", page);
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/media/${user_id}/`,
      formData
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const inActivePrintLog = async (user_id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/media/${user_id}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const updatePrimeTimeLog = async (
  debate_details,
  debate_heading,
  debate_time,
  summary,
  anchor,
  channel,
  tendancy,
  type_id,
  state,
  image_field,
  speech,
  related_party,
  user_id
) => {
  const response = { data: null, error: null };
  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  // Append other form data
  formData.append("debate_details", debate_details);
  formData.append("debate_heading", debate_heading);
  formData.append("debate_time", debate_time);
  formData.append("summary", summary);
  formData.append("anchor", anchor);
  formData.append("newchannel", channel);
  formData.append("tendancy", tendancy);
  formData.append("type_id", type_id);
  formData.append("state", stateFinal);
  {
    related_party.id && formData.append("political_party", related_party.id);
  }
  formData.append("speech", JSON.stringify(speech));
  // Append image data
  if (image_field) {
    formData.append("image", image_field);
  }
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/prime/${user_id}/`,
      formData
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const inActivePrimeTimeLog = async (user_id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/prime/${user_id}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const getSelectedUserLogData = async (
  date_start,
  date_stop,
  user_id
) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/?date_start=${date_start}&date_stop=${date_stop}&user=${user_id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getUserLogData = async (date_start, date_stop) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/?date_start=${date_start}&date_stop=${date_stop}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getUserPrintMediaLogData = async (date_start, date_stop) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/print/?date_start=${date_start}&date_stop=${date_stop}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getSelectedUserPrintMediaLogData = async (
  date_start,
  date_stop,
  user_id
) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/print/?date_start=${date_start}&date_stop=${date_stop}&user=${user_id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getSelectedUserPrimeTimeLogHistory = async (
  date_start,
  date_stop,
  user_id
) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/prime/?date_start=${date_start}&date_stop=${date_stop}&user=${user_id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getPrimeTimeLogData = async (log_id) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/prime/${log_id}/`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const getPrintCampaignAdsLogHistory = async (
  date_start,
  date_stop,
  user_id
) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/adprint/?date_start=${date_start}&date_stop=${date_stop}&user=${user_id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};
export const getElectronictCampaignAdsLogHistory = async (
  date_start,
  date_stop,
  user_id
) => {
  const response = { result: null, err: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/mmt/history/adelectronic/?date_start=${date_start}&date_stop=${date_stop}&user=${user_id}`
    );
    response.result = data;
  } catch (err) {
    response.err = err.response;
  }
  return response;
};

export const updateElectronicMediaCampignAds = async (
  description,
  state,
  political_party,
  channel,
  image_field,
  coverage_duration
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = null;
    type_id = 1;
  } else {
    stateFinal = state.id;
    type_id = 2;
  }
  const formData = new FormData();

  formData.append("description", description);
  formData.append("state", stateFinal);
  formData.append("political_party", political_party.id);
  formData.append("channel", channel.id);
  formData.append("coverage_duration", coverage_duration);
  formData.append("type_id", type_id);

  if (image_field) formData.append("image", image_field);

  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const generateCampaignAdsPdf = async (
  state,
  startDate,
  endDate,
  language,
  campaign_type,
  start_time,
  end_time
) => {
  const response = { data: null, error: null, status: null };
  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = "null";
    type_id = 1;
  } else {
    stateFinal = state.id;
    type_id = 2;
  }

  try {
    const requestData = {
      state_id: stateFinal,
      date_start: startDate,
      date_stop: endDate,
      language: language.toLowerCase(),
      start_time: start_time ? start_time : "",
      end_time: end_time ? end_time : "",
      state_name: state.name,
      campaign_type: campaign_type,
      type_id: `${type_id}`,
    };
    const { data, status } = await AXIOS.post(
      `${
        process.env.REACT_APP_API_PATH
      }/mmt/pdf/campaignAds/`,requestData
    );

    response.data = data;
    response.status = status; // Include status code in the response
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      response.error = error.response.data;
      response.status = error.response.status;
    } else if (error.request) {
      // The request was made but no response was received
      response.error = "No response received from server";
    } else {
      // Something happened in setting up the request that triggered an error
      response.error = "Error in request setup: " + error.message;
    }
  }
  return response;
};
