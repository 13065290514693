import AXIOS from "services/axios";

export const getSmarList = async (pageCount, platform, searchSmarGroup, region_type, category, object_id,party) => {
  const response = { data: null, error: null };

  try {
    const queryParams = [];

    if (region_type) queryParams.push(`region_type=${region_type}`);
    if (category) queryParams.push(`smargroup_category=${category}`);
    if (object_id) queryParams.push(`object_id=${object_id}`);
    if (pageCount > 1) queryParams.push(`page=${pageCount}`);
    if (platform) queryParams.push(`platform=${platform}`);
    if (searchSmarGroup) queryParams.push(`group_name=${searchSmarGroup}`);
    if (party) queryParams.push(`political_party=${party}`);
    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const { data } = await AXIOS.get(`${process.env.REACT_APP_API_PATH}/smargroup/${queryString}`);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};


export const getSmarDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/smargroup/${id}/`,
    );
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const addSmarGroup = async (
  id,
  group_name,
  smargroup_category,
  smargroup_sub_category,
  platform,
  state,
  country,
  pages,
  action,
  region_type
) => {
  let object_id = "";
 if (region_type.name.toLowerCase() === "state") {
    object_id = state.id;
  } else if (
    region_type.name.toLowerCase() === "nation" ||
    region_type.name.toLowerCase() === "nation"
  ) {
    object_id = country.id;
  }
  const response = {
    status: null,
    message: null,
  };
  let obj;
  if (platform?.name.toLowerCase() === "youtube") {
    obj = {
      group_name: group_name,
      smargroup_category: smargroup_category,
      smargroup_sub_category: smargroup_sub_category,
      platform: platform.id,
      pages: [],
      handles: [],
      insta_handles:[],
      ytchannels: pages,
      country:country.id,
      object_id:object_id,
      region_type:region_type.id
    }
  }else if (platform?.name.toLowerCase() === "instagram"){
    obj = {
      group_name: group_name,
      smargroup_category: smargroup_category,
      smargroup_sub_category: smargroup_sub_category,
      platform: platform.id,
      pages: [],
      handles: [],
      insta_handles:pages,
      ytchannels: [],
      country:country.id,
      object_id:object_id,
      region_type:region_type.id
    }
  } else {
    obj = {
      group_name: group_name,
      smargroup_category: smargroup_category,
      smargroup_sub_category: smargroup_sub_category,
      platform: platform.id,
      pages: pages,
      handles: [],
      ytchannels: [],
      insta_handles:[],
      country:country.id,
      object_id:object_id,
      region_type:region_type.id
    }
  }

  try {
    if (action === "edit") {
      const { data } = await AXIOS.patch(`${process.env.REACT_APP_API_PATH}/smargroup/${id}/`, 
        obj
      );
      response.status = data.status;
      response.message = data.message;
    } else {
      const { data } = await AXIOS.post(`${process.env.REACT_APP_API_PATH}/smargroup/`,
        obj
      );
      response.status = data.status;
      response.message = data.message;
    }
  } catch (err) {
    response.error = err.response.data.message;
  }
  return response;
};

export const handlePageDelete = async (id, newPages) => {
  const response = {
    status: null,
    message: null,
  };
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/smargroup/update/relation/group/${id}`, {
      page_ids: newPages,
    }
    );
    response.status = data.status;
    response.message = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getSmarGroupDetails = async (id) => {
  const response = { result: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/smargroup/details/${id}`,
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};