import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  gap: 2,
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    '&.Mui-disabled': {
      // styles for disabled state
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  border: `none !important`,
  color: theme.palette.primary.main,
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#253A6A",
    borderRadius: "0.125rem",
    // Add any other styles you want for the tooltip here
    // padding: '8px 12px',
    fontSize: "10px",
  },
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  '& svg': {
    width: '25px',
    height: '25px',
  },
}));

const ToggleGroup = ({ options, onChange, value }) => {
  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <StyledToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="toggle options"
    >
      {options.map((option) => (
        <Tooltip key={option.value} title={option.label}>
          <StyledToggleButton value={option.value}>
            {value === option.value ? option.selectedIcon : option.icon}
          </StyledToggleButton>
        </Tooltip>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default ToggleGroup;