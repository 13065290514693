import React, { useState, useEffect, useContext } from "react";

//mui
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MixedChart from "components/Charts/MixChart";

//components
import AutoCompleteGraphSelect from "components/Common/AutoCompleteGraphSelect";
import DivergentBarChart from "components/Charts/DivergentChart";
import MyResponsivePie from "components/Charts/PieChart";

//contexts
import { AlertContext } from "contexts/AlertContext";

const GraphContainer = styled(Box)(({ theme, section }) => ({
  //padding: "1.5rem 1.5rem 0.5rem 1.5rem", 
}));

const StyledIconButton = styled(IconButton)(({ theme, disabled }) => ({
  marginRight: "8px",
  padding: "5px",
  "& .editIcon": {
    fontSize: "1.4rem",
    color: disabled ? "#e6e6e6" : "#FF5252",
    background: "#EFFFFD",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.2rem",
    },
  },
  "& .delIcon": {
    fontSize: "2rem",
    background: "#EFFFFD",
    color: disabled ? "#e6e6e6" : "#FF5252",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.5rem",
    },
  },
}));
const colors = [
  "#fabc89",
  "#f5f08e",
  "#ff858d",
  "#edabe1",
  "#ad8ae5",
  "#6fa3ec",
  "#7dd5f2",
  "#31dded",
  "#7df2d9",
  "#9efaa7",
];
const TotalGraphColor = ["#165DFF", "#F7BA1E"];
const medianGraphColor = ["#0FC6C2"];

const GraphSection = ({ graphData, graphType, tabValue, selectedGraph }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [daysWonData, setDaysWonData] = useState([]);
  const [selectedPageNames, setSelectedPageNames] = useState([]);
  const { showAlert } = useContext(AlertContext);

  const allPageNames = graphData.map((item) => item.page__page_name);
  const itemsPerPage =
    6;
  useEffect(() => {
    const totalPages = Math.ceil(graphData.length / itemsPerPage);
    setPageCount(totalPages);
    const filteredData = graphData
      .filter((item) => item.no_of_days_won > 0)
      .map((item) => ({
        id: item.page__page_name,
        label: item.page__page_name,
        value: item.no_of_days_won,
      }));

    setDaysWonData(filteredData);
  }, [graphData, itemsPerPage]);

  const handlePageChange = (increment) => {
    const newPage = currentPage + increment;
    if (newPage > pageCount) {
      showAlert("No more pages to show");
    } else if (newPage < 1) {
      showAlert("You are on the first page");
    } else {
      setCurrentPage(newPage);
    }
  };

  const handlePageNamesChange = (newSelectedPageNames) => {
    setSelectedPageNames(newSelectedPageNames);
  };

  const getData = () => {
    let filteredMedianData = graphData;
    let filteredTotalData = graphData;
    let filteredTotalSharesData = graphData;
    let filteredMaxSharesData = graphData;

    if (selectedPageNames.length > 0) {
      filteredMedianData = graphData.filter((item) =>
        selectedPageNames.includes(item?.page__page_name)
      );
      filteredTotalData = graphData.filter((item) =>
        selectedPageNames.includes(item?.page__page_name)
      );
      filteredTotalSharesData = graphData.filter((item) =>
        selectedPageNames.includes(item?.page__page_name)
      );
      filteredMaxSharesData = graphData.filter((item) =>
        selectedPageNames.includes(item?.page__page_name)
      );
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return {
      medianSharesData: filteredMedianData.slice(startIndex, endIndex),
      totalSharesData: filteredTotalData.slice(startIndex, endIndex),
      totalSharesData: filteredTotalSharesData.slice(startIndex, endIndex),
      maxSharesData: filteredMaxSharesData.slice(startIndex, endIndex),
    };
  };

  const data = getData();

  const isAllDataZero = (data, fields) => {
    return data.every(item => fields.every(field => item[field] === 0));
  };
  
  const showPagination = false;
const renderAutoCompleteSelect = () => {
  switch (selectedGraph) {
    case "total posts vs median shares":
      return !isAllDataZero(graphData, ["total_posts", "median_shares"]);
    case "median shares vs max shares":
      return !isAllDataZero(graphData, ["median_shares", "max_shares"]);
    case "total posts vs total shares":
      return !isAllDataZero(graphData, ["total_posts", "total_shares"]);
    default:
      return false;
  }
};
  return (
    <GraphContainer>
      {selectedGraph === "number of days won" && (
         <>
          {!isAllDataZero(graphData, ["total_posts", "median_shares"]) ? (
        <Box sx={{ height: "400px" }}>
          <Typography
            variant="h6"
            mr={2}
            sx={{ color: "#002164", fontSize: "1rem" }}
          >
            Days Won
          </Typography>
          <MyResponsivePie
            data={daysWonData}
            colors={colors}
            type={"smarDashboard"}
          />
        </Box>
         ) : (
        <Typography variant="h6" align="center" sx={{ color: "#FF5252" }}>
              No data found
            </Typography>
        )}
      </>
      )}
       {renderAutoCompleteSelect() && selectedGraph !== "number of days won" && (
        <Box mb={2} ml={"auto"} mt={"-50px"}>
          <AutoCompleteGraphSelect
            value={selectedPageNames}
            options={allPageNames}
            onChange={handlePageNamesChange}
            label={tabValue === "facebook" ? "Select Pages" : "Select Channels"}
            tabValue={tabValue}
            selectedGraph={selectedGraph}
          />
        </Box>
      )}
       {selectedGraph === "total posts vs median shares" && (
        <>
          {!isAllDataZero(graphData, ["total_posts", "median_shares"]) ? (
            <MixedChart
              data={data}
              customColor={
                graphType === "median" ? medianGraphColor : TotalGraphColor
              }
              indexedBy="page__page_name"
              tabValue={tabValue}
            />
          ) : (
            <Typography variant="h6" align="center" sx={{ color: "#FF5252" }}>
              No data found
            </Typography>
          )}
        </>
      )}

      {selectedGraph === "median shares vs max shares" && (
        <>
          {!isAllDataZero(graphData, ["median_shares", "max_shares"]) ? (
            <DivergentBarChart
              data={data["maxSharesData"]}
              indexBy={"page__page_name"}
              fields={["median_shares", "max_shares"]}
              labels={
                tabValue === "facebook"
                  ? ["Median Shares", "Max Shares"]
                  : ["Median Views", "Max Views"]
              }
              height={400}
              width={900}
            />
          ) : (
            <Typography variant="h6" align="center" sx={{ color: "#FF5252" }}>
              No data found
            </Typography>
          )}
        </>
      )}

      {selectedGraph === "total posts vs total shares" && (
        <>
          {!isAllDataZero(graphData, ["total_posts", "total_shares"]) ? (
            <DivergentBarChart
              data={data["totalSharesData"]}
              indexBy={"page__page_name"}
              fields={["total_posts", "total_shares"]}
              labels={
                tabValue === "facebook"
                  ? ["Total Posts", "Total Shares"]
                  : ["Total Videos", "Total Views"]
              }
              width={900}
              height={400}
            />
          ) : (
            <Typography variant="h6" align="center" sx={{ color: "#FF5252" }}>
              No data found
            </Typography>
          )}
        </>
      )}

      {showPagination && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 2,
            paddingTop: "10px",
          }}
        >
          <Typography className="pages_text">
            {(currentPage - 1) * itemsPerPage + 1} to{" "}
            {currentPage * itemsPerPage > graphData.length
              ? graphData.length
              : currentPage * itemsPerPage}{" "}
            of {graphData.length}
          </Typography>
          <Box>
            <StyledIconButton
              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
            >
              <ChevronLeftIcon className="delIcon" />
            </StyledIconButton>
            <StyledIconButton
              onClick={() => handlePageChange(1)}
              disabled={currentPage === pageCount}
            >
              <ChevronRightIcon className="delIcon" />
            </StyledIconButton>
          </Box>
        </Box>
      )}
    </GraphContainer>
  );
};

export default GraphSection;
