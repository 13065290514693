import AXIOS from "services/axios";
import { accessLevel } from "utils/data";

export const getUsersList = async (pageCount, userType, searchUser) => {
  let user_type = userType.toLowerCase();
  let expanded_url = "";
  let user = ""
  if(user_type === "admin"){
    expanded_url = "&is_superuser=false&is_admin=true";
  } else if(user_type === "super admin"){
    expanded_url = "&is_superuser=true";
  } else if(user_type === "general"){
    expanded_url = "&is_superuser=false&is_admin=false&is_staff=false";
  }
  if(searchUser !== "") {
    user = searchUser;
  }
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(`${process.env.REACT_APP_API_PATH}/users/?${pageCount > 1 ? `page=${pageCount}`: ``}${expanded_url ? expanded_url : ``}${user ? `&name=${user}` : ``}`);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const getUserDetail = async (id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(`${process.env.REACT_APP_API_PATH}/users/${id}`);
    response.data = data;
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const addUsers = async (
  id,
  user_name,
  email_id,
  user_type,
  access_type,
  page,
  state,
  action
) => {
  const response = {
    status: null,
    message: null,
  };
  const obj = {
    name: user_name,
    email: email_id,
    user_type,
    access_type: user_type === "General" ? access_type : null,
    page_ids: access_type === "Page" ? page && page.length > 0 ? page : [] : [],
    state_ids: access_type === "State" ? state && state.length > 0 ? state : [] : [],
  };

  try {
    if (action === "edit") {
      const { data } = await AXIOS.patch(
        `${process.env.REACT_APP_API_PATH}/users/${id}/`,
        obj
      );

      response.status = data;
      response.message = data;
    } else {
      const { data } = await AXIOS.post(
        `${process.env.REACT_APP_API_PATH}/users`,
        obj
      );
      response.status = data.status;
      response.message = data.message;
    }
  } catch (err) {
    response.error = err.response.data.message;
  }
  return response;
};
