import React from "react";

import { ReactComponent as BirdsEyeLogo } from "assets/newlogo.svg";
import { ReactComponent as DigiDashLogo } from "assets/digidashlogo.svg";
import { ReactComponent as DigiDashLogoFull } from "assets/digidashlogofull.svg";

//mui
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const Container = styled("div")(({ theme, ishovered }) => ({
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  paddingLeft: ishovered ? "0" : "19px",
  gap: "0.5rem",
  margin: "1.5rem 0 4rem 0",
  [theme.breakpoints.down("xl")]: {
    paddingLeft: "14px",
    margin: "1.3rem 0 3rem 0",
  },
  [theme.breakpoints.down("lg")]: {
    paddingLeft: "12px",
    margin: "1rem 0 2rem 0",
  },
  "& .short_logo": {
    "& svg": {
      width: "38px",
      height: "42px",
      [theme.breakpoints.down("xl")]: {
        width: "31px",
        height: "38px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "27px",
        height: "34px",
      },
    },
  },
  "& .full_logo": {
    padding: "0 2rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      width: "100%",
      height: "42px",
      [theme.breakpoints.down("xl")]: {
        height: "38px",
      },
      [theme.breakpoints.down("lg")]: {
        height: "34px",
      },
    },
  },
  "& .text_style": {
    fontWeight: "700",
    fontSize: "1.1rem",
    color: "#333333",
  },
  "& .text_style_next": {
    fontWeight: "700",
    fontSize: "1.1rem",
    color: "#3EBFAB",
  }
}));

const Logo = ({ isHovered }) => {
  return (
    <Container ishovered={isHovered}>
      {isHovered ?
        <div className="full_logo">
          <DigiDashLogoFull />
        </div> :
        <div className="short_logo">
          <DigiDashLogo />
        </div>
      }
    </Container>
  );
};

export default Logo;
