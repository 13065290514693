import AXIOS from "services/axios";

export const addElectronicMediaCampignAds = async (
  description,
  state,
  political_party,
  channel,
  image_field,
  coverage_duration,
  log_time,
  type_id
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();

  formData.append("description", description);
  formData.append("state", stateFinal);
  formData.append("political_party", political_party.id);
  formData.append("channel", channel.id);
  formData.append("coverage_duration", coverage_duration);
  formData.append("log_time", log_time);
  formData.append("type_id", type_id);

  if (image_field) formData.append("image", image_field);

  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getElectronicCampaignAds = async (state, start_date, end_date,start_time, end_time) => {
  const response = { data: null, error: null ,result:null};
  let stateFinal;
  let typeId;
  if (state.id === 0) {
    stateFinal = null;
    typeId=1
  } else {
    stateFinal = state.id;
    typeId=2
  }
    try {
        const { data } = await AXIOS.get(
          `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}`
        );
    
        response.result = data;
      } catch (err) {
        response.error = err.response;
      }
      return response;

};

export const updateElectronicCampaignAds = async (
  description,
  state,
  political_party,
  channel,
  image_field,
  coverage_duration,
  log_time,
  userId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id
  if (state.id === 0) {
    stateFinal = null;
    type_id=1
  } else {
    stateFinal = state.id;
    type_id=2
  }
  const formData = new FormData();

  formData.append("description", description);
  formData.append("state", stateFinal);
  formData.append("political_party", political_party.id);
  formData.append("newchannel", channel.id);
  formData.append("coverage_duration", coverage_duration);
  formData.append("log_time", log_time);
  formData.append("type_id", type_id);

  if (image_field) formData.append("image", image_field);

  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/${userId}/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const deleteElectronicCampaignAd = async (userId) => {
    const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/adelectronic/${userId}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const updatePrintCampaignAds = async (
  state,
  relatedParty,
  newsChannel,
  image,
  pageNumber,
  articlePosition,
  log_time,
  userId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  let type_id;
  if (state.id === 0) {
    stateFinal = null;
    type_id=1
  } else {
    stateFinal = state.id;
    type_id=2
  }
  const formData = new FormData();

  formData.append("channel", newsChannel);
  formData.append("political_party", relatedParty.id);
  formData.append("page", pageNumber);
  formData.append("article_position", articlePosition);
  formData.append("state", stateFinal);
  formData.append("log_time", log_time);
  formData.append("type_id", type_id);

  if (image) formData.append("image", image);
  try {
    const { data } = await AXIOS.put(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/${userId}/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};
export const addPrintMediaCampaignAds = async (
  state,
  relatedParty,
  newsChannel,
  image,
  pageNumber,
  articlePosition,
  log_time,
  typeId
) => {
  const response = { data: null, error: null };

  let stateFinal;
  if (state.id === 0) {
    stateFinal = null;
  } else {
    stateFinal = state.id;
  }
  const formData = new FormData();
  formData.append("channel", newsChannel);
  formData.append("political_party", relatedParty.id);
  formData.append("page", pageNumber);
  formData.append("article_position", articlePosition);
  formData.append("state", stateFinal);
  formData.append("log_time", log_time);
  formData.append("type_id", typeId);

  if (image) formData.append("image", image);
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/`,
      formData
    );

    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const deletePrintCampaignAd = async (userId) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.delete(
      `${process.env.REACT_APP_API_PATH}/mmt/adprint/${userId}/`
    );
    response.result = data;
  } catch (err) {
    response.error = err.response;
  }

  return response;
};

export const getPrintCampaignAd = async (state, start_date, end_date,start_time,
    end_time) => {
    const response = { data: null, error: null,result:null };
  
    let stateFinal;
    let typeId;
    if (state.id === 0) {
      stateFinal = null;
      typeId=1
    } else {
      stateFinal = state.id;
      typeId=2
    }
      try {
          const { data } = await AXIOS.get(
            `${process.env.REACT_APP_API_PATH}/mmt/adprint/?state=${stateFinal}&date_start=${start_date}&date_stop=${end_date}&type_id=${typeId}&start_time=${start_time}&end_time=${end_time}`
          );
      
          response.result = data;
        } catch (err) {
          response.error = err.response;
        }
      
        return response;
    
  };