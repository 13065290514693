import React, { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertContext } from "contexts/AlertContext";

const AlertBar = () => {
  const { msg, severity, hideAlert } = useContext(AlertContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    msg ? setOpen(true) : setOpen(false);
  }, [msg]);

  const handleClose = () => {
    hideAlert();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      onClose={handleClose}
      autoHideDuration={3000}
    >
      {msg && (
        <Alert onClose={handleClose} severity={severity}>
          {msg}
        </Alert>
      )}
    </Snackbar>
  );
};

export default AlertBar;
