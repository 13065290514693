import AXIOS from "services/axios";

export const getAllCategories = async (category) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/categories/${category}`
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};
export const getPageTranslated = async (post_id) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/translate/post/?post_id=${post_id}`
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};
export const getSearchPosts = async (status,date_start,date_stop,page=1,page_id,search_text) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/api/data/?status=${status}&date_start=${date_start}&date_stop=${date_stop}&page=${page}&page_id=${page_id}&search_text=${search_text}`
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};
export const getTagsByCategory = async (category, page_id) => {
  const response = { data: null, error: null };
  try {
    const params = {};
    if (category) {
      params["category"] = category;
      params["page_id"] = page_id;
    }
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/tags/`,
      { params }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};

export const getPostsByStatus = async (
  status = "pending",
  date_start,
  date_stop,
  page_id,
  page = 1,
  share = null
) => {
  const response = { data: null, error: null };
  try {
    const params = { status, date_start, date_stop, page };
    if (page_id !== null) {
      params["page_id"] = page_id;
    }
    if (share !== null) {
      params["share"] = share;
    }
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/api/data/`,
      { params }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};

export const addNewCategory = async (categoryName, userId) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/tagging/categories/create/`,
      { name: categoryName, user: userId }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};

export const addNewTag = async (tagName, userId, category,stateId,pageCategory) => {
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/tagging/tags/`,
      pageCategory==="MP"?{ name: tagName, category, user: userId,state:stateId }:{ name: tagName, category, user: userId}
      
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};
export const addMpTagMapping=async(tagId,stateId)=>{
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/tagging/tags/mp_tag_mapping/`,
      {tag_id:tagId,state:stateId}
      
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
}
export const updateTagStatus=async(tagList,isActive)=>{
  const response = { data: null, error: null };
  try {
    const { data } = await AXIOS.post(
      `${process.env.REACT_APP_API_PATH}/tagging/tags/active_inactive/`,
      { tag_list: JSON.stringify(tagList), is_active:isActive }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
}
export const approvePost = async (
  postId,
  tag = [],
  page_id,
  created_at,
  status = 0,
  userId
) => {
  const response = { data: null, error: null };
  try {
    const body = { tag, page_id, created_at, status };
    if (userId) {
      body["rejected_by"] = userId.toString();
    }
    const { data } = await AXIOS.patch(
      `${process.env.REACT_APP_API_PATH}/tagging/tags/update/${postId}`,
      body
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};

export const getPostsCount = async (date_start, date_stop, page_id = null) => {
  const response = { data: null, error: null };
  if (page_id === null) {
    response.error = "No pageId found! Please select a pageId";
    return response;
  }
  try {
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/status/count/`,
      { params: { date_start, date_stop, page_id } }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};

export const getApprovedPosts = async (
  date_start,
  date_stop,
  page_id,
  page = 1,
  share = null,
  status,
  searchText
) => {
  const response = { data: null, error: null };
  try {
    const params = { date_start, date_stop, page };
    if (page_id !== null) {
      params["page_id"] = page_id;
    }
    if (share !== null) {
      params["shares"] = share;
    }
    if (status) {
      params["status"] = status;
    }
    if(searchText){
      params['search_text']=searchText
    }
    const { data } = await AXIOS.get(
      `${process.env.REACT_APP_API_PATH}/tagging/post/approved/`,
      { params }
    );
    response.data = data;
  } catch (error) {
    response.error = error.response;
  }
  return response;
};
